/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { data } from "../../tables/track/dataTrack";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { getTrack, updateTrack } from "../../api/track";
import { Link } from "react-router-dom";
import SupportTable from "../../tables/track/supportTable";
import { changeSupportStatus, getSupport } from "../../api/support_api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button } from "antd";

const theme = createTheme();

const ListSupport = () => {
  // states------
  const [loading, setIsLoading] = useState(false);
  const [data, setData] = useState("");
  const [count, setCount] = useState(1)
  const [page, setpage] = useState('')
  const [showModal, setShowModal] = useState(false);
  const [msg , setMsg]=useState('')

  // functions---
  const handleCloseModal = () => {
    setMsg('')
    setShowModal(false);
    // setSelectedRow(null);
  };
  const handleOk = () => {
  
      setShowModal(false);
      // setIsLoading2(true);
      
  };
 
  const handleMessage=(row)=>{
    setShowModal(true)
    setMsg(row?.msg)
  }
  console.log(msg,"m")
  const changeStatus=(row)=>{
    setIsLoading(true)
    changeSupportStatus('attended',row?._id).then((res)=>{
      if(res){
        toast.success('Support Attended Successfully ')
        getSupport(count).then((res) => {
          console.log(res, "res");
        setIsLoading(false)
          setData(res?.Messages);
          setpage(res?.count?.totalPage)
        });
      }
    })
  }
  // useEffects------
  useEffect(() => {
    setIsLoading(true)
    getSupport(count).then((res) => {
    setIsLoading(false)
      setData(res?.Messages);
      setpage(res?.count?.totalPage)
    });
  }, []);

  const columns = [
    {
      name: "Name",
      minWidth: "130px",
      sortable: true,
      maxWidth: "45px",
      selector: (row) => row.name,
    },
    {
      name: "Phone",
      minWidth: "150px",

      sortable: true,
      selector: (row) => row.phone,
    },

    {
      name: "Email",
      sortable: true,
      minWidth: "auto",
      selector: (row) => row.email,
    },
    {
      name: "Message",
      sortable: true,
      minWidth: "90px",
      selector: (row) => {
        return(
          <>

          <button className="btn btn-sm text-white  bg-success" onClick={()=>handleMessage(row)}>View</button>
          </>
        )
      },
    },
    {
      name: "Updated Date",
      sortable: true,
      minWidth: "100px",
      selector: (row) => {
        const formattedDate = row.updated_at ? new Date(row.updated_at).toLocaleString() : '-';
        return formattedDate;
      },
    },
    {
      name: "Attended",
      sortable: true,
      maxWidth: "content-fit",
      selector: (row) => {
        console.log(row?.attended,"at")
        if (row.attended === false || !row?.attended) {
          return (
            <button style={{minWidth:"100px"}} onClick={() => changeStatus(row)} className="btn btn-sm bg-success text-white">
              Attend
            </button>
          );
        } else if (row?.attended === true) {
          return <p className="text-success">Already Attended</p>;
        }
      },
    },
    
  

  ];
  return (
    <div>
      <div className="bg ">
        <div className="tw-pb-5 container tw-pt-8 tw-flex tw-flex-wrap tw-align-start tw-gap-6  ">
          <ThemeProvider theme={theme}>
            <Box width={{ md: "94%", xs: "100%" }}>
            <Modal
          title="Message Detail"
          visible={showModal}
          onOk={handleOk}
          okButtonProps={{
            style: { backgroundColor: "#4CAF50", color: "white" }, // Set background color for OK button
          }}
          onCancel={handleCloseModal}
          cancelButtonProps={{
            style: { backgroundColor: "#f44336", color: "white" }, // Set background color for Cancel button
          }}
        >
          {/* Your edit form goes here */}
        {msg}
        </Modal>
              <Box
                sx={{
                  bgcolor: "#fff",
                  minHeight: "auto",
                  paddingBottom: "2rem",
                }}
              >
                <SupportTable
                  loading={loading}
                  columns={columns}
                  data={data}
                  showRow={true}
                  setCount={setCount}
                  page={page}
                  rowHeading="New Order"
                />
              </Box>
            </Box>
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default ListSupport;
