/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { upload } from "../../../icons/icon";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { Button } from "react-bootstrap";
import { uploadFile } from "../../../api/upload";
import { Form, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { createCategory } from "../../../api/categories";
import { Modal } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import {
  changeServiceStatus,
  createService,
  delServices,
  getServices,
  updateService,
  updateServices,
} from "../../../api/services";
import ServiceTable from "../../../tables/service/serviceTable";
import { Edit, Trash } from "react-feather";
import { TRUE } from "sass";
const AddServices = () => {
  // states-------
  const Navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const toggle = () => setModal(!modal);
  const [loading, setIsLoading] = useState(false);
  const [editloading, setEditLoading] = useState(false);
  const [loading1, setIsLoading1] = useState(false);
  const [service, setService] = useState("");
  const [serviceCat, setServiceCat] = useState([]);
  const [delId, setDelId] = useState("");
  const [count, setCount] = useState(1);
  const [reload, setReload] = useState(false);
  const [rowData, setrowData] = useState(false);
  const [updateService, setUpdateService] = useState("");
  const [page, setpage] = useState("");
  // functions-------
  // const handleSubmit = async (e) => {
  //   setIsLoading(true);
  //   e.preventDefault();
  //   const data = {
  //     service: service,
  //   };
  //   if (setService === "") {
  //     toast.error("Please fill form first ");
  //     setIsLoading(false);
  //     return;
  //   } else {
  //     await createService(data.service)
  //     setIsLoading1(true)
  //       .then((res) => {
  //         if (res) {
  //           setIsLoading(false);
  //           toast.success("Service added successfully...!");
  //           setService("");
  //           // Navigate("/explore/listCat");
  //           getServices("").then((res) => {

  //             setServiceCat(res?.services);
  //             setIsLoading1(false);
  //           });
  //         }
  //       })
  //       .catch((err) => {
  //         setIsLoading(false);
  //         toast.error("Please fill form first ");
  //       });
  //   }
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      if (!service && trimmedService?.trim() == "") {
        toast.error("Please fill out the form first");
        setIsLoading(false);
        return;
      }
      const trimmedService = service.trim();
      const data = {
        service: trimmedService,
      };
      const res = await createService(data.service);

      if (res) {
        setIsLoading(false);
        toast.success("Service added successfully...!");
        setService("");
        const updatedServices = await getServices("");
        setServiceCat(updatedServices?.services);
        setIsLoading1(false);
      }
    } catch (err) {
      setIsLoading(false);
      toast.error("Error adding service. Please try again.");
    }
  };

  const handleEdit = (e) => {
    setEditLoading(true);
    e.preventDefault();
    const data = {
      name: updateService,
    };
    updateServices(data, rowData?._id)
      .then((res) => {
        setIsLoading1(true);
        if (res) {
          setIsLoading(false);
          toast.success("Service Edited successfully...!");
          setService("");
          getServices("").then((res) => {
            setServiceCat(res?.services);
            setIsLoading1(false);
            setEditLoading(false);
          });
          setOpen2(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setIsLoading1(false);
        setEditLoading(false);

        toast.error("Please fill form first ");
      });
  };

  //   useEffects----
  useEffect(() => {
    setIsLoading1(true);
    getServices("").then((res) => {
      console.log(res);
      setServiceCat(res?.services);
      setIsLoading1(false);
    });
  }, [reload]);
  //   functions-----
  const deactivateStatus = (row) => {
    setIsLoading1(true);
    changeServiceStatus("deactivated", row?._id)
      .then((res) => {
        if (res?.message) {
          toast.success("Status Updated Successfully");
          getServices("").then((res) => {
            setServiceCat(res?.services);
            setIsLoading1(false);
          });
        }
      })
      .catch((err) => {});
  };
  const activateStatus = (row) => {
    setIsLoading1(true);
    changeServiceStatus("active", row?._id)
      .then((res) => {
        if (res?.message) {
          toast.success("Status Updated Successfully");
          getServices("").then((res) => {
            setServiceCat(res?.services);
            setIsLoading1(false);
          });
        }
      })
      .catch((err) => {});
  };
  const showModal = () => {
    setOpen(true);
  };
  const showModal2 = (row) => {
    setOpen2(true);
    setUpdateService(row?.name);
  };
  const handleOk = (e) => {
    setIsLoading1(true);

    setOpen(false);
    delServices(delId)
      .then((res) => {
        toast.success("Deleted SuccessFully..!");
        getServices("").then((res) => {
          setServiceCat(res?.services);
          setIsLoading1(false);
        });
        setOpen(false);
      })
      .catch((err) => {});
  };
  const handleCancel = (e) => {
    setOpen(false);
  };
  const handleOk2 = () => {
    setOpen2(false);
  };
  const handleCancel2 = () => {
    setOpen2(false);
  };
  //   list columns----
  const columns = [
    {
      name: " Service Name",
      minWidth: "200px",
      sortable: true,
      maxWidth: "45px",
      selector: (row) => row.name,
    },
    {
      name: " Status",
      minWidth: "200px",
      sortable: true,
      maxWidth: "45px",
      selector: (row) => {
        return (
          <p
            className={
              row?.status == "active" || !row?.status
                ? " text-success"
                : " text-danger"
            }
          >
            {row?.status == "active" || !row?.status ? "Active" : "De Active"}
          </p>
        );
      },
    },
    {
      name: "Change Status",
      minWidth: "200px",
      sortable: true,
      maxWidth: "45px",
      selector: (row) => {
        if (row?.status === "active" || !row?.status) {
          return (
            <button
              style={{ minWidth: "120px" }}
              onClick={() => deactivateStatus(row)}
              className={
                row?.status == "active" || !row?.status
                  ? "btn btn-md btn-danger"
                  : "btn btn-md btn-success"
              }
            >
              {row?.status == "active" || !row?.status
                ? "De Activate"
                : "Activate"}
            </button>
          );
        } else if (row?.status === "deactivated") {
          return (
            <button
              style={{ minWidth: "120px" }}
              onClick={() => activateStatus(row)}
              className={
                row?.status == "active" || !row?.status
                  ? "btn btn-md btn-danger"
                  : "btn btn-md btn-success"
              }
            >
              {row?.status == "active" || !row?.status
                ? "De Activate"
                : "Activate"}
            </button>
          );
        }
      },
    },
    {
      name: "Actions",
      allowOverflow: false,
      cell: (row) => {
        return (
          <div className=" d-flex justify-content-center gap-1 align-items-center">
            <div>
              <Trash
                style={{ cursor: "pointer" }}
                size={20}
                onClick={() => {
                  showModal();
                  setDelId(row._id);
                }}
              />
            </div>

            <div>
              <Edit
                style={{ cursor: "pointer" }}
                size={20}
                onClick={() => {
                  showModal2(row);
                  // setDelId(row._id);
                  setrowData(row);

                  // setDelId(row._id);
                  // console.log(row._id);
                  // setShowdel(true);
                }}
              />
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div className="tw-pb-5 container bg ">
          <div className="tw-pt-16 md:tw-flex tw-align-start  ">
            <ToastContainer />

            <div>
              <h4 className="pb-4 pt-0 mt-0 tw-text-xl lato_bold">
                Service Name
              </h4>
              <TextField
                value={service}
                onChange={(e) => setService(e.target.value)}
                label={
                  <span style={{ color: "black" }}>
                    Name <span style={{ color: "#FF6347" }}>*</span>
                  </span>
                }
                id="outlined-start-adornment"
                sx={{
                  m: 1,
                  width: "25ch",
                  "& .MuiInputLabel-root": {
                    color: "red", // Color for label text
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#A09E9D", // Border color
                    },
                    "&:hover fieldset": {
                      borderColor: "#A09E9D", // Border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#A09E9D", // Border color on focus
                    },
                    "& input::placeholder": {
                      color: "#333333",
                      fontSize: "13px",
                      // Placeholder color
                    },
                  },
                }}
                placeholder="Service Name"
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ color: "red" }}
                    ></InputAdornment>
                  ),
                }}
              />
              <div className="tw-flex tw-align-middle tw-justify-center mt-4">
                <Button
                  disabled={loading}
                  type="submit"
                  className="auth_btn tw-text-white"
                  color="primary"
                >
                  {loading ? (
                    <>
                      <Spinner size={"sm"} />
                    </>
                  ) : (
                    "Add Service"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Form>
      <div className="tw-px-4 tw-py-7">
        <ServiceTable
          columns={columns}
          data={serviceCat}
          showRow={true}
          rowHeading="New Order"
          loading1={loading1}
          setCount={setCount}
          page={page}
        />
        <Modal
          title="Delete Services"
          open={open}
          onOk={handleOk}
          onCancel={handleCancel}
          okButtonProps={{
            style: { backgroundColor: "#4CAF50", color: "white" }, // Set background color for OK button
          }}
          cancelButtonProps={{
            style: { backgroundColor: "#f44336", color: "white" }, // Set background color for Cancel button
          }}
        >
          Are you Sure...?
        </Modal>
        <div className="edit_modal">
          <Modal
            title="Edit Services"
            open={open2}
            onOk={handleOk2}
            onCancel={handleCancel2}
            okButtonProps={{
              style: {
                backgroundColor: "#4CAF50",
                color: "white",
                display: "none",
              }, // Set background color for OK button
            }}
            cancelButtonProps={{
              style: {
                backgroundColor: "#f44336",
                color: "white",
                display: "none",
              }, // Set background color for Cancel button
            }}
          >
            <Form onSubmit={handleEdit}>
              <div className="tw-pb-5 container bg ">
                <div className="tw-pt-2 md:tw-flex tw-align-start  ">
                  <div>
                    <h4 className="pb-4 pt-0 mt-0 tw-text-xl lato_bold">
                      Service Name
                    </h4>
                    <TextField
                      value={updateService}
                      onChange={(e) => setUpdateService(e.target.value)}
                      label={
                        <span style={{ color: "black" }}>
                          Name <span style={{ color: "#FF6347" }}>*</span>
                        </span>
                      }
                      id="outlined-start-adornment"
                      sx={{
                        m: 1,
                        width: "25ch",
                        "& .MuiInputLabel-root": {
                          color: "red", // Color for label text
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#A09E9D", // Border color
                          },
                          "&:hover fieldset": {
                            borderColor: "#A09E9D", // Border color on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#A09E9D", // Border color on focus
                          },
                          "& input::placeholder": {
                            color: "#333333",
                            fontSize: "13px",
                            // Placeholder color
                          },
                        },
                      }}
                      placeholder="Service Name"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            style={{ color: "red" }}
                          ></InputAdornment>
                        ),
                      }}
                    />
                    <div className="tw-flex tw-align-middle tw-justify-center mt-4">
                      <Button
                        disabled={editloading}
                        type="submit"
                        className="auth_btn tw-text-white"
                        color="primary"
                      >
                        {editloading ? (
                          <>
                            <Spinner size={"sm"} />
                          </>
                        ) : (
                          "Edit Service"
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default AddServices;
