import axios from "axios";
export const updateAdminPassword = async (data) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found in local storage");
        return;
      }
      const res = await axios.put(
         'https://visaapp.onrender.com/api/users/admin/update-password',
         {
          oldPassword:data.oldPassword,
          newPassword:data?.newPassword
         },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
          },
        }
      );
  
      return res.data;
    } catch (error) {
      console.error("Error fetching category:", error);
       ;
    }
  };