import axios from "axios";
export const createService = async (data) => {
    console.log(data,'data inside api')
  try {
    const token = localStorage.getItem("token");
    const res = await axios.post(
      global.BASEURL + "api/services/create",
      {
        name: data,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      }
    );
    console.log(res.data, "res");
    return res.data;
  } catch (error) {
    console.log(error, "error");
     ; // Rethrow the error to handle it in the component
  }
};
export const getServices= async (id) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found in local storage");
        return;
      }
  
      const res = await axios.get(
         global.BASEURL+"api/services/admin/"+id,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
          },
        }
      );
    
      return res.data;
    } catch (error) {
      console.error("Error fetching category:", error);
       ;
    }
  };
  export const delServices= async (id) => {
    console.log(id,"id inside api ")
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found in local storage");
        return;
      }
  
      const res = await axios.delete(
         global.BASEURL+`api/services/${id}`,
       
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
          },
        }
      );
  
      console.log("Response status:", res.status);
      console.log("Response data:", res.data);
  
      return res.data;
    } catch (error) {
      console.error("Error fetching category:", error);
       ;
    }
  };
  // put req
  export const updateServices = async (data,id) => {
    console.log(data,'data inside api')
  try {
    const token = localStorage.getItem("token");
    const res = await axios.put(
      global.BASEURL + `api/services/edit/${id}`,
      {
        name: data?.name,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      }
    );
    console.log(res.data, "res");
    return res.data;
  } catch (error) {
    console.log(error, "error");
     ; // Rethrow the error to handle it in the component
  }
};
// change Status-------
export const changeServiceStatus = async (status,id) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.put(
      global.BASEURL + `api/services/${status}/${id}`,
      {}, 
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      }
    );
    console.log(res.data, "res");
    return res.data;
  } catch (error) {
    console.log(error, "error");
     ; // Rethrow the error to handle it in the component
  }
};