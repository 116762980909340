import axios from "axios";

export const getUser = async () => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token not found in local storage");
      return;
    }

    const res = await axios.get(global.BASEURL+"api/users/me/", {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    });

    console.log("Response status:", res.status);
    console.log("Response data:", res.data);

    return res.data;
  } catch (error) {
    console.error("Error fetching category:", error);
     ;
  }
};
// update user--------
export const updateUser = async (data) => {
  console.log(data, "data inside api");
  console.log(data?.values, "data inside api");
  try {
    const token = localStorage.getItem("token");
    const res = await axios.put(
      global.BASEURL + "api/users/update-user",
      {
        name: data?.name,
        nationality: data?.nationality,
        gender: data?.gender,
        dob: data?.dob,
        address1: data?.address1,
        address2: data?.address2,
        profilePicture: data?.profilePicture,
        residence: data?.residence,
        zipcode: data?.zipcode,
        doc_url: data?.doc_url,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      }
    );
    console.log(res.data, "res");
    return res.data;
  } catch (error) {
    console.log(error, "error");
     ; // Rethrow the error to handle it in the component
  }
};
