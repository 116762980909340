import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const getTrack = async (data) => {
  console.log(data.status, "data in api call");
  try {
    var token = localStorage.getItem("token");
    console.log(token, "token in track");
    if (!token) {
      console.error("Token not found in local storage");
      toast.error("Token error..!");
      return;
    } else {
      const res = await axios.post(
        global.BASEURL+"api/application/admin",
        {
          type: data?.type,
          date: data?.date,
          last_id: data?.last_id,
          status: data?.status
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
          },
        }
      );
      return res.data;
    }
  } catch (error) {
    console.error("Error fetching category:", error);
     ;
  }
};

  export const getTrackbyId = async (param) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found in local storage");
        return;
      }
      const res = await axios.get(
         global.BASEURL+`api/application/detail/${param}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
          },
        }
      );
  
      console.log("Response status:", res.status);
      console.log("Response data:", res.data);
  
      return res.data;
    } catch (error) {
      console.error("Error fetching category:", error);
       ;
    }
  };
  // update track req------
  export const updateTrack = async (status, param) => {
    console.log(status, param, "checking status and id...!");
    try {
      const token = localStorage.getItem("token");
      console.log(token, "");
  
      if (!token) {
        console.error("Token not found in local storage");
        return;
      }
  
      const res = await axios.put(
        global.BASEURL+`api/application/${status}/${param}`,
        {}, // Request data can be an empty object or the payload you want to send
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
          },
        }
      );
  
      console.log("Response status:", res.status);
      console.log("Response data:", res.data);
  
      return res.data;
    } catch (error) {
      console.error("Error updating track:", error);
       ;
    }
  };
  
  