import axios from "axios";

export const getSupport = async (pageNum) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      return;
    }
    const res = await axios.get(
      global.BASEURL+"api/support/admin/" + pageNum,
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      }
    );

    console.log("Response data:", res);

    return res.data;
  } catch (error) {
    console.error("Error fetching category:", error);
     ;
  }
};
// change Status-------
export const changeSupportStatus = async (status,id) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.put(
      global.BASEURL + `api/support/${status}/${id}`,
      {}, 
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      }
    );
    console.log(res.data, "res");
    return res.data;
  } catch (error) {
    console.log(error, "error");
     ; // Rethrow the error to handle it in the component
  }
};


