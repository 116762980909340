/* eslint-disable no-unused-vars */
import React from "react";
import "./components/style/main.css";
import { Suspense, lazy, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Register from "./components/authPages/register";
import Login from "./components/authPages/login";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import MySidebar from "./components/pages/mySidebar";
import MyNavbar from "./components/header/myNavbar";
import AddCat from "./components/pages/explore/category/addCat";
import ListCat from "./components/pages/explore/category/listCat";
import ListCountry from "./components/pages/explore/country/listCountry";
import AddCountry from "./components/pages/explore/country/addCountry";
import AddPartner from "./components/pages/partner/addPartner";
import ListPartner from "./components/pages/partner/listPartner";
import ListTrack from "./components/pages/track/listTrack";
import AddTrack from "./components/pages/track/addTrack";
import Updateprofile from "./components/pages/profile/updateprofile";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ListSupport from "./components/pages/support/listSupport";
import PrivacyPolicy from "./components/pages/privacyPolicy/privacy";
import AddServices from "./components/pages/explore/services/addServices";
import ForgotPassword from "./components/authPages/forgotPassword";
import VerifyCode from "./components/authPages/verificationCode";
import ChangePassword from "./components/authPages/changePassword";
import ListCustomer from "./components/pages/customer/listCustomer";
import DetailCountry from "./components/pages/explore/detail";
import AllNoti from "./components/pages/allNoti";
function App() {
  const [toggled, setToggled] = useState(false);
  const [broken, setBroken] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    global.BASEURL = "https://node.visa10.com/";
    global.Upload = "https://node.visa10.com/api/";
  }, []);
  useEffect(() => {
    // Check if user is logged in when the component mounts
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setIsLoggedIn(true);
    }
  }, []);
  return (
    <>
      <div className=" container-xxl px-0 ">
        <ToastContainer />

        {/* <Register/> */}
        {/* <Login/> */}
        {/* <MySidebar
        toggled={toggled}
        setBroken={setBroken}
        broken={broken}
        setToggled={setToggled}
      >
        <MyNavbar
          toggled={toggled}
          setBroken={setBroken}
          broken={broken}
          setToggled={setToggled}
        />
        <Routes>
          <Route index element={<ListCat />} />
          <Route path="explore/addCat" element={<AddCat />} />
          <Route path="explore/listCat" element={<ListCat />} />
          <Route path="explore/listCountry" element={<ListCountry />} />
          <Route path="visa/details" element={<AddCountry />} />
          <Route path="/profile/update" element={<Updateprofile />} />
          <Route path="/partner/addPartner" element={<AddPartner />} />
          <Route path="/partner/listPartner" element={<ListPartner />} />
          <Route path="/track/listTrack" element={<ListTrack />} />
          <Route path="/track/details/:id" element={<AddTrack />} />
        </Routes>
      </MySidebar> */}
      </div>
      {isLoggedIn ? (
        <MySidebar
          toggled={toggled}
          setBroken={setBroken}
          broken={broken}
          setToggled={setToggled}
          setIsLoggedIn={setIsLoggedIn}
        >
          <MyNavbar
            toggled={toggled}
            setBroken={setBroken}
            broken={broken}
            setToggled={setToggled}
            setIsLoggedIn={setIsLoggedIn}
          />
          <Routes>
            <Route path="explore/listCat" element={<ListCat />} />
            <Route path="/detailCountry/:id" element={<DetailCountry/>} />
            <Route path="explore/addCat" element={<AddCat />} />
            <Route path="explore/listCountry" element={<ListCountry />} />
            <Route path="visa/details" element={<AddCountry />} />
            <Route path="add/services" element={<AddServices />} />
            <Route path="/support" element={<ListSupport />} />
            <Route path="/security" element={<PrivacyPolicy />} />
            <Route path="/profile/update" element={<Updateprofile />} />
            <Route path="/partner/addPartner" element={<AddPartner />} />
            <Route path="/partner/listPartner" element={<ListPartner />} />
            <Route path="/customer/listCustomer" element={<ListCustomer />} />
            <Route path="/allNoti" element={<AllNoti />} />


            <Route path="/track/listTrack" element={<ListTrack />} />
            <Route path="/track/details/:id" element={<AddTrack />} />
          </Routes>
        </MySidebar>) : (<Routes>
          <Route index element={<Login setIsLoggedIn={setIsLoggedIn} />} />
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register setIsLoggedIn={setIsLoggedIn} />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/code-verification" element={<VerifyCode />} />
          <Route path="/change-password" element={<ChangePassword />} />
        </Routes>)
      }
    </>
  );
}

export default App;
