/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Badge,
  DropdownItem,
} from "reactstrap";
import { Modal, Button } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import { Form } from "reactstrap";
import { Input } from "reactstrap";
import { bell, search } from "../icons/icon";
import { MdMenu, MdNotificationAdd } from "react-icons/md";
import {
  getBadgeValue,
  getNotification,
  updateBadgeValue,
} from "../api/notification_api";
import { ArrowLeft, ArrowRight } from "react-feather";
import { updateAdminPassword } from "../api/admin";
const MyNavbar = ({ broken, setToggled, toggled, logout, setIsLoggedIn }) => {
  // states---------
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [notiData, setNotiData] = useState([]);
  const [notiCount, setNotiCount] = useState();
  const [notification, setNotification] = useState(false);
  const [badgevalue, setBageValue] = useState(0);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [newpass, setNewPass] = useState("");
  const [old, setOld] = useState("");
  const [loading2, setIsLoading2] = useState(false);

  // functions---------
  const handleCloseModal = () => {
    setShowModal(false);
    // setSelectedRow(null);
  };
  const handleOk = () => {
    if (newpass.length < 6) {
      toast.error("New password must be at least 6 characters");
      setIsLoading2(false);
      return;
    } else {
      setShowModal(false);
      setIsLoading2(true);
      const data = {
        oldPassword: old,
        newPassword: newpass,
      };
      updateAdminPassword(data)
        .then((res) => {
          if (res) {
            toast.success("Password updated successfully");
            setIsLoading2(false);
            setNewPass("");
            setOld("");
          }
        })
        .catch((err) => {});
    }
  };
  const handleLogout = () => {
    window?.localStorage.removeItem("token");
    setIsLoggedIn(false);
    navigate("/");
  };
  const handleInputChange = () => {};
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const toggleDropdownNotification = () => {
    setNotification(!notification);
    updateBadgeValue()
      .then((res) => {
        console.log(res.data?.notification, "notification");
        setBageValue(0);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handlePassword = () => {
    setShowModal(true);
  };
  useEffect(() => {
    // Get the current URL
    const currentUrl = window.location.pathname;
    console.log("Current URL:", currentUrl);

    // You can do whatever you need with the URL here

    // Remember to clean up if needed when the component is unmounted
    return () => {
      // Cleanup code here, if necessary
    };
  }, []);
  // const getNotify = () => {
  //   getNotification(1)
  //     .then((result) => {
  //       console.log(result,"noti")
  //       if (result.success) {
  //         setNotiData(result.notifications);
  //         setNotiCount(result.count);
  //       } else {
  //         setNotiData([]);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  const getBadge = () => {
    getBadgeValue().then((res) => {
      setBageValue(res.notification);
    }).catch((err)=>{

    });
  };
  // useEffect(() => {
  //   getNotify();
  //   setInterval(() => {
  //     getBadge();
  //   }, 3000);
  // }, []);

  return (
    <Navbar
      bg="white"
      expand="lg"
      sticky="top"
      className="p-3 shadow-sm w-[100%]"
      id="navbar"
    >
      <Container
        fluid
        className="d-flex flex-row justify-content-end align-items-center  md:tw-gap-4 tw-gap-2  "
      >
        <Modal
          title="Edit Password"
          visible={showModal}
          onOk={handleOk}
          okButtonProps={{
            style: { backgroundColor: "#4CAF50", color: "white" }, // Set background color for OK button
          }}
          onCancel={handleCloseModal}
          cancelButtonProps={{
            style: { backgroundColor: "#f44336", color: "white" }, // Set background color for Cancel button
          }}
        >
          {/* Your edit form goes here */}
          <Input
            placeholder="old password"
            onChange={(e) => {
              setOld(e.target.value);
            }}
            value={old}
            type="text"
            className="mb-3"
          />

          <Input
            placeholder="new password"
            onChange={(e) => {
              setNewPass(e.target.value);
            }}
            value={newpass}
            type="text"
          />
        </Modal>
        <div className="me-auto">
          {broken && (
            <button className="sb-button" onClick={() => setToggled(!toggled)}>
              <MdMenu size={28} />
            </button>
          )}
        </div>
        {/* <div
          style={
            {
              // width: "100%",
            }
          }
          className="search_div px-3"
        >
          <img className="icon_size_tab search_icon me-2" src={search} alt="" />
          <Input
            style={{ padding: "0px" }}
            type="text"
            className="hide_fcontrol  "
            placeholder="Search..."
            onChange={handleInputChange}
          />
        </div> */}

        <div>
          {/* <img src={bell} alt="notification" /> */}
          <Dropdown isOpen={notification} toggle={toggleDropdownNotification}>
            <DropdownToggle>
              <img src={bell} alt="notification" />
              <Badge
                color="danger"
                pill
                style={{
                  position: "absolute",
                  top: 0,
                  left: 20,
                  minWidth: "fit-content",
                }}
              >
                {badgevalue === 0 ? "" : badgevalue}
              </Badge>
            </DropdownToggle>
            <DropdownMenu className="custom-dropdown max-[450px]:tw-w-64">
              {notiData?.map((items, index) => (
                <DropdownItem key={index} className="">
                  <div className="tw-flex tw-gap-3">
                    <div
                      className="btn btn-outline-danger rounded-circle p-0 tw-flex tw-justify-center tw-items-center"
                      style={{ height: "1.4rem", width: "1.4rem" }}
                    >
                      <MdNotificationAdd />
                    </div>
                    <div>
                      <div className="tw-text-sm">{items.title}</div>
                      <div className="tw-text-sm max-[450px]:tw-whitespace-break-spaces">
                        {items.description}
                      </div>
                    </div>
                  </div>
                  {}
                </DropdownItem>
              ))}
              <div className="d-flex justify-content-center">

              <button onClick={()=>{
                    setNotification(false);
                navigate('/allNoti')
              }}>See more</button>
              </div>
              {/* <div className="py-3 ">
                <button>
                  <ArrowLeft />
                </button>
                <button>
                  <ArrowRight />
                </button>
              </div> */}
            </DropdownMenu>
          </Dropdown>
        </div>
        <div>
          <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
            <DropdownToggle>
              <img
                src="https://picsum.photos/200/300"
                alt=""
                style={{
                  borderRadius: "50%",
                  width: "40px",
                  height: "40px",
                }}
              />
            </DropdownToggle>
            <DropdownMenu className="custom-dropdown">
              <div className="mmm d-flex flex-column gap-1">
                <DropdownItem
                  className="custom-dropdown-item"
                  onClick={handleLogout}
                >
                  Logout
                </DropdownItem>
                <DropdownItem
                  className="custom-dropdown-item"
                  onClick={handlePassword}
                >
                  Change Password
                </DropdownItem>
              </div>
            </DropdownMenu>
          </Dropdown>
        </div>
      </Container>
    </Navbar>
  );
};

export default MyNavbar;
