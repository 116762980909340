/* eslint-disable no-unused-vars */
import { Fragment, useState, forwardRef } from "react";
import ReactPaginate from "react-paginate";
import DataTable from "react-data-table-component";
import {
  ChevronDown,
  FileText,
  MoreVertical,
  Edit2,
  Trash,
} from "react-feather";
import {
  Row,
  Col,
  Card,
  Input,
  Label,
  CardTitle,
  CardHeader,
  Spinner,
} from "reactstrap";

import { MdSearch } from "react-icons/md";
import { Link } from "react-router-dom";
import { Chevron } from "../../icons/icon";

const BootstrapCheckbox = forwardRef((props, ref) => (
  <div className="form-check">
    <Input type="checkbox" ref={ref} {...props} />
  </div>
));

const TablePartner = ({ data, columns, rowHeading, page,loading , setCount}) => {
  const [sortOption, setSortOption] = useState(""); // State to manage selected sorting option

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
    // Implement sorting logic based on the selected option
    // You might want to update the data array accordingly
  };
  const [modal, setModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  // ** Function to handle Modal toggle
  const handleModal = () => setModal(!modal);

  // ** Function to handle filter
  const handleFilter = (e) => {
    const value = e.target.value;
    let updatedData = [];
    setSearchValue(value);

    if (value.length) {
      updatedData = data.filter((item) => {
        const startsWith =
          item.full_name.toLowerCase().startsWith(value.toLowerCase()) ||
          item.post.toLowerCase().startsWith(value.toLowerCase()) ||
          item.email.toLowerCase().startsWith(value.toLowerCase()) ||
          item.age.toLowerCase().startsWith(value.toLowerCase()) ||
          item.salary.toLowerCase().startsWith(value.toLowerCase()) ||
          item.start_date.toLowerCase().startsWith(value.toLowerCase());

        const includes =
          item.full_name.toLowerCase().includes(value.toLowerCase()) ||
          item.post.toLowerCase().includes(value.toLowerCase()) ||
          item.email.toLowerCase().includes(value.toLowerCase()) ||
          item.age.toLowerCase().includes(value.toLowerCase()) ||
          item.salary.toLowerCase().includes(value.toLowerCase()) ||
          item.start_date.toLowerCase().includes(value.toLowerCase());

        if (startsWith) {
          return startsWith;
        } else if (!startsWith && includes) {
          return includes;
        } else return null;
      });
      setFilteredData(updatedData);
      setSearchValue(value);
    }
  };

  // ** Function to handle Pagination
  const handlePagination = (page) => {
    setCurrentPage(page.selected);
    setCount(page?.selected +1)
  };

  // ** Pagination Previous Component
  const Previous = () => {
    return (
      <Fragment>
        <span className="align-middle d-none d-md-inline-block">Prev</span>
      </Fragment>
    );
  };

  // ** Pagination Next Component
  const Next = () => {
    return (
      <Fragment>
        <span className="align-middle d-none d-md-inline-block">Next</span>
      </Fragment>
    );
  };
  // ** Custom Pagination
  const CustomPagination = () => (
    <ReactPaginate
      previousLabel={<Previous size={15} />}
      nextLabel={<Next size={15} />}
      forcePage={currentPage}
      onPageChange={(page) => handlePagination(page)}
      pageCount={
      page
        // searchValue.length
        //   ? Math.ceil(filteredData.length / 7)
        //   : Math.ceil(data.length / 7) || 1
      }
      breakLabel="..."
      pageRangeDisplayed={2}
      marginPagesDisplayed={2}
      activeClassName="active"
      pageClassName="page-item"
      breakClassName="page-item"
      nextLinkClassName="page-link"
      pageLinkClassName="page-link"
      breakLinkClassName="page-link"
      previousLinkClassName="page-link"
      nextClassName="page-item next-item"
      previousClassName="page-item prev-item"
      containerClassName="pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1 mt-4"
    />
  );

  return (
    <>
      <Fragment>
        <Card className="border border-white w-full">
          <div className="tw-flex tw-flex-wrap  tw-justify-end tw-items-md-end tw-items-sm-center tw-px-4 tw-py-4">
            {/* <div>
              <select
                style={{
                  padding: ".7rem .9rem",
                  border: "1px solid rgba(0, 0, 0, 0.40)",
                  appearance: "none", // Hide the default arrow
                  backgroundImage: `url(${Chevron})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "calc(100% - 10px) center",
                  paddingRight: "3rem",
                  cursor: "pointer",
                  borderRadius: "28px",
                  minWidth: "200px",
                  position: "relative", // Set position to relative
                }}
                value={sortOption}
                onChange={handleSortChange}
              >
                <option value="">Sort By</option>
                <option value="name">Name</option>
                <option value="date">Date</option>
              </select>
            </div> */}
            <div className="tw-pt-3">
              <Link
                to="/partner/addPartner"
                className="bgprimary tw-rounded-md tw-px-3  tw-py-2 tw-text-white"
              >
                Add Partner
              </Link>
            </div>
          </div>
          <div className="react-dataTable">
          {loading ? (
              <div
                className="tw-pt-16"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "100vh",
                }}
              >
                <Spinner size={"md"} />
              </div>
            ) : (
              <DataTable
                noHeader
                pagination
                selectableRows={false}
                columns={columns}
                paginationPerPage={10}
                className="react-dataTable"
                sortIcon={<ChevronDown size={10} />}
                paginationDefaultPage={currentPage + 1}
                paginationComponent={CustomPagination}
                data={searchValue.length ? filteredData : data}
              />
            )}
          </div>
        </Card>
      </Fragment>
    </>
  );
};

export default TablePartner;
