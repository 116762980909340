// forgot api ---------
import axios from "axios";
export const forgot = async (data) => {
  console.log(data, "data inside api");
  console.log(data?.values, "data inside api");
  try {
    const res = await axios.post(
       global.BASEURL+"api/users/forget-password",     
      {
        phone : data,
      },
      {
        headers: {
        },
      }
    );
    console.log(res.data, "res");
    return res.data;
  } catch (error) {
    console.log(error, "error");
     ; // Rethrow the error to handle it in the component
  }
};
// verificationCode api ---------
export const verificationOtp = async (data) => {
    console.log(data, "data inside api");
    console.log(data?.values, "data inside api");
    var token = localStorage.getItem("verification_token");
    try {
      const res = await axios.post(
         global.BASEURL+"api/users/verify-otp/forget-password",     
        {
            code : data,
            token :token
        },
        {
          headers: {
          },
        }
      );
      console.log(res.data, "res");
      return res.data;
    } catch (error) {
      console.log(error, "error");
       ; // Rethrow the error to handle it in the component
    }
  };
// update password --------
export const updatePassword = async (data) => {
    console.log(data, "data inside api");
    console.log(data?.values, "data inside api");
    var token = localStorage.getItem("verification_token");
    try {
      const res = await axios.put(
         global.BASEURL+"api/users/update-password",     
        {
            password : data,
            token :token
        },
        {
          headers: {
          },
        }
      );
      console.log(res.data, "res");
      return res.data;
    } catch (error) {
      console.log(error, "error");
       ; // Rethrow the error to handle it in the component
    }
  };