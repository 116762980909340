import axios from "axios";
export const createCategory = async (data) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.post(
      global.BASEURL + "api/cat/create",
      {
        name: data?.cat,
        image: data.image,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log(error, "error");
     ; // Rethrow the error to handle it in the component
  }
};
// get req-----------
// export const getCategory = async () => {
//     // console.log(data,'data inside api')
//   try {
//     const token = localStorage.getItem("token");
//     const res = await axios.get(
//       global.BASEURL + "api/cat/admin",
//       {
//         headers: {
//           "Content-Type": "application/json",
//           "x-auth-token": token,
//         },
//       }
//     );
//     console.log(res.data, "res");
//     return res.data;
//   } catch (error) {
//     console.log(error, "error");
//      ; // Rethrow the error to handle it in the component
//   }
// };
export const getCategory = async (id) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found in local storage");
        return;
      }
  
      const res = await axios.get(
        global.BASEURL+"api/cat/page-admin/"+ id,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
          },
        }
      );
  
  
      return res.data;
    } catch (error) {
      console.error("Error fetching category:", error);
      //  ;
    }
  };
  export const getCategory_ = async (id) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found in local storage");
        return;
      }
  
      const res = await axios.get(
        global.BASEURL+`api/cat/admin/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
          },
        }
      );
  
      console.log("Response status:", res.status);
      console.log("Response data:", res.data);
  
      return res.data;
    } catch (error) {
      console.error("Error fetching category:", error);
      //  ;
    }
  };
  export const delCat= async (id) => {
    console.log(id,"id inside api ")
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found in local storage");
        return;
      }
  
      const res = await axios.delete(
         `${global.BASEURL}api/cat/${id}`,
       
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
          },
        }
      );
  
      console.log("Response status:", res.status);
      console.log("Response data:", res.data);
  
      return res.data;
    } catch (error) {
      console.error("Error fetching category:", error);
       ;
    }
  };
  // put req
  export const updateCategories = async (data,id) => {
    console.log(data,'data inside api')
  try {
    const token = localStorage.getItem("token");
    const res = await axios.put(
      global.BASEURL + `api/cat/edit/${id}`,
      {
        name: data?.name,
        image:data?.image
      },
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      }
    );
    console.log(res.data, "res");
    return res.data;
  } catch (error) {
    console.log(error, "error");
     ; // Rethrow the error to handle it in the component
  }
};
// change Status-------
export const changeCatStatus = async (status,id) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.put(
      global.BASEURL + `api/cat/${status}/${id}`,
      {}, 
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      }
    );
    console.log(res.data, "res");
    return res.data;
  } catch (error) {
    console.log(error, "error");
     ; // Rethrow the error to handle it in the component
  }
};
