/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { upload } from "../../../icons/icon";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { Button } from "react-bootstrap";
import { uploadFile } from "../../../api/upload";
import { Form, Row, Spinner } from "reactstrap";
import { changeCatStatus, createCategory, delCat, getCategory, updateCategories } from "../../../api/categories";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavLink, useNavigate } from "react-router-dom";
import { Modal } from "antd";
import CatTabel from "../../../tables/category/catTable";
import { Edit, Trash } from "react-feather";
const AddCat = () => {
  // states-------
  const [loading1, setIsLoading1] = useState(false);
  const [reload, setReload] = useState(false);
  const Navigate = useNavigate();
  const fileInputRef = useRef(null);
  const fileInputRef2 = useRef(null);
  const [showImg, setShowImg] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [previewImage2, setPreviewImage2] = useState(null);
  const [editloading, setEditLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [status, setStatus] = useState('');
  const [page, setpage] = useState("");
  const [count, setCount] = useState(1);
  const [delId, setDelId] = useState("");
  const [rowData, setrowData] = useState("");
  const [updateCat, setUpdateCat] = useState("");
  const [cate, setCate] = useState("");
  const [cat, setCat] = useState("");
  const [catImages, setCatImages] = useState([])

  // functions-------
  const showModal = () => {
    setOpen(true);
  };
  const showModal2 = (row) => {
    setUpdateCat(row?.name);
    setSelectedFile2(row?.image);
    setOpen2(true);
  };
  const handleOk = (e) => {
    setOpen(false);
    delCat(delId)
      .then((res) => {
        toast.success("Deleted SuccessFully..!");
        setReload(true);
        setOpen(false);
      })
      .catch((err) => { });
  };
  const handleCancel = (e) => {
    setOpen(false);
  };
  const handleOk2 = () => {
    setOpen2(false);
  };
  const handleCancel2 = () => {
    setrowData("");
    setOpen2(false);
    setSelectedFile2(null)
    setPreviewImage2(null);
  };
  const handleDivClick = () => {
    fileInputRef.current.click();
  };
  const handleDivClick2 = () => {
    fileInputRef2.current.click();
  };
  useEffect(() => {
    setIsLoading1(true);
    getCategory(count).then((res) => {
      if (!res?.status || res?.status === 'active') {
        setStatus('deactivated');
      } else if (res?.status === 'deactivated') {
        setStatus('active');
      }
      setpage(res?.count?.totalPage)

      setCate(res?.categories);
      setIsLoading1(false);
    });

  }, [count]);
  // image upload Api ------
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewImage(reader.result);
        };
        reader.readAsDataURL(file);
        // Upload file and wait for the result
        const result = await uploadFile(file);
        // Handle the result as needed
        setSelectedFile(result?.image);
      } catch (error) {
        // Handle the error as needed
      }
    }
  };
  const handleFileChange2 = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewImage2(reader.result);
        };
        reader.readAsDataURL(file);
        // Upload file and wait for the result
        const result = await uploadFile(file);
        // Handle the result as needed
        setSelectedFile2(result?.image);
      } catch (error) {
        // Handle the error as needed
      }
    }
  };
  const handleshowImgs = (row) => {
    setShowImg(true)
    setCatImages(row?.image)
  };
  const handleEdit = (e) => {
    setEditLoading(true)
    toast.success("Edited Successfully")
    setOpen2(false)
    e.preventDefault()
    const data = {
      name: updateCat,
      image: selectedFile2
    }
    setEditLoading(true);
    updateCategories(data, rowData?._id).then((res) => {
      setReload(true)
      setEditLoading(false)
    }).catch((err) => { })

  };
  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    const trimmedCat = cat.trim();
    const trimmeddes = description.trim();

    const data = {
      cat: trimmedCat,
      description: trimmeddes,
      image: selectedFile,
    };
    if (!selectedFile && setCat === "" && cat.trim() === ' ' && trimmedCat === '') {
      toast.error("Please fill form first ");
      setIsLoading(false);
      return;
    } else {
      createCategory(data)
        .then((res) => {
          if (res) {
            setIsLoading(false);
            toast.success("Category added successfully...!");
            setPreviewImage(null);
            setCat("");
            getCategory(count).then((res) => {
              setCate(res?.categories);
              setIsLoading1(false);
            });
            // Navigate("/explore/listCat");
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error("Please fill form first ");
        });
    }
  };
  const deactivateStatus = (row) => {
    setIsLoading1(true);
    changeCatStatus('deactivated', row?._id).then((res) => {
      if (res?.message) {
        toast.success('Status Updated Successfully')
        getCategory(count).then((res) => {
          setCate(res?.categories);
          setIsLoading1(false);
        });
      }
    }).catch((err) => { })
  }
  const activateStatus = (row) => {
    setIsLoading1(true);
    changeCatStatus('active', row?._id).then((res) => {
      if (res?.message) {
        toast.success('Status Updated Successfully')
        getCategory(count).then((res) => {
          setCate(res?.categories);
          setIsLoading1(false);
        });
      }
    }).catch((err) => { })
  }
  const columns = [
    {
      name: " Service Name",
      minWidth: "200px",
      sortable: true,
      maxWidth: "45px",
      selector: (row) => row.name,
    },
    {
      name: 'Images',
      maxWidth: "200px",
      // selector: (row) => row.image,
      // sortable: "true",
      cell: (row) => {

        // setDefaultImg(row.image[0]);

        return (
          <NavLink
            style={{ background: "transparent" }}
            onClick={() => handleshowImgs(row)}>

            <div >
              <div className="d-flex gap-2">
                <img
                  className=""
                  width="37px"
                  style={{ borderRadius: "5px", cursor: "pointer" }}
                  height="35px"

                  src={global.Upload + row?.image}
                  alt=""
                />
                <p></p>
              </div>
            </div>


          </NavLink>
        )

      }
    },
    {
      name: " Status",
      minWidth: "200px",
      sortable: true,
      maxWidth: "45px",
      selector: (row) => {
        return (
          <p className={row?.status == 'active' || !row?.status ? ' text-success' : ' text-danger'}>{row?.status == 'active' || !row?.status ? 'Active' : 'De Active'}</p>
        )
      }
    },
    {
      name: "Change Status",
      minWidth: "200px",
      sortable: true,
      maxWidth: "45px",
      selector: (row) => {
        if (row?.status === 'active' || !row?.status) {
          return (
            <button style={{ minWidth: "120px" }} onClick={() => deactivateStatus(row)} className={row?.status == 'active' || !row?.status ? 'btn btn-md btn-danger' : 'btn btn-md btn-success'}>{row?.status == 'active' || !row?.status ? 'De Activate' : 'Activate'}</button>
          )
        }
        else if (row?.status === 'deactivated') {
          return (
            <button style={{ minWidth: "120px" }} onClick={() => activateStatus(row)} className={row?.status == 'active' || !row?.status ? 'btn btn-md btn-danger' : 'btn btn-md btn-success'}>{row?.status == 'active' || !row?.status ? 'De Activate' : 'Activate'}</button>
          )
        }
      },
    },

    {
      name: "Actions",
      allowOverflow: false,
      cell: (row) => {
        return (
          <div className=" d-flex justify-content-center gap-1 align-items-center">
            {/* <div>
              <Trash
                style={{ cursor: "pointer" }}
                size={20}
                onClick={() => {
                  showModal();
                  setDelId(row._id);
                }}
              />
            </div> */}

            <div>
              <Edit
                style={{ cursor: "pointer" }}
                size={20}
                onClick={() => {
                  showModal2(row);
                  // setDelId(row._id);
                  setrowData(row);

                  // setDelId(row._id);
                  // setShowdel(true);
                }}
              />
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div className="tw-pb-5 container bg ">
          <div className="tw-pt-16 md:tw-flex tw-align-start  ">
            <ToastContainer />

            <div
              className="upload tw-rounded-xl tw-bg-white border tw-mr-14 tw-mt-2 border-light tw-p-3 tw-h-50 tw-w-64 tw-cursor-pointer mt-2 "
              onClick={handleDivClick}
            >
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <div className="upload_border tw-h-44 d-flex flex-column align-items-center justify-content-center">
                {previewImage ? (
                  <img
                    src={previewImage}
                    alt="upload preview"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <>
                    <img src={upload} alt="upload" />
                    <div className="text-center ">
                      <p
                        className="m-0 tw-text-xs"
                        style={{ color: "#333", fontSize: "9px" }}
                      >
                        Click to select or ‘Drag and drop’ your Image here
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div>
              <h4 className="pb-4 pt-0 mt-0 tw-text-xl lato_bold">
                Visa Category Name
              </h4>
              <div className="d-flex flex-column align-items-center justify-content-center">

                <div>

                  <TextField
                    value={cat}
                    onChange={(e) => setCat(e.target.value)}
                    label={
                      <span style={{ color: "black" }}>
                        Name <span style={{ color: "#FF6347" }}>*</span>
                      </span>
                    }
                    id="outlined-start-adornment"
                    sx={{
                      m: 1,
                      width: "25ch",
                      "& .MuiInputLabel-root": {
                        color: "red", // Color for label text
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#A09E9D", // Border color
                        },
                        "&:hover fieldset": {
                          borderColor: "#A09E9D", // Border color on hover
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#A09E9D", // Border color on focus
                        },
                        "& input::placeholder": {
                          color: "#333333",
                          fontSize: "13px",
                          // Placeholder color
                        },
                      },
                    }}
                    placeholder="Visa Category Name"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          style={{ color: "red" }}
                        ></InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="ms-1">

                  {/* <TextField
  value={description}
  onChange={(e) => setDescription(e.target.value)}
  label={
    <span style={{ color: "black" }}>
      Description <span style={{ color: "#FF6347" }}>*</span>
    </span>
  }
  id="outlined-start-adornment"
  sx={{
    m: 1,
    width: "25ch",
    "& .MuiInputLabel-root": {
      color: "red", // Color for label text
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#A09E9D", // Border color
      },
      "&:hover fieldset": {
        borderColor: "#A09E9D", // Border color on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "#A09E9D", // Border color on focus
      },
      "& input::placeholder": {
        color: "#333333",
        fontSize: "13px",
        // Placeholder color
      },
    },
  }}
  placeholder="Description"
  InputProps={{
    startAdornment: (
      <InputAdornment
        position="start"
        style={{ color: "red" }}
      ></InputAdornment>
    ),
  }}
/> */}

                </div>
              </div>
              <div className="tw-flex tw-align-middle tw-justify-center mt-4">
                <Button
                  disabled={loading}
                  type="submit"
                  className="auth_btn tw-text-white"
                  color="primary"
                >
                  {loading ? (
                    <>
                      <Spinner size={"sm"} />
                    </>
                  ) : (
                    "Add Category"
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Form>
      <div className="tw-px-4 tw-py-7">
        <CatTabel
          columns={columns}
          data={cate}
          showRow={true}
          rowHeading="New Order"
          loading1={loading1}
          setCount={setCount}
          page={page}
        />
        <Modal
          title="Delete Services"
          open={open}
          onOk={handleOk}
          onCancel={handleCancel}
          okButtonProps={{
            style: { backgroundColor: "#4CAF50", color: "white" }, // Set background color for OK button
          }}
          cancelButtonProps={{
            style: { backgroundColor: "#f44336", color: "white" }, // Set background color for Cancel button
          }}
        >
          Are you Sure...?
        </Modal>
        <div className="edit_modal">
          <Modal
            title="Edit Categories"
            open={open2}
            onOk={handleOk2}
            onCancel={handleCancel2}
            okButtonProps={{
              style: {
                backgroundColor: "#4CAF50",
                color: "white",
                display: "none",
              }, // Set background color for OK button
            }}
            cancelButtonProps={{
              style: {
                backgroundColor: "#f44336",
                color: "white",
                display: "none",
              }, // Set background color for Cancel button
            }}
          >
            <Form onSubmit={handleEdit}>
              <div className="tw-pb-5 container bg ">
                <div className="tw-pt-2 md:tw-flex tw-align-start  ">
                  <div
                    className="upload tw-rounded-xl tw-bg-white border tw-mr-7 tw-mt-2 border-light tw-p-3 tw-h-50 tw-w-64 tw-cursor-pointer mt-2 "
                    onClick={handleDivClick2}
                  >
                    <input
                      type="file"
                      ref={fileInputRef2}
                      style={{ display: "none" }}
                      onChange={handleFileChange2}
                    />
                    <div className="upload_border tw-h-44 d-flex flex-column align-items-center justify-content-center">
                      {previewImage2 ? (
                        <img
                          src={previewImage2}
                          alt="upload preview"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <>
                          <img
                            src={global.Upload + selectedFile2}
                            alt="upload preview"
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div>
                    <h4 className="pb-4 pt-0 mt-0 tw-text-xl lato_bold">
                      Category Name
                    </h4>
                    <TextField
                      value={updateCat}
                      onChange={(e) => setUpdateCat(e.target.value)}
                      label={
                        <span style={{ color: "black" }}>
                          Name <span style={{ color: "#FF6347" }}>*</span>
                        </span>
                      }
                      id="outlined-start-adornment"
                      sx={{
                        m: 1,
                        width: "25ch",
                        "& .MuiInputLabel-root": {
                          color: "red", // Color for label text
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#A09E9D", // Border color
                          },
                          "&:hover fieldset": {
                            borderColor: "#A09E9D", // Border color on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#A09E9D", // Border color on focus
                          },
                          "& input::placeholder": {
                            color: "#333333",
                            fontSize: "13px",
                            // Placeholder color
                          },
                        },
                      }}
                      placeholder="Service Name"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            style={{ color: "red" }}
                          ></InputAdornment>
                        ),
                      }}
                    />

                    <div className="tw-flex tw-align-middle tw-justify-center mt-4">
                      <Button
                        disabled={editloading}
                        type="submit"
                        className="auth_btn tw-text-white"
                        color="primary"
                      >
                        {editloading ? (
                          <>
                            <Spinner size={"sm"} />
                          </>
                        ) : (
                          "Edit Categories"
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default AddCat;
