import React from "react";
import { plus } from "../icons/icon";

const BlueButton = (props) => {
  return (
    <div>
      <button
      type="button"
      onClick={props.onClick}
        className="d-flex justify-content-between gap-3"
        style={{
          backgroundColor: props.bg,
          color: props.color,
          padding: props.padding,
          borderRadius: props.bR,
        }}
      >
        <p className="m-0">{props.content}</p>
        <img alt="plus" src={plus}/>
      </button>
    </div>
  );
};

export default BlueButton;
