/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useEffect } from "react";
import logo from "../assets/png/visaLogo.png";
import auth from "../assets/png/auth.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Col,
  FormFeedback,
  Spinner,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { login } from "../api/loginApi";
import { toast } from "react-toastify";
const Login = ({ setIsLoggedIn }) => {
  // states----
  const [loading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  // form validation---------
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      phone: "",
      password: "",
    },
    validationSchema: Yup.object({
      phone: Yup.string("Please Enter phone number").required(
        "Phone number is required"
      ),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Please Enter Password"),
    }),
    onSubmit: async (values, { resetForm }) => {
      console.log(values, "login credientials...!");
      setIsLoading(true);
      login(values)
        .then((res) => {
          // if (res?.token) {
            setIsLoggedIn(true);
            navigate("/explore/listCat");
            setIsLoading(false);
            toast.success("Login Successfully...!");
            resetForm();
          // } else {
            // toast.error("Invalid credientials");
            // setIsLoading(false);
          // }
        })
        .catch((err) => {
          console.log(err,"catch")
          toast.error(err?.message);
          setIsLoading(false);
        });
    },
  });

  return (
    <div className="container min-[1440px]:tw-px-10 ">
      <div className="tw-pb-5">
        <div className="tw-pt-10">
          <img src={logo} width="200px" alt="" />
        </div>
        <div className="register_main d-sm-flex flex-lg-row flex-sm-column  justify-content-between align-items-center tw-px-0 px-0">
          <div className="">
            <img src={auth} alt="authPic" className="img-fluid" />
          </div>
          <div>
            <div className="md:tw-w-96">
              <div className="lato_regular tw-text-4xl tw-pt-10 ">
                Welcome back! Glad to see you, Again!
              </div>
            </div>
            <Form onSubmit={validation.handleSubmit} className="mt-3">
              <FormGroup row>
                <Col md={10} sm={12}>
                  <div className="login_phonebook">
                    <PhoneInput
                      country={"ae"}
                      inputClass="form-control"
                      containerClass="phone-input-container"
                      inputProps={{
                        name: "phone",
                        id: "phone",
                        placeholder: "Enter phone",
                      }}
                      value={validation.values.phone || ""}
                      onChange={(phone) =>
                        validation.handleChange({
                          target: { name: "phone", value: `+${phone}` },
                        })
                      }
                      onBlur={validation.handleBlur}
                      isValid={
                        validation.touched.phone && !validation.errors.phone
                      }
                      inputStyle={
                        {
                          // Add any additional styles you need for the input
                        }
                      }
                    />
                  </div>
                  {validation.touched.phone && validation.errors.phone ? (
                    <FormFeedback type="invalid">
                      {validation.errors.phone}
                    </FormFeedback>
                  ) : null}
                </Col>
                {/* <Col md={10} sm={12}>
                  <Input
                    type="text"
                    className="form-control"
                    name="phone"
                    id="phone"
                    placeholder="Phone Number"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.phone || ""}
                    invalid={
                      validation.touched.phone && validation.errors.phone
                        ? true
                        : false
                    }
                  />
                  {validation.touched.phone && validation.errors.phone ? (
                    <FormFeedback type="invalid">
                      {validation.errors.phone}
                    </FormFeedback>
                  ) : null}
                </Col> */}
              </FormGroup>
              <FormGroup>
                <Col md={10} sm={12} className="p-0">
                  <Input
                    type="password"
                    className="form-control "
                    name="password"
                    id="password"
                    placeholder="Password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.password || ""}
                    invalid={
                      validation.touched.password && validation.errors.password
                        ? true
                        : false
                    }
                  />
                  {validation.touched.password && validation.errors.password ? (
                    <FormFeedback type="invalid">
                      {validation.errors.password}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Link
                  to="/forgot-password"
                  className="forgot lato_medium tw-cursor-pointer tw-flex tw-me-16 tw-justify-end pb-4"
                >
                  Forgot Password
                </Link>
              </FormGroup>
              {/* <Link to='/register' className="forgot tw-flex tw-justify-end pb-4">
                <p className="lato_medium tw-cursor-pointer">
                  Don't have an Account..?
                </p>
              </Link> */}

              <div className="tw-flex  w-100">
                <Button
                  style={{ width: "100%" }}
                  disabled={loading}
                  type="submit"
                  className="auth_btn tw-text-white"
                  color="primary"
                >
                  {loading ? (
                    <>
                      <Spinner size={"sm"} />
                    </>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
