/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import logo from "../assets/png/visaLogo.png";
import auth from "../assets/png/auth.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { register } from "../api/loginApi";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Col,
  FormFeedback,
  Spinner,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
const Register = ({setIsLoggedIn}) => {
  const navigate=useNavigate()
  const [loading, setIsLoading] = useState(false);
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      email: "",
      phone: "",
      password: "",
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      phone: Yup.string().required("Phone number is required"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true);
      console.log(values);
      register(values).then((res) => {
        navigate("/explore/listCat");
        setIsLoading(false);
        setIsLoggedIn(true)
        toast.success("Registered Successfully...!");
        resetForm();
      }).catch((err)=>{
        toast.error('Credientials already exist')
        console.log(err.response.data);
        setIsLoading(false);

      });
      // islogin();
      // navigate("/explore/listCat");
    },
  });
  return (
    <div className="tw-pb-5 container  min-[1440px]:tw-px-10 ">
      <ToastContainer />
      <div className="tw-pt-10">
        <img src={logo} width="200px" alt="" />
      </div>

      <div className="register_main d-sm-flex flex-lg-row flex-sm-column  justify-content-between align-items-center tw-px-0 px-0">
        <div className="">
          <img src={auth} alt="authPic" className="img-fluid" />
        </div>
        <div>
          <div className="md:tw-w-96">
            <div className="lato_regular tw-text-4xl tw-pt-10 ">
              Hello! Register to get started
            </div>
          </div>
          <Form onSubmit={validation.handleSubmit} className="mt-3">
            <FormGroup row>
              <Col md={10} sm={12}>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  invalid={
                    validation.touched.name && validation.errors.name
                      ? true
                      : false
                  }
                />
                {validation.touched.name && validation.errors.name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.name}
                  </FormFeedback>
                ) : null}
              </Col>
            </FormGroup>

            <FormGroup row>
              <Col md={10} sm={12}>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ""}
                  invalid={
                    validation.touched.email && validation.errors.email
                      ? true
                      : false
                  }
                />
                {validation.touched.email && validation.errors.email ? (
                  <FormFeedback type="invalid">
                    {validation.errors.email}
                  </FormFeedback>
                ) : null}
              </Col>
            </FormGroup>

            <FormGroup>
              <Col md={10} sm={12}>
                <Input
                  type="tel"
                  name="phone"
                  id="phone"
                  placeholder="Phone number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.phone || ""}
                  invalid={
                    validation.touched.phone && validation.errors.phone
                      ? true
                      : false
                  }
                />
                {validation.touched.phone && validation.errors.phone ? (
                  <FormFeedback type="invalid">
                    {validation.errors.phone}
                  </FormFeedback>
                ) : null}
              </Col>
            </FormGroup>

            <FormGroup row>
              <Col md={10} sm={12}>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.password || ""}
                  invalid={
                    validation.touched.password && validation.errors.password
                      ? true
                      : false
                  }
                />
                {validation.touched.password && validation.errors.password ? (
                  <FormFeedback type="invalid">
                    {validation.errors.password}
                  </FormFeedback>
                ) : null}
              </Col>
            </FormGroup>

            {/* <FormGroup row>
              <Col md={10} sm={12}>
                <Input
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  placeholder="Confirm Password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.confirmPassword || ""}
                  invalid={
                    validation.touched.confirmPassword &&
                    validation.errors.confirmPassword
                      ? true
                      : false
                  }
                />
                {validation.touched.confirmPassword &&
                validation.errors.confirmPassword ? (
                  <FormFeedback type="invalid">
                    {validation.errors.confirmPassword}
                  </FormFeedback>
                ) : null}
              </Col>
            </FormGroup> */}

            <div className="tw-flex tw-align-middle tw-justify-center">
           
              <Button
                disabled={loading}
                type="submit"
                className="auth_btn tw-text-white"
                color="primary"
              >
                {loading ? (
                  <>
                    <Spinner size={"sm"} />
                  </>
                ) : (
                  "Submit"
                )}
              </Button>
            
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Register;
