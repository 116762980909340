import axios from "axios";
export const createVisa = async (data) => {
  console.log(data?.destination, "data inside api");
  console.log(data?.country, "data inside api");
  try {
    const token = localStorage.getItem("token");
    const res = await axios.post(
      global.BASEURL + "api/visa/create",
      {
        image: data?.image,
        residence: data?.residence,
        destination: data?.destination,
        cat_id: data?.cat_id,
        service:data?.service,
        country: data?.country,
        stay_duration: data?.values?.stayDuration ,
        visa_validity: data?.values?.visaValidity,
        visa_entry: data?.values?.visaEntry,
        processing_time: data?.values?.processingTime,
        steps: JSON.stringify(data?.steps),
        note:data?.note,
        counselar_cost: data?.values?.counsellorCost,
        offers: data?.values?.offersAndBenefits,
        entry_restrictions: data?.values?.entryRestrictions,
        entry_health_req: data?.values?.entryHealthRequirements,
        in_country_exp: data?.values?.inCountryExperience,
        transit_restrictions: data?.values?.transitRestrictions,
        re_entry_req: data?.values?.reEntryRequirements,
        standard_cost: data?.values?.standardVisaCost,
        checklist: JSON.stringify(data?.checklist),
      },
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      }
    );
    console.log(res.data, "res");
    return res.data;
  } catch (error) {
    console.log(error, "error");
      // Rethrow the error to handle it in the component
  }
};
// get api ---------
export const getVisa = async () => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token not found in local storage");
      return;
    }
    const res = await axios.get(
       global.BASEURL+"api/visa/admin/all/1",
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      }
    );
    console.log("Response status:", res.status);
    console.log("Response data:", res.data);

    return res.data;
  } catch (error) {
    console.error("Error fetching category:", error);
     ;
  }
};
export const getfilteredVisa= async(val)=>{
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token not found in local storage");
      return;
    }

    const res = await axios.get(
       global.BASEURL+`api/visa/admin/all/1/${val}`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      }
    );

    console.log("Response status:", res.status);
    console.log("Response data:", res.data);

    return res.data;
  } catch (error) {
    console.error("Error fetching category:", error);
     ;
  }
}
// get visa by id----------
export const getVisaById= async(id)=>{
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Token not found in local storage");
      return;
    }

    const res = await axios.get(
       global.BASEURL+`api/visa/detail/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      }
    );

    console.log("Response status:", res.status);
    console.log("Response data:", res.data);

    return res.data;
  } catch (error) {
    console.error("Error fetching category:", error);
     ;
  }
}
export const updateVisa = async (data,id) => {
  console.log(data, "data inside api");
  console.log(data?.values, "data inside api");
  try {
    const token = localStorage.getItem("token");
    const res = await axios.put(
      global.BASEURL + `api/visa/update/${id}`,
      {
        image: data?.image,
        residence: data?.residence,
        destination: data?.destination,
        cat_id: data?.cat_id,
        service:data?.service,
        country: data?.country,
        stay_duration: data?.values?.stayDuration ,
        visa_validity: data?.values?.visaValidity,
        visa_entry: data?.values?.visaEntry,
        processing_time: data?.values?.processingTime,
        steps: JSON.stringify(data?.steps),
        note:data?.note,
        counselar_cost: data?.values?.counsellorCost,
        offers: data?.values?.offersAndBenefits,
        entry_restrictions: data?.values?.entryRestrictions,
        entry_health_req: data?.values?.entryHealthRequirements,
        in_country_exp: data?.values?.inCountryExperience,
        transit_restrictions: data?.values?.transitRestrictions,
        re_entry_req: data?.values?.reEntryRequirements,
        standard_cost: data?.values?.standardVisaCost,
        checklist: JSON.stringify(data?.checklist),
      },
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      }
    );
    console.log(res.data, "res");
    return res.data;
  } catch (error) {
    console.log(error, "error");
     ; // Rethrow the error to handle it in the component
  }
};