import React, { useState, useRef } from "react";
import Box from "@mui/material/Box";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { mdi_user } from "../../icons/icon";
import TextField from "@mui/material/TextField";
import BlueButton from "../../resueComponent/blueButton";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import { Button, Form, Input, Label, Spinner, FormFeedback } from "reactstrap";
import { uploadFile, uploadPdf } from "../../api/upload";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerPartner } from "../../api/partner";
import { format } from "date-fns";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Autocomplete from "@mui/material/Autocomplete";
import countries from "../explore/country/countryData";
import { checkEmail, checkPhone } from "../../api/check";
const theme = createTheme();
// import { Input } from 'reactstrap';
// import { Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
// import '@react-pdf-viewer/core/lib/styles/index.css';
// import '@react-pdf-viewer/default-layout/lib/styles/index.css';
const AddPartner = () => {
  const dynamicLabel = "Dynamic Label"; // or fetch the label from some source
  const [pdfFile, setPdfFile] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [phone, setPhone] = useState("");
  // Function to format the date
  const formatDate = (date) => {
    return format(date, "dd-MMM-yyyy"); // Format the date as "27-Dec-2017"
  };
  // formik formik--------
  const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),
    residence: yup.string().required("Residence is required"),
    phone: yup.string().required("Phone number is required"),
    password: yup.string().required("Password is required").min("6"),
    nationality: yup.string().required("Nationality is required"),
    // dob: yup.string().required("Date is required"),
    address1: yup.string().required("Address is required"),
    address2: yup.string().required("Address2 is required"),
    commissions: yup.string().required("commission is required"),
    // operating_country: yup.string().required("operating country is required"),
    zipCode: yup.string().required("Zip code is required"),
    idNumber: yup.string().required("ID Number is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      dob: "",
      nationality: "",
      residence: "",
      address1: "",
      address2: "",
      profilePicture: "",
      password: "",
      commissions: "",
      operating_country: "",
      zipCode: "",
      idNumber: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);

      try {
        // Validate form fields
        if (!selectedFile || selectedLabel === "" || !startDate || !phone) {
          toast.error("Fill in all required fields");
          setIsLoading(false);

          return;
        }

        // Check email
        const emailResponse = await checkEmail(values.email);
        if (emailResponse?.data?.success === false) {
          setIsLoading(false);

          toast.error("Email already exists");
          return;
        }

        // Check phone
        const phoneResponse = await checkPhone(values.phone);
        if (phoneResponse?.data?.success === false) {
          toast.error("Phone already exists");
          setIsLoading(false);

          return;
        }

        // If all checks passed, proceed with API call
        const data = {
          name: values.name,
          email: values.email,
          phone: values.phone,
          nationality: values.nationality,
          commissions: values.commissions,
          operating_country: selectedLabel,
          dob: startDate,
          address1: values.address1,
          address2: values.address2,
          profilePicture: selectedFile,
          residence: values.residence,
          password: values.password,
          zipcode: values.zipCode,
          doc_url: selectedFile2,
          idNumber: values.idNumber,
        };

        const registerResponse = await registerPartner(data);
        setIsLoading(false);
        console.log(registerResponse, "response");
        navigate("/partner/listPartner");
        resetForm();
        setSelectedFile(null);
        setSelectedFile2(null);
        setPreviewImage2(null);
        toast.success("Partner added successfully...!");
      } catch (error) {
        setIsLoading(false);
        if (error) {
          toast.error("Credentials already exist");
        } else {
          toast.error("Failed to add partner");
        }
      }
    },
  });

  // states ------------
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [loading, setIsLoading] = useState(false);
  const [previewImage2, setPreviewImage2] = useState(null);
  const [password, setPassword] = useState("");
  const [startDate, setStartDate] = useState(null);
  const navigate = useNavigate();
  const [selectedLabel, setSelectedLabel] = useState("");
  // functions-----------
  const handleAutocompleteChange = (event, newValue) => {
    console.log(newValue?.label, "label");
    // Handle the change and set the selected label
    setSelectedLabel(newValue?.label || "");
  };
  const handleButtonClick = () => {
    // fileInputRef.current.click();
  };
  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        // Upload file and wait for the result
        const result = await uploadFile(file);
        // Handle the result as needed
        setSelectedFile(result?.image);
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewImage(reader.result);
        };
        reader.readAsDataURL(file);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const handleSecondImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const result = await uploadPdf(file);
        setSelectedFile2(result?.image);

        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewImage2(reader.result);
        };
        reader.readAsDataURL(file);
      } catch (error) {
        console.error("Error uploading file :", error);
      }
    }
  };
  // const handleSecondImageChange = async (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     try {
  //       // Assuming uploadFile is a function to upload the file
  //       const result = await uploadFile(file);
  //       setSelectedFile2(result?.pdfUrl);

  //       // Assuming you want to display a preview of the PDF
  //       const reader = new FileReader();
  //       reader.onloadend = () => {
  //         setPreviewPdf(reader.result);
  //       };
  //       reader.readAsDataURL(file);
  //     } catch (error) {
  //       console.error("Error uploading file :", error);
  //     }
  //   }
  // };
  console.log(startDate, "ss");

  return (
    <div className="bg ">
      <div className="tw-pb-5 container tw-pt-8 tw-flex tw-flex-wrap tw-align-start tw-gap-6  ">
        <ThemeProvider theme={theme}>
          <Box width={{ md: "94%", xs: "100%" }}>
            <ToastContainer />
            <Box sx={{ bgcolor: "#fff", minHeight: "100vh" }}>
              <div className="md:tw-px-16 sm:tw-px-8 tw-px-4 tw-pt-5">
                <Form onSubmit={formik.handleSubmit}>
                  <div className="row profile ">
                    <div className=" col-md-4 col-12 profile_child_1 ">
                      <div className="d-flex flex-column gap-5   ">
                        <div>
                          <img
                            className="avatar"
                            alt=""
                            src={
                              previewImage ? `${previewImage}` : `${mdi_user}`
                            }
                          />
                        </div>
                      </div>
                      <button
                        className="bgprimary tw-rounded-md position-relative tw-px-3 tw-mt-8 tw-py-2 tw-text-white"
                        onClick={handleButtonClick}
                      >
                        Upload Image
                        <input
                          type="file"
                          className="innerFile tw-cursor-pointer"
                          accept="image/*"
                          onChange={handleImageChange}
                          id="avatarInput"
                        />
                      </button>
                    </div>
                    <div className=" col-md-8 col-12 md:tw-text-start tw-text-center profile_child_2  tw-py-8 sm:tw-py-8">
                      <p className="lato_bold">Personal Details</p>
                      <div className="d-flex flex-md-row flex-column gap-4 mt-3 ">
                        <TextField
                          style={{ width: "100%" }}
                          id="name"
                          name="name"
                          label={
                            <>
                              Name <span className="tw-text-red-600">*</span>
                            </>
                          }
                          variant="outlined"
                          className="myCustomTextField"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.name && Boolean(formik.errors.name)
                          }
                          helperText={formik.touched.name && formik.errors.name}
                        />

                        <TextField
                          style={{ width: "100%" }}
                          id="email"
                          name="email"
                          label={
                            <>
                              Email <span className="tw-text-red-600">*</span>
                            </>
                          }
                          variant="outlined"
                          className="myCustomTextField"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                          helperText={
                            formik.touched.email && formik.errors.email
                          }
                        />
                      </div>
                      <div className="d-flex flex-md-row flex-column gap-4 mt-3 ">
                        <TextField
                          style={{ width: "100%", maxWidth: "100%" }} // Adjust the max-width as needed
                          id="commissions"
                          name="commissions"
                          label={
                            <>
                              Commissions{" "}
                              <span className="tw-text-red-600">*</span>
                            </>
                          }
                          variant="outlined"
                          className="myCustomTextField"
                          value={formik.values.commissions}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.commissions &&
                            Boolean(formik.errors.commissions)
                          }
                          helperText={
                            formik.touched.commissions &&
                            formik.errors.commissions
                          }
                        />

                        <DatePicker
                          label={dynamicLabel}
                          style={{ width: "100%" }}
                          placeholderText="Pick date"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          className="w-100"
                          // Render custom input value
                          renderCustomInput={(
                            props,
                            openCalendar,
                            closeCalendar
                          ) => (
                            <input
                              {...props}
                              value={startDate ? formatDate(startDate) : ""}
                              onFocus={openCalendar}
                              onBlur={closeCalendar}
                              style={{ width: "100%" }} // Add your custom styles here
                            />
                          )}
                          portalId="datepicker-portal" // Assign a unique ID for the portal
                          appendToBody={true} // Append the datepicker to the body
                          popperModifiers={{
                            preventOverflow: {
                              enabled: true,
                            },
                            flip: {
                              enabled: true,
                            },
                            offset: {
                              enabled: true,
                              offset: "5px, 10px", // Adjust the offset as needed
                            },
                            zIndex: {
                              enabled: true,
                              zIndex: 99, // Set the zIndex as needed
                            },
                          }}
                        />
                      </div>
                      <div className="row pt-4">
                        <div className="col-md-6 col-12  any">
                          <PhoneInput
                            className="w-md-50 w-100"
                            country={"us"}
                            // value={formik.values.phone}
                            // onChange={(phone) =>
                            //   formik.setFieldValue("phone", phone)
                            // }
                            onChange={(phone) => {
                              setPhone(phone);

                              formik.handleChange({
                                target: { name: "phone", value: `+${phone}` },
                              });
                            }}
                            inputStyle={{
                              border: "1px solid #ced4da", // Adjust the border color
                            }}
                            // Adjust the container width if needed
                          />
                        </div>
                        <div className="col-md-6 col-12">
                          <TextField
                            className="myCustomTextField w-md-50 w-100"
                            id="nationality"
                            name="nationality"
                            label={
                              <>
                                Nationality{" "}
                                <span className="tw-text-red-600">*</span>
                              </>
                            }
                            variant="outlined"
                            value={formik.values.nationality}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.nationality &&
                              Boolean(formik.errors.nationality)
                            }
                            helperText={
                              formik.touched.nationality &&
                              formik.errors.nationality
                            }
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-md-row flex-column gap-4 mt-3">
                        <div className="w-md-50 w-100">
                          <TextField
                            className="myCustomTextField w-md-50 w-100"
                            id="residence"
                            name="residence"
                            label={
                              <>
                                Residence{" "}
                                <span className="tw-text-red-600">*</span>
                              </>
                            }
                            variant="outlined"
                            value={formik.values.residence}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.residence &&
                              Boolean(formik.errors.residence)
                            }
                            helperText={
                              formik.touched.residence &&
                              formik.errors.residence
                            }
                          />
                        </div>

                        <div className="d-flex flex-column w-md-50 w-100">
                          <TextField
                            className="myCustomTextField w-md-50 w-100"
                            // onChange={(e) => setPassword(e.target.value)}
                            id="password"
                            name="password" // Update this line to match the name in your validation schema
                            label={
                              <>
                                Password{" "}
                                <span className="tw-text-red-600">*</span>
                              </>
                            }
                            variant="outlined"
                            value={formik.values.password} // Update this line to match the name in your validation schema
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.password && formik.errors.password
                                ? true
                                : false
                            }
                            error={
                              formik.touched.password &&
                              Boolean(formik.errors.password)
                            }
                            helperText={
                              formik.touched.password && formik.errors.password
                            }
                          />
                          {/* {password == "" ? (
                            <p
                              className="text-danger ms-2"
                              style={{ fontSize: "12px" }}
                            >
                              password is required
                            </p>
                          ) : (
                            ""
                          )} */}
                        </div>
                      </div>

                      <div className="d-flex flex-md-row flex-column gap-4 mt-3">
                        <TextField
                          className="myCustomTextField w-md-50 w-100"
                          id="address1"
                          name="address1"
                          label={
                            <>
                              Address1{" "}
                              <span className="tw-text-red-600">*</span>
                            </>
                          }
                          variant="outlined"
                          value={formik.values.address1}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.address1 &&
                            Boolean(formik.errors.address1)
                          }
                          helperText={
                            formik.touched.address1 && formik.errors.address1
                          }
                        />
                        <TextField
                          className="myCustomTextField w-md-50 w-100"
                          id="address2"
                          name="address2"
                          label={
                            <>
                              Address2{" "}
                              <span className="tw-text-red-600">*</span>
                            </>
                          }
                          variant="outlined"
                          value={formik.values.address2}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.address2 &&
                            Boolean(formik.errors.address2)
                          }
                          helperText={
                            formik.touched.address2 && formik.errors.address2
                          }
                        />
                      </div>

                      <div className="d-flex flex-md-row flex-column gap-4 mt-3">
                        {/* <TextField
                          className="myCustomTextField w-md-50 w-100"
                          id="country"
                          name="country"
                          label={
                            <>
                              Country <span className="tw-text-red-600">*</span>
                            </>
                          }
                          variant="outlined"
                          value={formik.values.country}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.country &&
                            Boolean(formik.errors.country)
                          }
                          helperText={
                            formik.touched.country && formik.errors.country
                          }
                        /> */}
                        <TextField
                          className="myCustomTextField w-md-50 w-100"
                          id="zipCode"
                          name="zipCode"
                          label={
                            <>
                              Zip Code{" "}
                              <span className="tw-text-red-600">*</span>
                            </>
                          }
                          variant="outlined"
                          value={formik.values.zipCode}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.zipCode &&
                            Boolean(formik.errors.zipCode)
                          }
                          helperText={
                            formik.touched.zipCode && formik.errors.zipCode
                          }
                        />
                      </div>
                      {/* <div className="col-md-12 my-2 px-1">
                        <h5 className="pb-1 px-0" style={{ color: "grey" }}>
                          Doc Url<span className="tw-text-red-600"> *</span>
                        </h5>
                        <div className="doc">
                          <Input
                            style={{ paddingTop: "7px" }}
                            type="file"
                            className=""
                            accept=".pdf"
                            onChange={handleSecondImageChange}
                          />
                        </div>
                      </div> */}
                      {/* {pdfUrl && (
                        <div style={{ height: "500px", marginTop: "20px" }}>
                          <Viewer fileUrl={pdfUrl} />
                        </div>
                      )} */}

                      <div className="col-md-12 my-2 px-1">
                        <h5 className="pb-1 px-0" style={{ color: "grey" }}>
                          Doc Url<span className="tw-text-red-600"> *</span>
                        </h5>
                        <div className="doc ">
                          <Input
                            style={{ paddingTop: "7px" }}
                            type="file"
                            className=""
                            accept=".pdf" // Specify the accepted file type as PDF
                            onChange={handleSecondImageChange}
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-md-row flex-column gap-4 mt-3 px-0">
                        <TextField
                          className="myCustomTextField w-100"
                          id="idNumber"
                          name="idNumber"
                          label={
                            <>
                              ID Number{" "}
                              <span className="tw-text-red-600">*</span>
                            </>
                          }
                          variant="outlined"
                          value={formik.values.idNumber}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.idNumber &&
                            Boolean(formik.errors.idNumber)
                          }
                          helperText={
                            formik.touched.idNumber && formik.errors.idNumber
                          }
                        />
                        <Autocomplete
                          id="country-select-demo"
                          sx={{ width: "100%" }}
                          options={countries}
                          value={{ label: selectedLabel }}
                          autoHighlight
                          onChange={handleAutocompleteChange}
                          getOptionLabel={(option) => {
                            return option.label;
                          }}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              <img
                                loading="lazy"
                                width="20"
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                alt=""
                              />
                              {option.label} ({option.code}) +{option.phone}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                <>
                                  Operating Country{" "}
                                  <span className="tw-text-red-600">*</span>
                                </>
                              }
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password", // disable autocomplete and autofill
                              }}
                              InputProps={{
                                ...params.InputProps,
                                classes: {
                                  focused: "custom-focused", //   your custom-focused class here
                                },
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: "custom-label-root", // Add your custom label root class here
                                },
                              }}
                            />
                          )}
                        />
                        {/* <TextField
                          style={{ width: "100%", maxWidth: "100%" }} // Adjust the max-width as needed
                          id="operating_country"
                          name="operating_country"
                          label={
                            <>
                              Operating Country{" "}
                              <span className="tw-text-red-600">*</span>
                            </>
                          }
                          variant="outlined"
                          className="myCustomTextField"
                          value={formik.values.operating_country}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.operating_country &&
                            Boolean(formik.errors.operating_country)
                          }
                          helperText={
                            formik.touched.operating_country &&
                            formik.errors.operating_country
                          }
                        /> */}
                      </div>

                      <div className="text-start pb-5 pt-4">
                        <button
                          disabled={loading}
                          type="submit"
                          className="bgprimary tw-text-white tw-rounded-md tw-px-4 tw-py-1  "
                        >
                          {loading ? (
                            <>
                              <Spinner size={"sm"} />
                            </>
                          ) : (
                            "Add Partner"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default AddPartner;
