import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import {
  country,
  country2,
  country3,
  country4,
  country5,
  home,
  india,
} from "../../../icons/icon";
import { Link, useNavigate } from 'react-router-dom';

const ListCountry = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    // Assuming you want to navigate to "explore/addCountry"
    navigate('/explore/addCountry');
  };
  const countries = [
    {
      name: "India",
      cont: "Continent",
      img: india,
      req: "Visa Required",
    },
    {
      name: "Austrailia",
      cont: "Continent",
      img: country2,
      req: "Visa Required",
    },
    {
      name: "China",
      cont: "Continent",
      img: country3,
      req: "Visa Required",
    },
    {
      name: "Canada",
      cont: "Continent",
      img: country4,
      req: "Visa Required",
    },
    {
      name: "Nepal",
      cont: "Continent",
      img: country5,
      req: "Visa Required",
    },
    {
      name: "Germany",
      cont: "Continent",
      img: country,
      req: "Visa Required",
    },
  ];

  return (
    <div className="tw-bg-white tw-pt-5  ">
    <div onClick={handleNavigate} className="text-end">

    <button className=" bgprimary tw-rounded-md tw-px-4 tw-mb-3 tw-mr-4  tw-py-2 tw-text-white"> Add Country</button>
    </div>
    <div className="bg">
    
      <div className="tw-pb-5 container tw-pt-10 tw-flex tw-flex-wrap tw-align-start tw-gap-6  ">
        {countries?.map((val, i) => {
          return (
            <div key={i}>
              <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                  component="img"
                  height="140"
                  image={val.img}
                  alt="green iguana"
                />
                <CardContent>
                  <div>
                    <p>
                      <span
                        className="lato_bold tw-text-lg"
                        style={{ marginRight: "10px" }}
                      >
                        {val.name}
                      </span>
                      <span className="tw-text-sm tw-text-gray-500">
                        {val.cont}
                      </span>
                    </p>
                  </div>

                  <p className="lato_regular">{val.req}</p>
                </CardContent>
              </Card>
            </div>
          );
        })}
      </div>
    </div>
    </div>
  );
};

export default ListCountry;
