import React, { useState } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";

import { Link, useNavigate } from "react-router-dom";
import {
  explore,
  exploreDark,
  logo,
  logout,
  partner,
  partnerLight,
  profile,
  profileLight,
  security,
  support,
  track,
  trackLight,
} from "../icons/icon";
const MySidebar = ({
  children,
  setToggled,
  toggled,
  setBroken,
  setIsLoggedIn,
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const [selectedLink, setSelectedLink] = useState(
    window?.location?.pathname == "/" ? "1" : ""
  );
  const [show, setShow] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = (item) => {
    setIsHovered(item);
  };
  const handleMouseLeave = () => {
    setIsHovered(null);
  };
  const handleLinkClick=()=>{

  }
  const handleLinkClick2 = (itemId,i) => {
    console.log(itemId,i,"chek")
  
    if (itemId === "9") {
      window?.localStorage.removeItem("token");
      setIsLoggedIn(false);
      navigate("/");
    }
    setSelectedLink(itemId);
    setToggled(false);
    setShow(false);
  };
  const menuItems = [
    {
      id: "1",
      image: explore,
      image2: exploreDark,
      items: "Explore",
      path: "/explore/listCat",
    },
    {
      id: "2",

      image: profileLight,
      image2: profile,

      items: "Profile",
      path: "/profile/update",
    },
    {
      id: "3",

      image: trackLight,
      image2: track,

      items: "Track",
      path: "/track/listTrack",
    },
    {
      id: "4",

      image: partnerLight,
      image2: partner,

      items: "Partner",
      path: "/partner/listPartner",
    },
    {
      id: "5",

      image: partnerLight,
      image2: partner,

      items: "Customer",
      path: "/customer/listCustomer",
    },
  ];
  const menuItems2 = [
    {
      id: "7",
      image: security,
      // image2: exploreDark,
      items: "Privacy Policy",
      path: "/security",
    },
    {
      id: "8",

      image: support,
      // image2: profile,

      items: "Help & Support",
      path: "/support",
    },
    {
      id: "9",

      image: logout,
      // image2: track,

      items: "Logout",
      path: "/",
    },
  ];
  return (
    <div className="tw-flex tw-h-screen tw-min-h-screen">
      <div className="tw-h-screen tw-relative tw-z-[9999]">
        <Sidebar
          width="200px"
          style={{ height: "100%" }}
          collapsed={collapsed}
          toggled={toggled}
          backgroundColor="white"
          onBackdropClick={() => {
            setToggled(false);
            setShow(false);
          }}
          onBreakPoint={setBroken}
          breakPoint="xl"
        >
          <div
            className="parent_side"
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <div className="mb-4 mt-3">
              <img
                src={logo}
                className="tw-max-h-[2rem] tw-w-[100%] px-5 mb-2 mt-4"
                alt=""
              />
            </div>
            <Menu className="tw-w-full">
              {/* <h1 className="manrope_medium tw-text-[#A8A8A8] ms-3 mb-3 tw-text-xs">
                Main Menu
              </h1> */}

              {menuItems?.map((item, i) => (
                <MenuItem
                  key={i}
                  onClick={() => handleLinkClick2(item.id,i)}
                  component={<Link to={item.path} />}
                  onMouseEnter={() => handleMouseEnter(item.id)}
                  onMouseLeave={handleMouseLeave}
                  className={`text-sm w-full ${
                    selectedLink === item.id ||
                    window?.location?.pathname === item.path || (window?.location?.pathname === '/explore/addCat'  && i === 0 )||
                    (window?.location?.pathname === "/visa/details" && i === 0) ||
                    (window?.location?.pathname === "/add/services" && i === 0)
                     ||(window?.location?.pathname === "track/details/:id" &&
                      i === 2) || (window?.location?.pathname === "/partner/addPartner" && i === 3)  
                      ? "lato_bold active tw-text-white"
                      : "lato_bold"
                  }`}
                >
                  <div className="tw-flex tw-items-center gap-3">
                    {isHovered === item.id ||
                    selectedLink === item.id ||
                    window?.location?.pathname === item.path ||
                    (window?.location?.pathname === "/explore/addCat" &&
                      i === 0) ||(window?.location?.pathname === "/explore/addCat" &&
                      i === 0) ||
                    (window?.location?.pathname === "/visa/details" &&
                      i === 0) ||
                      (window?.location?.pathname === "/add/services" && i === 0)||
                    (window?.location?.pathname === "track/details/:id" &&
                      i === 2) ||  (window?.location?.pathname === "/partner/addPartner" && i === 3) ||
                      (window?.location?.pathname === "/customer/listCustomer" && i === 4)
                       ? (
                      <img src={item.image} alt="" />
                    ) : (
                      <img src={item.image2} alt="" />
                    )}

                    <div className="lato_medium">{item.items}</div>
                  </div>
                </MenuItem>
              ))}
            </Menu>
            <div className="child_side">
              {menuItems2?.map((item, i) => (
                
                <Menu key={i} className="tw-w-full">
                  <MenuItem
                    onClick={() => handleLinkClick2(item.id,i)}
                    component={<Link to={item.path} />}
                    // onMouseEnter={() => handleMouseEnter(item.id)}
                    // onMouseLeave={handleMouseLeave}
                    className={`text-sm w-full ${
                      selectedLink === item.id===7 ||
                      (window?.location?.pathname === "/security" && i ===0)
                      ||
                      (window?.location?.pathname === "/support" && i ===1)

                        ? "lato_bold active tw-text-white" // Background color pink and text color white
                        : "lato_bold"
                    }`}
                    style={{
                      borderRight: selectedLink === item.id ? "none" : "", // Remove right border if active
                    }}
                  >
                    <div className="tw-flex tw-items-center gap-3">
                      <img src={item.image} alt="" />

                      {/* {isHovered === item.id || selectedLink === item.id ? (
                          <img src={item.image} alt="" />
                        ) : (
                          null
                        )} */}
                      <div className="lato_medium">{item.items}</div>
                    </div>
                  </MenuItem>
                </Menu>
              ))}
            </div>
          </div>
        </Sidebar>
      </div>
      <main className="min-vh-100 overflow-auto tw-w-full tw-bg-[#F9FAFB]">
        {children}
      </main>
    </div>
  );
};

export default MySidebar;
