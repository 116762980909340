import axios from "axios";
export const uploadFile = async (file) => {
  try {
    const formData = new FormData();
    formData.append("image", file);
    const token = localStorage.getItem("token");
    const response = await axios.post(
      global.BASEURL + "api/image/upload",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": token,
        },
      }
    );
    // console.log(response.data, "res");
    return response.data;
  } catch (error) {
    console.error("Error uploading file:", error.response.data);
     ;
  }
};

export const uploadPdf = async (file) => {
  try {
    const formData = new FormData();
    formData.append("image", file);
    const token = localStorage.getItem("token");
    const response = await axios.post(
      global.BASEURL + "api/pdf/upload",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": token,
        },
      }
    );
    // console.log(response.data, "res");
    return response.data;
  } catch (error) {
    console.error("Error uploading file:", error.response.data);
     ;
  }
};