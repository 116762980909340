/* eslint-disable no-unused-vars */
import { Fragment, useState, forwardRef } from "react";
import ReactPaginate from "react-paginate";
import DataTable from "react-data-table-component";
import { Spinner } from "reactstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import {
  ChevronDown,
  FileText,
  MoreVertical,
  Edit2,
  Trash,
} from "react-feather";
import {
  Row,
  Col,
  Card,
  Input,
  Label,
  CardTitle,
  CardHeader,
} from "reactstrap";

import { MdSearch } from "react-icons/md";
import { Link } from "react-router-dom";
import { Chevron } from "../../icons/icon";

const BootstrapCheckbox = forwardRef((props, ref) => (
  <div className="form-check">
    <Input type="checkbox" ref={ref} {...props} />
  </div>
));

const TrackTable = ({ data, columns, rowHeading, showRow,page,loading , setCount ,setDate ,settype,type,setStatus,status}) => {
  const [sortOption, setSortOption] = useState("");
  const [sortOption2, setSortOption2] = useState(""); // State to manage selected sorting option
  // State to manage selected sorting option

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
    // Implement sorting logic based on the selected option
    // You might want to update the data array accordingly
  };
  const handleSortChange2 = (event) => {
    setSortOption(event.target.value);
    // Implement sorting logic based on the selected option
    // You might want to update the data array accordingly
  };
  const [modal, setModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);

  const handleChange = (date) => {
    setSelectedDate(date);
    // You can format the date and store it in your desired format in the state
    const formattedDate = format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
    setDate(formattedDate)
  };
  // ** Function to handle Modal toggle
  const handleModal = () => setModal(!modal);
const handleFilterChange=(e)=>{
  setFilter(e.target.value)
  settype(e.target.value)

}
const handleStatus=(e)=>{
  setStatus(e.target.value)
}
  // ** Function to handle filter
  const handleFilter = (e) => {
    const value = e.target.value;
    let updatedData = [];
    setSearchValue(value);

    if (value.length) {
      updatedData = data.filter((item) => {
        const startsWith =
          item.full_name.toLowerCase().startsWith(value.toLowerCase()) ||
          item.post.toLowerCase().startsWith(value.toLowerCase()) ||
          item.email.toLowerCase().startsWith(value.toLowerCase()) ||
          item.age.toLowerCase().startsWith(value.toLowerCase()) ||
          item.salary.toLowerCase().startsWith(value.toLowerCase()) ||
          item.start_date.toLowerCase().startsWith(value.toLowerCase());

        const includes =
          item.full_name.toLowerCase().includes(value.toLowerCase()) ||
          item.post.toLowerCase().includes(value.toLowerCase()) ||
          item.email.toLowerCase().includes(value.toLowerCase()) ||
          item.age.toLowerCase().includes(value.toLowerCase()) ||
          item.salary.toLowerCase().includes(value.toLowerCase()) ||
          item.start_date.toLowerCase().includes(value.toLowerCase());

        if (startsWith) {
          return startsWith;
        } else if (!startsWith && includes) {
          return includes;
        } else return null;
      });
      setFilteredData(updatedData);
      setSearchValue(value);
    }
  };

  // ** Function to handle Pagination
  const handlePagination = (page) => {
    setCurrentPage(page.selected);
    setCount(page?.selected +1)
  };

  // ** Pagination Previous Component
  const Previous = () => {
    return (
      <Fragment>
        <span className="align-middle d-none d-md-inline-block">Prev</span>
      </Fragment>
    );
  };

  // ** Pagination Next Component
  const Next = () => {
    return (
      <Fragment>
        <span className="align-middle d-none d-md-inline-block">Next</span>
      </Fragment>
    );
  };
  // ** Custom Pagination
  const CustomPagination = () => (
    <ReactPaginate
      previousLabel={<Previous size={15} />}
      nextLabel={<Next size={15} />}
      forcePage={currentPage}
      onPageChange={(page) => handlePagination(page)}
      pageCount={
      page
        // searchValue.length
        //   ? Math.ceil(filteredData.length / 7)
        //   : Math.ceil(data.length / 7) || 1
      }
      breakLabel="..."
      pageRangeDisplayed={2}
      marginPagesDisplayed={2}
      activeClassName="active"
      pageClassName="page-item"
      breakClassName="page-item"
      nextLinkClassName="page-link"
      pageLinkClassName="page-link"
      breakLinkClassName="page-link"
      previousLinkClassName="page-link"
      nextClassName="page-item next-item"
      previousClassName="page-item prev-item"
      containerClassName="pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1 mt-4"
    />
  );

  return (
    <>
      <Fragment>
        <Card className="border border-white w-full">
          <div className="tw-flex tw-flex-wrap  tw-justify-between tw-items-md-end tw-items-sm-center tw-px-4 tw-py-4">
            <div className="d-flex flex-row justify-content-lg-start justify-content-center  flex-lg-nowrap flex-wrap align-items-center gap-4  ">
            <h3 className="fs-5 d-lg-block d-none">Track List</h3>
              {/* <select
                style={{
                  padding: ".7rem .9rem",
                  border: "1px solid rgba(0, 0, 0, 0.40)",
                  appearance: "none", // Hide the default arrow
                  backgroundImage: `url(${Chevron})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "calc(100% - 10px) center",
                  paddingRight: "3rem",
                  cursor: "pointer",
                  borderRadius: "28px",
                  minWidth: "200px",
                  position: "relative", // Set position to relative
                }}
                value={sortOption}
                onChange={handleSortChange}
              >
                <option value="">Sort By</option>
                <option value="name">Name</option>
                <option value="date">Date</option>
              </select> */}
              <select
                style={{
                  padding: ".7rem .9rem",
                  border: "1px solid rgba(0, 0, 0, 0.40)",
                  appearance: "none", // Hide the default arrow
                  backgroundImage: `url(${Chevron})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "calc(100% - 10px) center",
                  paddingRight: "3rem",
                  cursor: "pointer",
                  borderRadius: "28px",
                  minWidth: "200px",
                  position: "relative", // Set position to relative
                }}
                value={type}
                onChange={handleFilterChange}
              >
                {/* <option >Filter By</option> */}
                <option value="">All</option>
                <option value="partner">Partner</option>
                <option value="customer">Customer</option>
              </select>
              <select
                style={{
                  padding: ".7rem .9rem",
                  border: "1px solid rgba(0, 0, 0, 0.40)",
                  appearance: "none", // Hide the default arrow
                  backgroundImage: `url(${Chevron})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "calc(100% - 10px) center",
                  paddingRight: "3rem",
                  cursor: "pointer",
                  borderRadius: "28px",
                  minWidth: "200px",
                  position: "relative", // Set position to relative
                }}
                value={status}
                onChange={handleStatus}
              >
                <option value="">All</option>
                <option value="pending">Pending</option>
                <option value="accepted">Accepted</option>
                <option value="contacted">Contacted</option>
                <option value="completed">Completed</option>
                <option value="rejected">Rejected</option>

              </select>
              <div>
              <div className="trackDate">

      <DatePicker
      style={{borderRadius:"20px"}}
        selected={selectedDate}
        onChange={handleChange}
        showTimeSelect
        dateFormat="yyyy-MM-dd HH:mm:ss.SSSxxx"
        timeFormat="HH:mm"
        timeIntervals={15}
        timeCaption="Time"
        placeholderText="Select date and time"
      />
              </div>
    </div>
            </div>
            <div className="tw-pt-3">
             
            </div>
          </div>

          <div className="react-dataTable">
            {loading ? (
              <div
                className="tw-pt-16"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "100vh",
                }}
              >
                <Spinner size={"md"} />
              </div>
            ) : (
              <DataTable
                noHeader
                pagination
                selectableRows={false}
                columns={columns}
                paginationPerPage={10}
                className="react-dataTable"
                sortIcon={<ChevronDown size={10} />}
                paginationDefaultPage={currentPage + 1}
                paginationComponent={CustomPagination}
                data={searchValue.length ? filteredData : data}
              />
            )}
          </div>
        </Card>
      </Fragment>
    </>
  );
};

export default TrackTable;
