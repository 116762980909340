/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useEffect } from "react";
import logo from "../assets/png/visaLogo.png";
import auth from "../assets/png/auth.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Col,
  FormFeedback,
  Spinner,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ArrowLeft } from "react-feather";
import { verificationOtp } from "../api/forgot";
const VerifyCode = () => {
  // states----
  const [loading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  // form validation---------
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: "",
    },
    validationSchema: Yup.object({
      code: Yup.string().required("Code is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      verificationOtp(values.code)
        .then((res) => {
          if (res?.success === true) {
            setIsLoading(false);
            toast.success("Code verify successfully");
            navigate("/change-password");
          }
        })
        .catch((err) => {
setIsLoading(false)
        });
    },
  });

  return (
    <div className="container min-[1440px]:tw-px-10 ">
      <div className="tw-pb-5">
        {/* <div>
          <ArrowLeft/>
        </div> */}
        <div className="tw-pt-10">
          <img src={logo} width="200px" alt="" />
        </div>
        <div className="register_main d-sm-flex flex-lg-row flex-sm-column  justify-content-between align-items-center tw-px-0 px-0">
          <div className="">
            <img src={auth} alt="authPic" className="img-fluid" />
          </div>
          <div>
            <div className="md:tw-w-96 tw-text-center mb-4">
              <div className="lato_regular tw-text-2xl tw-pt-10 ">
                Email Verification
              </div>
              <div className="text-muted ">
                Check your email, get a code we send you
              </div>
            </div>
            <Form onSubmit={validation.handleSubmit} className="mt-3">
              <FormGroup row>
                <Col md={10} sm={12}>
                  <Input
                    type="text"
                    className="form-control"
                    name="code"
                    id="code"
                    placeholder="Enter code"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.code || ""}
                    invalid={
                      validation.touched.code && validation.errors.code
                        ? true
                        : false
                    }
                  />
                  {validation.touched.code && validation.errors.code ? (
                    <FormFeedback type="invalid">
                      {validation.errors.code}
                    </FormFeedback>
                  ) : null}
                </Col>
              </FormGroup>

              <div className="tw-flex tw-align-middle tw-justify-center">
                <Button
                  disabled={loading}
                  type="submit"
                  className="auth_btn tw-text-white"
                  color="primary"
                >
                  {loading ? (
                    <>
                      <Spinner size={"sm"} />
                    </>
                  ) : (
                    "Verify Code"
                  )}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyCode;
