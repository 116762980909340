/* eslint-disable no-unused-vars */
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import { Input } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Label, Spinner } from "reactstrap";
import * as yup from "yup";
import { getCategory_ } from "../../../api/categories";
import { getServices } from "../../../api/services";
import { uploadFile } from "../../../api/upload";
import { createVisa } from "../../../api/visa";
import { delIcon, grid, upload } from "../../../icons/icon";
import BlueButton from "../../../resueComponent/blueButton";
import countries from "./countryData";
const theme = createTheme();
const AntTabs = styled((props) => <Tabs {...props} />)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#1890ff",
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);
const TabPanel = ({ value, index, children }) => (
  <div hidden={value !== index}>
    {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
  </div>
);
const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 99, // Adjust the zIndex as needed
  }),
};

const AddCountry = () => {
  // states----------
  const [value, setValue] = React.useState(0);
  const [note, setNote] = useState("");
  const [name, setname] = useState("");
  const [service, setService] = useState("");
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [visaCat, setVisaCat] = useState([]);
  const [serviceCat, setServiceCat] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const [chkinputValue, setChkInputValue] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [inputFields, setInputFields] = useState([]);
  const [loadingImage, setloadingImage] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState("");
  const [destination, setDestination] = useState("");
  const [country, setCountry] = useState("");
  const [residence, setResidence] = useState("");
  const [chkinputFields, setChkInputFields] = useState([]);
  const [checkdynamic, setCheckDynamic] = useState([]);
  const [options, setOptions] = useState([]);
  const [dynamic, setDynamic] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedVisa, setSelectedVisa] = useState("");
  const [selectedOption2, setSelectedOption2] = useState([]);
  const [index, setIndex] = useState("");
  const [lastId, setLastId] = useState("");
  const [options2, setOptions2] = useState([]);
  const [s_lastId, sets_lastId] = useState("");
  const [array_, setArray_] = useState("");
  // const [selectedservice, setSelectedService] = useState("");

  const [stepsValue, setStepsValue] = useState("");
  const [checkListValue, setcheckListValue] = useState("");
  const [selectedService, setSelectedService] = useState();
  const navigate = useNavigate();
  const [count, setCount] = useState(1);

  // functions------------
  const abc = () => {
    const randomKey = Math.random().toString(36).substr(2, 5); // Generate a random key for each input field
    const newInputField = {
      key: randomKey,
      value: "",
    };
    setInputFields([...inputFields, newInputField]);
    setInputValue2("");
  };

  const handleInputChange = (index, value) => {
    setStepsValue(value);
    const updatedInputFields = [...inputFields];
    updatedInputFields[index].value = value;
    setInputFields(updatedInputFields);
  };
  const handleInputChklist = (index, value) => {
    setcheckListValue(value);
    const updatedInputFields = [...chkinputFields];
    updatedInputFields[index].value = value;
    setChkInputFields(updatedInputFields);
  };
  const checklist = () => {
    const randomKey = Math.random().toString(36).substr(2, 5); // Generate a random key for each input field
    const newInputField = {
      key: randomKey,
      value: "",
    };
    setChkInputFields([...chkinputFields, newInputField]);
    setChkInputValue("");
  };

  const handleNextButtonClick = () => {
    console.log(dynamic, "dd..");
    // Validate form fields
    formik.validateForm().then((errors) => {
      if (
        Object.keys(errors)?.length > 0 ||
        previewImage === null ||
        selectedVisa?.length <= 0 ||
        selectedService?.length <= 0
      ) {
        // If form fields are not valid, display error message
        toast.error("Please fill in all required fields");
      } else if (!selectedOption || !selectedOption2) {
        // Check if at least one option is selected from Visa category and service category
        toast.warn(
          "At least select one from Visa category and service category"
        );
      } else if (residence === "") {
        // Check if residence is empty
        toast.warn("Please add the residence");
      } else if (destination === "") {
        // Check if destination is empty
        toast.warn("Please add the destination");
      } else if (country === "") {
        // Check if country is empty
        toast.warn("Please add the country");
      } else {
        // If all conditions are met, proceed to the next step
        setValue(1);
      }
    });
  };
  const handleNextButtonClick2 = () => {
    const data = inputFields?.filter((item) => item?.value?.trim());

    if (stepsValue && note && data?.length > 0) {
      setValue(2);
    } else {
      toast.error("please fill data first");
    }
  };
  useEffect(() => {
    // Retrieve selected options from local storage when component mounts
    const storedSelectedOption = localStorage.getItem("selectedOption");
    if (storedSelectedOption) {
      setSelectedVisa(JSON.parse(storedSelectedOption));
    }
    const storedSelectedOption2 = localStorage.getItem("selectedOption2");
    if (storedSelectedOption2) {
      setSelectedService(JSON.parse(storedSelectedOption2));
    }
  }, []);
  const handleClearInput = () => {
    setInputValue("");
  };
  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    setloadingImage(true);
    const file = event.target.files[0];
    if (file) {
      try {
        // Upload file and wait for the result
        const result = await uploadFile(file);
        setloadingImage(false);
        // Handle the result as needed
        setSelectedFile(result?.image);
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewImage(reader.result);
        };
        reader.readAsDataURL(file);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleAutocompleteChange = (event, newValue) => {
    // Handle the change and set the selected label
    setSelectedLabel(newValue?.label || "");
  };

  const handleOptionChange = (selectedOption) => {
    setSelectedVisa(selectedOption);
    localStorage.setItem("selectedOption", JSON.stringify(selectedOption));
    const array = selectedOption?.map((val) => {
      return val?.value;
    });

    console.log(array, "array of ids");
    setSelectedOption(array);
  };
  console.log(name, "name");

  console.log(selectedOption, "options");
  const handleOptionChange2 = (selectedOption) => {
    const array2 = selectedOption?.map((val) => {
      return val?.value;
    });
    setSelectedOption2(array2);
    setSelectedService(selectedOption);
    localStorage.setItem("selectedOption2", JSON.stringify(selectedOption));

    setService(selectedOption?.label);
  };
  // form validation---------
  const validationSchema = yup.object({
    stayDuration: yup.string().required("Stay Duration is required"),
    visaValidity: yup.string().required("Visa Validity is required"),
    visaEntry: yup.string().required("Visa Entry is required"),
    processingTime: yup.string().required("Processing Time is required"),
    standardVisaCost: yup.string().required("Standard Visa Cost is required"),
    counsellorCost: yup.string().required("Counsellor Cost is required"),
    offersAndBenefits: yup
      .string()
      .required("Offers And Benefits are required"),
    entryRestrictions: yup.string().required("Entry Restrictions are required"),
    entryHealthRequirements: yup
      .string()
      .required("Entry & Health Requirements are required"),
    inCountryExperience: yup
      .string()
      .required("In-country experience is required"),
    transitRestrictions: yup
      .string()
      .required("Transit Restrictions are required"),
    reEntryRequirements: yup
      .string()
      .required("Re-Entry Requirements are required"),
  });
  const formik = useFormik({
    initialValues: {
      stayDuration: "",
      visaValidity: "",
      visaEntry: "",
      processingTime: "",
      standardVisaCost: "",
      counsellorCost: "",
      offersAndBenefits: "",
      entryRestrictions: "",
      entryHealthRequirements: "",
      inCountryExperience: "",
      transitRestrictions: "",
      reEntryRequirements: "",
    },
    validationSchema: validationSchema,

    onSubmit: async (values, { resetForm }) => {
      chkinputFields?.map((val) => {
        setArray_(val.value);
      });
      const CheckList = chkinputFields?.filter((item) => item?.value?.trim());

      formik.validateForm().then((errors) => {
        if (Object.keys(errors)?.length > 0) {
          toast.error("Please fill in all required fields");
        } else if (CheckList?.length === 0 || chkinputFields?.length === 0) {
          toast.error("form Data must be filled ");
        } else if (
          (chkinputFields?.length > 0 && array_ !== "") ||
          selectedOption2 ||
          selectedOption
        ) {
          const data = {
            image: selectedFile,
            values: values,
            country: country,
            cat_id: selectedOption,
            service: selectedOption2,
            steps: dynamic,
            residence: residence,
            destination: destination,
            note: note,
            checklist: checkdynamic,
          };

          console.log(data, "dd");
          // return
          setIsLoading(true);
          createVisa(data)
            .then((res) => {
              if (res?.success === true) {
                setIsLoading(false);
                toast.success("Added successfully..!");
                localStorage.removeItem("selectedOption2");
                localStorage.removeItem("selectedOption");
                resetForm();
                setCheckDynamic([]);
                setSelectedFile(null);
                setChkInputFields([]);
                navigate("/explore/listCat");
              } else {
                setIsLoading(false);

                toast.error("Internal server Error");
              }
            })
            .catch((err) => {
              setIsLoading(false);
            });
          // If form fields are not valid, display error message
        } else {
          toast.error("Still incomplete form");
        }
      });
    },
  });

  const handleRemoveField = (field, index) => {
    if (!field.value) {
      setStepsValue("");
    }
    const updatedFields = [...inputFields];
    updatedFields.splice(index, 1);
    setInputFields(updatedFields);
  };
  const handleRemoveChkField = (index) => {
    setIndex(index);
    const updatedFields = [...chkinputFields];
    updatedFields.splice(index, 1);
    setChkInputFields(updatedFields);
  };
  // useEffect-------
  useEffect(() => {
    getCategory_(lastId)
      .then((res) => {
        setVisaCat(res?.categories);
        // const data = res?.categories
        setLastId(res?.categories[res?.categories?.length - 1]?._id);
        const data = res?.categories?.map((d) => ({
          value: d._id,
          label: d.name,
        }));
        setOptions(data);
        // setCount(res?.count.totalPage)
      })
      .catch((err) => {});
  }, []);
  useEffect(() => {
    getServices(s_lastId)
      .then((res) => {
        setServiceCat(res?.services);
        sets_lastId(res?.services[res?.services?.length - 1]?._id);
        const sData = res?.services?.map((d) => ({
          value: d._id,
          label: d.name,
        }));
        setOptions2(sData);
      })
      .catch((err) => {});
  }, []);
  useEffect(() => {
    const updatedDynamic = [];
    for (let i = 0; i < inputFields?.length; i++) {
      const element = inputFields[i].value;
      if (inputFields[i].value !== "") {
        updatedDynamic.push(element);
        setDynamic(updatedDynamic);
      }
    }
    if (inputFields?.length == 0) {
      setDynamic([]);
    }
  }, [inputFields]);
  useEffect(() => {
    const updatedDynamic = [];
    for (let i = 0; i < chkinputFields?.length; i++) {
      const element = chkinputFields[i].value;
      if (chkinputFields[i].key !== "") {
        updatedDynamic.push(element);
        setCheckDynamic(updatedDynamic);
      }
    }
    if (chkinputFields?.length == 0) {
      setCheckDynamic([]);
    }
  }, [JSON.stringify(chkinputFields)]);
  useEffect(() => {
    localStorage.removeItem("selectedOption");
    localStorage.removeItem("selectedOption2");
  }, []);
  const handleMenuScrollToBottom = () => {
    getCategory_(lastId)
      .then((res) => {
        setVisaCat(res?.categories);
        setLastId(res?.categories[res?.categories?.length - 1]?._id);
        const data = res?.categories?.map((d) => ({
          value: d._id,
          label: d.name,
        }));
        setOptions((prev) => [...prev, ...data]);
      })
      .catch((err) => {});
  };
  const handleMenuScrollToBottom2 = () => {
    getServices(s_lastId)
      .then((res) => {
        setServiceCat(res?.services);
        sets_lastId(res?.services[res?.services?.length - 1]?._id);
        const sData = res?.services?.map((d) => ({
          value: d._id,
          label: d.name,
        }));
        setOptions2(sData);
      })
      .catch((err) => {});
  };
  return (
    <div className="bg ">
      <div className="tw-pb-5 container tw-pt-16 tw-flex tw-flex-wrap tw-align-start tw-gap-6  ">
        <ToastContainer />

        <ThemeProvider theme={theme}>
          <Box width={{ md: "80%", xs: "100%" }}>
            <Box sx={{ bgcolor: "#fff", minHeight: "100vh" }}>
              <AntTabs
                value={value}
                onChange={handleChange}
                aria-label="ant example"
              >
                <AntTab className="lato_regular" label="Overview" />
                <AntTab label="How To Apply" />
                <AntTab label="Document Checklist" />
              </AntTabs>
              <Form onSubmit={formik.handleSubmit}>
                <TabPanel value={value} index={0}>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div
                      style={{ border: "1px solid #CCCCCC " }}
                      className="upload tw-rounded-xl tw-bg-white tw-p-3 tw-h-50 tw-w-64 tw-cursor-pointer mt-2 "
                      onClick={handleDivClick}
                    >
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />
                      <div className="upload_border tw-h-44 d-flex flex-column align-items-center justify-content-center">
                        {previewImage ? (
                          <img
                            src={previewImage}
                            alt="upload preview"
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                              objectFit: "cover",
                            }}
                          />
                        ) : loadingImage ? (
                          <div className="d-flex justify-content-center align-items-center" style={{position:"absolute",bottom:"45%"}}>
                            <Spinner size='sm' />
                          </div>
                        ) : (
                          <>
                            <img src={upload} alt="upload" />
                            <div className="text-center ">
                              <p
                                className="m-0 tw-text-xs"
                                style={{ color: "#333", fontSize: "9px" }}
                              >
                                Click to select or ‘Drag and drop’ your Image
                                here
                              </p>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div class="col-md-6 col-12 mt-4 ">
                      <Autocomplete
                        id="country-select-demo"
                        sx={{ width: "100%" }}
                        options={countries}
                        value={{ label: country }}
                        autoHighlight
                        // onChange={handleAutocompleteChange}
                        onChange={(event, value) => {
                          setCountry(value ? value?.label || "" : "");
                        }}
                        getOptionLabel={(option) => {
                          return option.label;
                        }}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              alt=""
                            />
                            {option.label} ({option.code}) +{option.phone}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              <>
                                Country{" "}
                                <span className="tw-text-red-600">*</span>
                              </>
                            }
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                            InputProps={{
                              ...params.InputProps,
                              classes: {
                                focused: "custom-focused", //   your custom-focused class here
                              },
                            }}
                            InputLabelProps={{
                              classes: {
                                root: "custom-label-root", // Add your custom label root class here
                              },
                            }}
                          />
                        )}
                      />
                      <Label className="mt-3 label_font">Visa Category</Label>
                      <Select
                        options={options}
                        isMulti
                        onMenuScrollToBottom={handleMenuScrollToBottom}
                        value={selectedVisa}
                        styles={customStyles}
                        onChange={handleOptionChange}
                      />
                      <Label className="mt-3 label_font">
                        Service Category
                      </Label>
                      <Select
                        options={options2}
                        onMenuScrollToBottom={handleMenuScrollToBottom2}
                        isMulti
                        styles={customStyles}
                        value={selectedService}
                        onChange={handleOptionChange2}
                      />
                      <Box
                        component="form"
                        sx={{
                          "& > :not(style)": { mt: 2, width: "100%" },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          id="stayDuration"
                          label={
                            <>
                              Stay Duration{" "}
                              <span className="tw-text-red-600">*</span>
                            </>
                          }
                          variant="outlined"
                          className="myCustomTextField"
                          value={formik.values.stayDuration}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.stayDuration &&
                            Boolean(formik.errors.stayDuration)
                          }
                          helperText={
                            formik.touched.stayDuration &&
                            formik.errors.stayDuration
                          }
                        />

                        {/* Visa Validity */}
                        <TextField
                          id="visaValidity"
                          label={
                            <>
                              Visa Validity{" "}
                              <span className="tw-text-red-600"> *</span>
                            </>
                          }
                          variant="outlined"
                          className="myCustomTextField"
                          value={formik.values.visaValidity}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.visaValidity &&
                            Boolean(formik.errors.visaValidity)
                          }
                          helperText={
                            formik.touched.visaValidity &&
                            formik.errors.visaValidity
                          }
                        />

                        {/* Visa Entry */}
                        <TextField
                          id="visaEntry"
                          label={
                            <>
                              Visa Entry{" "}
                              <span className="tw-text-red-600"> *</span>
                            </>
                          }
                          variant="outlined"
                          className="myCustomTextField"
                          value={formik.values.visaEntry}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.visaEntry &&
                            Boolean(formik.errors.visaEntry)
                          }
                          helperText={
                            formik.touched.visaEntry && formik.errors.visaEntry
                          }
                        />

                        {/* Processing Time */}
                        <TextField
                          id="processingTime"
                          label={
                            <>
                              Processing Time{" "}
                              <span className="tw-text-red-600"> *</span>
                            </>
                          }
                          variant="outlined"
                          className="myCustomTextField"
                          value={formik.values.processingTime}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.processingTime &&
                            Boolean(formik.errors.processingTime)
                          }
                          helperText={
                            formik.touched.processingTime &&
                            formik.errors.processingTime
                          }
                        />

                        {/* Standard Visa Cost */}
                        <TextField
                          id="standardVisaCost"
                          label={
                            <>
                              Standard Visa Cost{" "}
                              <span className="tw-text-red-600"> *</span>
                            </>
                          }
                          variant="outlined"
                          className="myCustomTextField"
                          value={formik.values.standardVisaCost}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.standardVisaCost &&
                            Boolean(formik.errors.standardVisaCost)
                          }
                          helperText={
                            formik.touched.standardVisaCost &&
                            formik.errors.standardVisaCost
                          }
                        />

                        {/* Counsellor Cost */}
                        <TextField
                          id="counsellorCost"
                          label={
                            <>
                              Counsellor Cost{" "}
                              <span className="tw-text-red-600"> *</span>
                            </>
                          }
                          variant="outlined"
                          className="myCustomTextField"
                          value={formik.values.counsellorCost}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.counsellorCost &&
                            Boolean(formik.errors.counsellorCost)
                          }
                          helperText={
                            formik.touched.counsellorCost &&
                            formik.errors.counsellorCost
                          }
                        />

                        {/* Offers And Benefits */}
                        <TextField
                          id="offersAndBenefits"
                          label={
                            <>
                              Offers And Benefits{" "}
                              <span className="tw-text-red-600"> *</span>
                            </>
                          }
                          variant="outlined"
                          className="myCustomTextField"
                          value={formik.values.offersAndBenefits}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.offersAndBenefits &&
                            Boolean(formik.errors.offersAndBenefits)
                          }
                          helperText={
                            formik.touched.offersAndBenefits &&
                            formik.errors.offersAndBenefits
                          }
                        />

                        {/* Entry Restrictions */}
                        <TextField
                          id="entryRestrictions"
                          label={
                            <>
                              Entry Restrictions{" "}
                              <span className="tw-text-red-600"> *</span>
                            </>
                          }
                          variant="outlined"
                          className="myCustomTextField"
                          value={formik.values.entryRestrictions}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.entryRestrictions &&
                            Boolean(formik.errors.entryRestrictions)
                          }
                          helperText={
                            formik.touched.entryRestrictions &&
                            formik.errors.entryRestrictions
                          }
                        />

                        {/* Entry & Health Requirements */}
                        <TextField
                          id="entryHealthRequirements"
                          label={
                            <>
                              Entry & Health Requirements{" "}
                              <span className="tw-text-red-600"> *</span>
                            </>
                          }
                          variant="outlined"
                          className="myCustomTextField"
                          value={formik.values.entryHealthRequirements}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.entryHealthRequirements &&
                            Boolean(formik.errors.entryHealthRequirements)
                          }
                          helperText={
                            formik.touched.entryHealthRequirements &&
                            formik.errors.entryHealthRequirements
                          }
                        />

                        {/* In-country experience */}
                        <TextField
                          id="inCountryExperience"
                          label={
                            <>
                              In-country experience{" "}
                              <span className="tw-text-red-600"> *</span>
                            </>
                          }
                          variant="outlined"
                          className="myCustomTextField"
                          value={formik.values.inCountryExperience}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.inCountryExperience &&
                            Boolean(formik.errors.inCountryExperience)
                          }
                          helperText={
                            formik.touched.inCountryExperience &&
                            formik.errors.inCountryExperience
                          }
                        />

                        {/* Transit Restrictions */}
                        <TextField
                          id="transitRestrictions"
                          label={
                            <>
                              Transit Restrictions{" "}
                              <span className="tw-text-red-600"> *</span>
                            </>
                          }
                          variant="outlined"
                          className="myCustomTextField"
                          value={formik.values.transitRestrictions}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.transitRestrictions &&
                            Boolean(formik.errors.transitRestrictions)
                          }
                          helperText={
                            formik.touched.transitRestrictions &&
                            formik.errors.transitRestrictions
                          }
                        />

                        {/* Re-Entry Requirements */}
                        <TextField
                          id="reEntryRequirements"
                          label={
                            <>
                              Re-Entry Requirements{" "}
                              <span className="tw-text-red-600"> *</span>
                            </>
                          }
                          variant="outlined"
                          className="myCustomTextField"
                          value={formik.values.reEntryRequirements}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.reEntryRequirements &&
                            Boolean(formik.errors.reEntryRequirements)
                          }
                          helperText={
                            formik.touched.reEntryRequirements &&
                            formik.errors.reEntryRequirements
                          }
                        />
                        <Autocomplete
                          id="country-select-demo"
                          sx={{ width: "100%" }}
                          options={countries}
                          value={{ label: destination }}
                          autoHighlight
                          onChange={(event, value) => {
                            setDestination(value ? value?.label || "" : "");
                          }}
                          getOptionLabel={(option) => {
                            return option.label;
                          }}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              <img
                                loading="lazy"
                                width="20"
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                alt=""
                              />
                              {option.label} ({option.code}) +{option.phone}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                <>
                                  Destination{" "}
                                  <span className="tw-text-red-600">*</span>
                                </>
                              }
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password", // disable autocomplete and autofill
                              }}
                              InputProps={{
                                ...params.InputProps,
                                classes: {
                                  focused: "custom-focused", // your custom-focused class here
                                },
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: "custom-label-root", // Add your custom label root class here
                                },
                              }}
                            />
                          )}
                        />

                        <Autocomplete
                          id="country-select-demo"
                          sx={{ width: "100%" }}
                          options={countries}
                          value={{ label: residence }}
                          autoHighlight
                          onChange={(event, value) =>
                            setResidence(value ? value.label || "" : "")
                          }
                          getOptionLabel={(option) => {
                            return option.label;
                          }}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              <img
                                loading="lazy"
                                width="20"
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                alt=""
                              />
                              {option.label} ({option.code}) +{option.phone}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={
                                <>
                                  Residence{" "}
                                  <span className="tw-text-red-600">*</span>
                                </>
                              }
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password", // disable autocomplete and autofill
                              }}
                              InputProps={{
                                ...params.InputProps,
                                classes: {
                                  focused: "custom-focused", //   your custom-focused class here
                                },
                              }}
                              InputLabelProps={{
                                classes: {
                                  root: "custom-label-root", // Add your custom label root class here
                                },
                              }}
                            />
                          )}
                        />
                        <div className="tw-flex tw-justify-center tw-pt-5">
                          <button
                            onClick={handleNextButtonClick}
                            type="button"
                            className="tw-bg-purple-900 tw-px-6 tw-py-3 tw-text-white tw-rounded-md lato_regular"
                          >
                            Next
                          </button>
                        </div>
                      </Box>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <BlueButton
                    bg="#446DC9"
                    color="white"
                    padding=".4rem 1rem"
                    content="Add Step"
                    bR="6px"
                    onClick={abc}
                  />

                  <div className="tw-pt-8  parentSelector ">
                    {inputFields?.map((field, index) => (
                      <div
                        key={field.key}
                        className="tw-relative tw-flex tw-align-middle tw-gap-1  "
                      >
                        <Input
                          className="tw-py-3 mb-4 customBorder"
                          type="text"
                          placeholder="Type your process..."
                          value={field.value}
                          onChange={(e) =>
                            handleInputChange(index, e.target.value)
                          }
                          prefix={
                            <img
                              src={grid}
                              alt="LeftImage"
                              style={{ marginRight: 8, height: 16, width: 16 }}
                            />
                          } // Left image
                        />
                        <div
                          className="tw-absolute  tw-pl-14  tw-right-1 tw-pb-6  tw-cursor-pointer"
                          onClick={() => handleRemoveField(field, index)}
                          style={{ top: "50%", transform: "translateY(-50%)" }}
                        >
                          <img
                            src={delIcon}
                            alt="DeleteIcon"
                            style={{ height: 16, width: 16 }}
                          />
                        </div>
                      </div>
                    ))}

                    <div className="tw-mt-8">
                      <TextField
                        className="myCustomTextField w-md-50  w-100"
                        id="note"
                        name="note"
                        onChange={(e) => setNote(e.target.value)}
                        value={note}
                        label={
                          <>
                            Note <span className="tw-text-red-600">*</span>
                          </>
                        }
                        variant="outlined"
                      />
                    </div>
                    <div className="tw-flex tw-justify-center tw-pt-5">
                      <button
                        type="button"
                        onClick={handleNextButtonClick2}
                        className="tw-bg-purple-900 tw-px-6 tw-py-3 tw-text-white tw-rounded-md lato_regular"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </TabPanel>

                <TabPanel value={value} index={2}>
                  <div className="tw-pb-7">
                    <BlueButton
                      bg="#446DC9"
                      color="white"
                      padding=".4rem 1rem"
                      content="Add Checklist"
                      bR="6px"
                      onClick={checklist}
                    />
                  </div>
                  <div className="tw-pt-8 parentSelector">
                    {chkinputFields?.map((field, index) => (
                      <div
                        key={field.key}
                        className="tw-relative tw-flex tw-gap-2"
                      >
                        <Input
                          className="tw-py-3 mb-4 customBorder tw-flex-1"
                          type="text"
                          placeholder="Type your process..."
                          value={field.value}
                          onChange={(e) =>
                            handleInputChklist(index, e.target.value)
                          }
                          prefix={
                            <img
                              src={grid}
                              alt="LeftImage"
                              style={{ marginRight: 8, height: 16, width: 16 }}
                            />
                          } // Left image
                        />
                        <div
                          className="tw-relative tw-ml-2 tw-cursor-pointer tw-pt-4"
                          onClick={() => handleRemoveChkField(index)}
                        >
                          <img
                            src={delIcon}
                            alt="DeleteIcon"
                            style={{ height: 16, width: 16 }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="floating-label-container parent_design tw-mt-6 ">
                    <span className="floating-label child_design">
                      {name ? name : ""}
                      <span style={{ color: "red" }}>*</span>
                    </span>
                    <div>
                      <div
                        className="custom-input"
                        style={{ minHeight: "200px" }}
                      >
                        {checkdynamic?.map((val, i) => {
                          return (
                            <ul key={i}>
                              <li className="lato_bold tw-ps-3">
                                <span className="bullet tw-pe-2 ">&#8226;</span>
                                {val}
                              </li>
                            </ul>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="tw-flex tw-justify-center tw-pt-5">
                    <button
                      disabled={loading}
                      type="submit"
                      className="bgprimary tw-rounded-md tw-px-10 tw-mt-3 tw-py-2 tw-text-white"
                      color="primary"
                    >
                      {loading ? (
                        <>
                          <Spinner size={"sm"} />
                        </>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </TabPanel>
              </Form>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default AddCountry;
