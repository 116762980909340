import axios from "axios";
export const registerPartner = async (data) => {
  console.log(data, "data inside api");
  console.log(data?.values, "data inside api");
  try {
    const token = localStorage.getItem("token");
    const res = await axios.post(
       global.BASEURL+"api/users/register/partner",     
      {
        name : data?.name,
        email: data?.email,
        phone : data?.phone,
        nationality : data?.nationality,
        commissions : data?.commissions,
        operating_country:data?.operating_country,
        dob : data?.dob,
        address1 : data?.address1,
        address2 : data?.address2,
        profilePicture : data?.profilePicture,
        residence : data?.residence,
        zipcode : data?.zipcode,
        doc_url : data?.doc_url,
        idNumber : data?.idNumber,
        password : data?.password
      },
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      }
    );
    console.log(res.data, "res");
    return res.data;
  } catch (error) {
    console.log(error, "error");
     ; // Rethrow the error to handle it in the component
  }
};
// get req
export const getParteners = async (param) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found in local storage");
        return;
      }
      const res = await axios.get(
         global.BASEURL+`api/users/partner/${param}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
          },
        }
      );
  
      console.log("Response status:", res.status);
      console.log("Response data:", res.data);
  
      return res.data;
    } catch (error) {
      console.error("Error fetching category:", error);
       ;
    }
  };
  export const changeUserStatus = async (status,id) => {
    try {
      const token = localStorage.getItem("token");
      const res = await axios.put(
         global.BASEURL+`api/users/${status}/${id}`,
        {}, 
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
          },
        }
      );
      console.log(res.data, "res");
      return res.data;
    } catch (error) {
      console.log(error, "error");
       ; // Rethrow the error to handle it in the component
    }
  };
  // upadate password-----------
  export const updatePassword = async (data,id) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found in local storage");
        return;
      }
      const res = await axios.put(
         global.BASEURL+`api/users/admin/user/update-password/${id}`,
         {
          password:data
         },
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
          },
        }
      );
  
      console.log("Response status:", res.status);
      console.log("Response data:", res.data);
  
      return res.data;
    } catch (error) {
      console.error("Error fetching category:", error);
       ;
    }
  };