/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
// import { Card } from "react-bootstrap";
import { home, plane, student, tourist } from "../../../icons/icon";
import { Link, useNavigate } from "react-router-dom";
import { getCategory, getCategory_ } from "../../../api/categories";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { getVisa, getfilteredVisa } from "../../../api/visa";
import { Spinner } from "reactstrap";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, FreeMode, Pagination } from "swiper";
const ListCat = () => {
  // states-----------
  const [data, setData] = useState([]);
  const [lastId, setLastId] = useState("");
  const [scrolling, setScrolling] = useState(false)
  const [visadata, setvisaData] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const navigate = useNavigate({});
  const [active, setActive] = useState('')
  // functions--------
  const handleNavigate = () => {
    navigate("/explore/addCat");
  };
  const filteration = (val) => {
    setActive(val)
    getfilteredVisa(val)
      .then((res) => {
        setvisaData(res?.visaApplication);
      })
      .catch((error) => {
        console.error("Error fetching category:", error);
      });
  };
  const detailCountry = (i) => {
    navigate(`/detailCountry/${i}`);
  };
  // useEffect--
  // useEffect(() => {
  //   setIsLoading(true);
  //   getCategory(lastId)
  //     .then((res) => {
  //       console.log(res?.categories, "im");
  //       setData(res?.categories);
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching category:", error);
  //       setIsLoading(false);
  //     });
  // }, []);
  useEffect(() => {
    setTimeout(() => {
      setStatus(!status);
    }, 1500);
  }, []);
  // useEffect--
  useEffect(() => {
    setScrolling(false)

    getCategory_(lastId)
      .then((res) => {
        setData(res?.categories);
        setLastId(res?.categories[res?.categories?.length - 1]?._id);
      })
      .catch((error) => {
        console.error("Error fetching category:", error);
      });
  }, []);
  useEffect(() => {
    getVisa()
      .then((res) => {
        setvisaData(res?.visaApplication);
      })
      .catch((error) => {
        console.error("Error fetching category:", error);
      });
  }, [status]);
  const handlePagination = () => {
    setScrolling(true)
    if (data?.length > 0) {
      getCategory_(lastId)
        .then((res) => {
          setScrolling(false)
          setLastId(res?.categories[res?.categories?.length - 1]?._id);
          const data1 = res?.categories;
          const arr = [...data, ...data1];
          setData(arr);
          // setData((prev)=>[...prev,...data])
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching category:", error);
          setIsLoading(false);
        });
    }
  };
  return (
    <div className="tw-bg-white tw-pt-5 ">
      <div className="d-flex flex-wrap  justify-content-md-end justify-content-sm-center  flex-row gap-1 align-items-center ">
        <button
          onClick={handleNavigate}
          className=" bgprimary tw-rounded-md tw-px-4 tw-mb-3 tw-mr-4  tw-py-2 tw-text-white"
        >
          {" "}
          Add Visa Category
        </button>
        <Link
          to="/add/services"
          className=" bgprimary tw-rounded-md tw-px-4 tw-mb-3 tw-mr-4  tw-py-2 tw-text-white"
        >
          {" "}
          Add Service Cat
        </Link>
        <Link
          to="/visa/details"
          className=" bgprimary tw-rounded-md tw-px-4 tw-mb-3 tw-mr-4  tw-py-2 tw-text-white"
        >
          {" "}
          Add Visa Details
        </Link>
      </div>
      <div className="bg">
        {loading ? (
          <div
            className="tw-pt-16"
            style={{
              display: "flex",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <Spinner size={"md"} />
          </div>
        ) : (
          <div className="tw-pb-5 container py-4">
            <Swiper
              spaceBetween={10}
              freeMode={true}
              modules={[Navigation, FreeMode, Pagination]}
              className="m-auto overflow-hidden flex flex-nowrap"
              slidesPerView={'auto'}
              onReachEnd={handlePagination}
            >
              {data?.length > 0 && data?.map((val, i) => (
                <SwiperSlide key={i} style={{ maxWidth: '10rem' }}>
                  <div className='tw-flex tw-align-start'>

                    <Card
                      style={{
                        width: '140px', // Set your desired width
                        height: '100%',
                      }}
                      onClick={() => filteration(val?._id)}

                      className={active === val?._id ? "tw-h-32 tw-cursor-pointer tw-w-36 border-0 tw-rounded-xl d-flex tw-pb-6 justify-content-center align-items-center flex-column active_" : "tw-h-32 tw-cursor-pointer tw-w-36 border-0 tw-rounded-xl d-flex tw-pb-6 justify-content-center align-items-center flex-column"}
                    >
                      {console.log(active, "eq")}
                      <div className="d-flex gap-3 tw-pt-2 justify-content-between align-items-center flex-column ">
                        <img
                          src={`${global.Upload}${val.image}`}
                          style={{ width: '80px', height: '80px' }}
                          alt={val?.type}
                        />
                        <p className="m-0">{val?.name}</p>
                      </div>
                    </Card>
                  </div>
                </SwiperSlide>
              ))}
              {/* {scrolling && (
          <SwiperSlide>
            <Spinner animation="border" />
          </SwiperSlide>
        )} */}
            </Swiper>


            {/* <div className="tw-pt-16 tw-flex tw-flex-wrap tw-align-start tw-gap-5">
              {data?.map((val, i) => (
                <div key={i}>
                  <div className="tw-flex tw-align-start">
                    <Card
                      style={{
                        width: "140px", // Set your desired width
                        height: "100%",
                      }}
                      onClick={() => filteration(val?._id)}
                      className="tw-h-32 tw-cursor-pointer tw-w-36 border-0 tw-rounded-xl d-flex tw-pb-6 justify-content-center align-items-center flex-column"
                    >
                      <div className="d-flex gap-3 tw-pt-2 justify-content-between align-items-center flex-column">
                        <img
                          src={`${global.Upload}${val.image}`}
                          style={{ width: "80px", height: "80px" }}
                          alt={val?.type}
                        />

                        <p className="m-0">{val?.name}</p>
                      </div>
                    </Card>{" "}
                  </div>
                </div>
              ))}
            </div> */}
            <div className="tw-pb-5 container tw-pt-10 tw-flex tw-flex-wrap tw-align-start tw-gap-6 tw-cursor-pointer">
              {visadata && visadata.length > 0 ? (
                visadata?.map((val, i) => {
                  const imageUrl = global.BASEURL + val.cat_id?.image;

                  return (
                    <div key={i}>
                      <Card
                        onClick={() => detailCountry(val?._id)}
                        style={{ height: "100%", width: "300px" }}
                      >
                        <CardMedia
                          component="img"
                          image={`${global.BASEURL}api/${val?.image}`}
                          alt="Visa Image"
                          style={{
                            objectFit: "cover",
                            width: "100%", // Adjust as needed
                            height: "140px", // Adjust as needed
                          }}
                        // component="img"
                        // image={`${global.BASEURL}api/${val?.image}`}
                        // alt="green iguana"
                        // style={{
                        //   objectFit: "cover",
                        //   width: "200px",
                        //   height: "140px",
                        // }}
                        />
                        <CardContent>
                          <div>
                            {/* <p>
                              <span
                                className="lato_bold tw-text-lg"
                                style={{ marginRight: "10px" }}
                              >
                                {val.cat_id?.name}
                              </span>
                            </p> */}
                            <span className="tw-text-sm tw-text-gray-500">
                              {val.country}
                            </span>
                          </div>
                          <p className="lato_regular">{val.visa_validity}</p>
                        </CardContent>
                      </Card>
                    </div>
                  );
                })
              ) : (
                <p className="tw-text-indigo-900">
                  No application right now ...
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ListCat;
