import axios from "axios";
export const login = async (data) => {
    try {
      const res = await axios.post(
        global.BASEURL + "api/auth/admin",    
        {
          phone: data.phone,
          password: data.password,  
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
  
      console.log(res.data, "res");
          if (res.data) {
        console.log(res.data,"res.. true")
        // Assuming your API returns a JSON object with a 'token' property
     
        // Save the token in a secure manner (e.g., localStorage, sessionStorage, or secure cookie)
        // For security reasons, consider using HttpOnly cookies for storing tokens
        localStorage.setItem("token", res?.data?.token);
        localStorage.setItem("visa10_user_data", JSON.stringify(res?.data?.user));
        // Successful login
        console.log("Login successful");
   
      } else {
        // console.log(res.data,"res")

        // Handle login failure
        console.error("Login failed");
      }
      return res.data;
    } catch (error) {
      console.log(error, "error");
       ; // Rethrow the error to handle it in the component
    }
  };
  export const register = async (data) => {
    try {
      const res = await axios.post(
        global.BASEURL + "api/users/signup/admin",    
        {
          name: data?.name,
          email:data?.email,
          phone: data.phone,
          password: data.password,  
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.data) {
        console.log(res.data,"res.. true")
        // Assuming your API returns a JSON object with a 'token' property
     
        // Save the token in a secure manner (e.g., localStorage, sessionStorage, or secure cookie)
        // For security reasons, consider using HttpOnly cookies for storing tokens
        localStorage.setItem("token", res?.data?.token);
        localStorage.setItem("visa10_user_data", JSON.stringify(res?.data?.user));
        // Successful login
        console.log("Registered successful");
   
      } else {
        // console.log(res.data,"res")

        // Handle login failure
        console.error("Login failed");
      }
      console.log(res.data, "res");
      return res.data;
    } catch (error) {
      console.log(error, "error");
       ; // Rethrow the error to handle it in the component
    }
  };