/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useEffect } from "react";
import logo from "../assets/png/visaLogo.png";
import auth from "../assets/png/auth.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Col,
  FormFeedback,
  Spinner,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { updatePassword } from "../api/forgot";
const ChangePassword = ({ setIsLoggedIn }) => {
  // states----
  const [loading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  // form validation---------
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
      conPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Please Enter Password"),
      conPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .min(6, "Password must be at least 6 characters")
        .required("Please Enter Confirm Password"),
    }),
    onSubmit: async (values, { resetForm }) => {
setIsLoading(true)

      updatePassword(values.password).then((res)=>{
        if(res?.success===true){
setIsLoading(false)
          toast.success('Password Updated Successfully')
          navigate("/");
        }
      }).catch((err)=>{
setIsLoading(false)

      })
    },
  });

  return (
    <div className="container min-[1440px]:tw-px-10 ">
      <div className="tw-pb-5">
        <div className="tw-pt-10">
          <img src={logo} width="200px" alt="" />
        </div>
        <div className="register_main d-sm-flex flex-lg-row flex-sm-column  justify-content-between align-items-center tw-px-0 px-0">
          <div className="">
            <img src={auth} alt="authPic" className="img-fluid" />
          </div>
          <div>
            <div className="md:tw-w-96">
              <div className="lato_regular tw-text-4xl tw-pt-10 ">
                Welcome back! Glad to see you, Again!
              </div>
            </div>
            <Form onSubmit={validation.handleSubmit} className="mt-3">


              <FormGroup row>
                <Col md={10} sm={12} className="p-0">
                  <Input
                    type="password"
                    className="form-control"
                    name="password"
                    id="password"
                    placeholder="Password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.password || ""}
                    invalid={
                      validation.touched.password && validation.errors.password
                        ? true
                        : false
                    }
                  />
                  {validation.touched.password && validation.errors.password ? (
                    <FormFeedback type="invalid">
                      {validation.errors.password}
                    </FormFeedback>
                  ) : null}
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md={10} sm={12} className="p-0">
                  <Input
                  
                    type="password"
                    className="form-control "
                    name="conPassword"
                    id="conPassword"
                    placeholder="Confirm Password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.conPassword || ""}
                    invalid={
                      validation.touched.conPassword && validation.errors.conPassword
                        ? true
                        : false
                    }
                  />
                  {validation.touched.conPassword && validation.errors.conPassword ? (
                    <FormFeedback type="invalid">
                      {validation.errors.conPassword}
                    </FormFeedback>
                  ) : null}
                </Col>
              </FormGroup>
              {/* <Link to='/register' className="forgot tw-flex tw-justify-end pb-4">
                <p className="lato_medium tw-cursor-pointer">
                  Don't have an Account..?
                </p>
              </Link> */}

              <div className="tw-flex tw-align-middle tw-justify-center">
                <Button

                  disabled={loading}
                  type="submit"
                  className="auth_btn tw-text-white"
                  color="primary"
                >
                  {loading ? (
                    <>
                      <Spinner size={"sm"} />
                    </>
                  ) : (
                    "Change Password"
                  )}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
