import React, { useEffect, useState } from "react";
import TablePartner from "../../tables/partnerTable/tablePartner";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { data } from "../../tables/partnerTable/dataPartner";
import { changeUserStatus, getParteners, updatePassword } from "../../api/partner";
import { faL } from "@fortawesome/free-solid-svg-icons";
import { Alert, Input } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Edit } from "react-feather";
import {  Modal, Button } from 'antd';

const theme = createTheme();

const ListPartner = () => {
  // states-----
  const [data, setData] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [loading2, setIsLoading2] = useState(false);

  const [count, setCount] = useState(1);
  const [page, setpage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [id , setId]=useState('')
  // functions--------
  const deactivateStatus = (row) => {
    setIsLoading(true);
    console.log(row?._id);
    changeUserStatus("deactivated", row?._id)
      .then((res) => {
        if (res?.message) {
          toast.success("Status Updated Successfully");
          getParteners(count).then((res) => {
            console.log("API Response:", res);
            setData(res?.user);
            setIsLoading(false);
          });
        }
        console.log(res);
      })
      .catch((err) => {});
  };
  const activateStatus = (row) => {
    setIsLoading(true);
    console.log(row?._id);
    changeUserStatus("online", row?._id)
      .then((res) => {
        if (res?.message) {
          toast.success("Status Updated Successfully");
          getParteners(count).then((res) => {
            console.log("API Response:", res);
            setData(res?.user);
            setIsLoading(false);
          });
        }
        console.log(res);
      })
      .catch((err) => {});
  };
  const handleEditClick = (row) => {
    setSelectedRow(row?.temporaryUnhashedPassword);
    setShowModal(true);
    setId(row?._id)
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedRow(null);
  };
  const handleOk=()=>{
    setShowModal(false);
    setIsLoading(true);
    setIsLoading2(true)
//     updatePassword(selectedRow,id).then((res)=>{
//       if(res){
//         toast.success('Password updated successfully')
//     setIsLoading2(false)
//     getParteners(count).then((res) => {
//       setData(res?.user);
//       setpage(res?.count?.totalPage);
//       setIsLoading(false);
//     });
//       }
// console.log(res,"res of update pass")
//     }).catch((err)=>{})

  }
  // useEffects---------
  useEffect(() => {
    setIsLoading(true);
    getParteners(count).then((res) => {
      console.log(res, "res");
      setData(res?.user);
      setpage(res?.count?.totalPage);
      setIsLoading(false);
    });
  }, [count]);

  const columns = [
    {
      name: "Name",
      minWidth: "130px",
      sortable: true,
      maxWidth: "45px",
      selector: (row) => row.name,
    },
    {
      name: "Email",
      minWidth: "200px",

      sortable: true,
      selector: (row) => row.email,
    },
    {
      name: "Phone Number",
      sortable: true,
      minWidth: "150px",
      selector: (row) => row.phone,
    },
    {
      name: "Residence",
      sortable: true,
      minWidth: "100px",
      selector: (row) => row.residence,
    },
    {
      name: "Nationality",
      sortable: true,
      minWidth: "120px",
      selector: (row) => row.nationality,
    },

    {
      name: "Address",
      sortable: true,
      minWidth: "170px",
      selector: (row) => row.address1,
    },
    {
      name: "Password",
      minWidth:"150px",

      sortable: true,
      selector: (row) => {
        return (
          <>
            <div className="d-flex gap-4 align-items-center  flex-row">
              <div style={{minWidth:"70px"}}>{row.temporaryUnhashedPassword}</div>
              <div>
              <Edit style={{cursor:"pointer"}} size={12} onClick={() => handleEditClick(row)} />
              </div>
            </div>
          </>
        );
      },
    },
    {
      name: "UserID",
      minWidth: "250px",
      sortable: true,
      selector: (row) => row._id,
    },
    {
      name: " Status",
      minWidth: "200px",
      sortable: true,
      maxWidth: "45px",
      selector: (row) => {
        return (
          <p
            className={
              row?.status == "online" || !row?.status
                ? " text-success"
                : " text-danger"
            }
          >
            {row?.status == "online" || !row?.status ? "Active" : "De Active"}
          </p>
        );
      },
    },
    {
      name: "Change Status",
      minWidth: "200px",
      sortable: true,
      maxWidth: "45px",
      selector: (row) => {
        if (row?.status === "online" || !row?.status) {
          return (
            <button
              style={{ minWidth: "110px" }}
              onClick={() => deactivateStatus(row)}
              className={
                row?.status == "online" || !row?.status
                  ? "btn btn-md btn-danger"
                  : "btn btn-md btn-success"
              }
            >
              {row?.status == "online" || !row?.status
                ? "De Activate"
                : "Activate"}
            </button>
          );
        } else if (row?.status === "deactivated") {
          return (
            <button
              style={{ minWidth: "110px" }}
              onClick={() => activateStatus(row)}
              className={
                row?.status == "deactivated" || !row?.status
                  ? "btn btn-md btn-success"
                  : "btn btn-md btn-danger"
              }
            >
              {row?.status == "deactivated" ? "Activate" : "De Activate"}
            </button>
          );
        }
      },
    },
    // {
    //     name: 'Status',
    //     sortable: true,
    //     minWidth: '120px',
    //     cell: (row) => {
    //         return (
    //             <div className="border border-white bg-[#ecf8f0] text-[#1C8C6E] rounded text-center py-[6px] w-[100px] h-auto">
    //                 {row.status}
    //             </div>
    //         )
    //     }
    // },
    // {
    //     name: 'Action',
    //     allowOverflow: true,
    //     minWidth: '112px',
    //     cell: () => {
    //         return (
    //             <></>
    //             // <div className='flex gap-1'>
    //             //     <button className="bg-[#2B7F75] flex justify-center rounded-lg w-[24px] h-[24px] items-center"><img className="w-[12px] h-auto" src={preview} alt="" /></button>
    //             //     <button className="bg-[#54A6FF] flex justify-center rounded-lg w-[24px] h-[24px] items-center"><img className="w-[12px] h-auto" src={edit2} alt="" /></button>
    //             //     <button className="bg-[#CE2C60] flex justify-center rounded-lg w-[24px] h-[24px] items-center"><img className="w-[12px] h-auto" src={trash} alt="" /></button>
    //             // </div>
    //         )
    //     }
    // }
  ];
  return (
    <div>
      <div className="bg ">
        <div className="tw-pb-5 container tw-pt-8 tw-flex tw-flex-wrap tw-align-start tw-gap-6  ">
          <ThemeProvider theme={theme}>
          <Modal
        title="Edit Password"
        visible={showModal}
        onOk={handleOk}
        okButtonProps={{
            style: { backgroundColor: "#4CAF50", color: "white" }, // Set background color for OK button
          }}
        onCancel={handleCloseModal}
          cancelButtonProps={{
            style: { backgroundColor: "#f44336", color: "white" }, // Set background color for Cancel button
          }}
      >
        {/* Your edit form goes here */}
      <Input onChange={(e)=>{setSelectedRow(e.target.value)}} value={selectedRow} type='text'/>

      </Modal>
            <Box width={{ md: "94%", xs: "100%" }}>
              <Box
                sx={{
                  bgcolor: "#fff",
                  minHeight: "auto",
                  paddingBottom: "2rem",
                }}
              >
                <TablePartner
                  columns={columns}
                  data={data}
                  showRow={true}
                  rowHeading="New Order"
                  loading={loading}
                  setCount={setCount}
                  page={page}
                />
              </Box>
            </Box>
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default ListPartner;
