import axios from "axios";
export const checkEmail = async (email) => {
  try {
    const res = await axios.post(
      global.BASEURL + "api/users/check-email",
      {
        email: email,
      
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log(res, "res");
    return res;
  } catch (error) {
    console.log(error, "error");
     ; // Rethrow the error to handle it in the component
  }
};
export const checkPhone = async (phone) => {
    try {
      const res = await axios.post(
        global.BASEURL + "api/users/check-phone",
        {
          phone: `+${phone}`,
        
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(res, "res");
      return res;
    } catch (error) {
      console.log(error, "error");
       ; // Rethrow the error to handle it in the component
    }
  };