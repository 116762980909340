// get req
import axios from "axios";
export const getCustomers = async (param) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("Token not found in local storage");
        return;
      }
      const res = await axios.get(
         `${global.BASEURL}api/users/customer/${param}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
          },
        }
      );
  
      console.log("Response status:", res.status);
      console.log("Response data:", res.data);
  
      return res.data;
    } catch (error) {
      console.error("Error fetching category:", error);
       ;
    }
  };
  // change Status-------
export const changeUserStatus = async (status,id) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.put(
       `${global.BASEURL}api/users/${status}/${id}`,
      {}, 
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      }
    );
    console.log(res.data, "res");
    return res.data;
  } catch (error) {
    console.log(error, "error");
     ; // Rethrow the error to handle it in the component
  }
};