import axios from "axios";

export const getNotification = async (pageNum) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      return;
    }
    const res = await axios.get(
      global.BASEURL+"api/notification/admin/" + pageNum,
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      }
    );

    console.log("Response data:", res);

    return res.data;
  } catch (error) {
    console.error("Error fetching category:", error);
     ;
  }
};
// badge api ---
export const getBadgeValue = async () => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      return;
    }
    const res = await axios.get(
      global.BASEURL+"api/notification/seen",
      
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      }
    );

    console.log("Response data:", res);

    return res.data;
  } catch (error) {
    console.error("Error fetching category:", error);
     ;
  }
};
// update badge---
export const updateBadgeValue = async () => {
  try {
    var token = localStorage.getItem("token");
    console.log(token,"token...")
    if (!token) {
      return false;
    }
    else{
      const res = await axios.put(
        global.BASEURL+"api/notification/seen",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
          },
        }
      );  
      return res.data;
    }
  } catch (error) {
    console.error("Error fetching category:", error);
  }
};


