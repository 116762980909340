/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { data } from "../../tables/track/dataTrack";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TrackTable from "../../tables/track/trackTable";
import { getTrack, updateTrack } from "../../api/track";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const theme = createTheme();
const ListTrack = () => {
  // states------
  const [loading, setIsLoading] = useState(false);
  const [data, setData] = useState("");
  const [count, setCount] = useState(1)
  const [page, setpage] = useState('')
  const [type, settype] = useState('')
  const [date, setDate] = useState('')
const [status ,setStatus]=useState('')
  // functions---
  const handleUpdateStatus = () => {
    updateTrack()
  }
  // useEffects------
  useEffect(() => {
    const data={
      type:type,
      date:date,
      last_id:count,
      status:status
    }
    setIsLoading(true);
    getTrack(data).then((res) => {
    
      setData(res?.Applications);
      setpage(res?.count?.totalPage)
      setIsLoading(false);
    });
    
  }, [count,type,date,status]);
  const columns = [
    {
      name: "Case no",
      minWidth: "130px",
      sortable: true,
      maxWidth: "45px",
      selector: (row) => row.application_id,
    },
    {
      name: "Name",
      minWidth: "130px",
      sortable: true,
      maxWidth: "45px",
      selector: (row) => row.name,
    },
    {
      name: "Phone",
      minWidth: "150px",

      sortable: true,
      selector: (row) => row.phone,
    },

    {
      name: "Nationality",
      sortable: true,
      minWidth: "auto",
      selector: (row) => row.nationality,
    },
    {
      name: "Residence",
      sortable: true,
      minWidth: "auto",
      selector: (row) => row.residence,
    },
    {
      name: "Travel Country",
      sortable: true,
      minWidth: "auto",
      selector: (row) => row.travelcountry,
    },
    {
      name: "Status",
      sortable: true,
      minWidth: "auto",
      selector: (row) => {
        // console.log(row?.data)
        return (
          <>
            {row?.status === "pending" && (
              <span className="text-info">Pending</span>
            )}
            {row?.status === "contacted" && (
              <span className="text-dark">Contacted</span>
            )}
            {row?.status === "inprogress" && (
              <span className="text-success">Active</span>
            )}
            {row?.status === "accepted" && (
              <span className="text-success">Accepted</span>
            )}
            {row?.status === "rejected" && (
              <span className="text-danger">Rejected</span>
            )}
            {row?.status === "completed" && (
              <span className="text-primary">Completed</span>
            )}

          </>
        );
      },
    },

    {
      name: 'Check detail',
      sortable: true,
      minWidth: '120px',
      cell: (row) => {
        return (
          <Link to={`/track/details/${row._id}`}>
            <button className=" btn btn-sm bg-info  border d-flex justify-content-center  text-light border-white bg-[#ecf8f0] text-[#1C8C6E] rounded text-center w-[100px] h-auto">
              View
            </button>
          </Link>
        )
      }
    },
    // {
    //     name: 'Action',
    //     allowOverflow: true,
    //     minWidth: '112px',
    //     cell: () => {
    //         return (
    //             <></>
    //             // <div className='flex gap-1'>
    //             //     <button className="bg-[#2B7F75] flex justify-center rounded-lg w-[24px] h-[24px] items-center"><img className="w-[12px] h-auto" src={preview} alt="" /></button>
    //             //     <button className="bg-[#54A6FF] flex justify-center rounded-lg w-[24px] h-[24px] items-center"><img className="w-[12px] h-auto" src={edit2} alt="" /></button>
    //             //     <button className="bg-[#CE2C60] flex justify-center rounded-lg w-[24px] h-[24px] items-center"><img className="w-[12px] h-auto" src={trash} alt="" /></button>
    //             // </div>
    //         )
    //     }
    // }
  ];

  return (
    <div>
      <div className="bg ">
        <div className="tw-pb-5 container tw-pt-8 tw-flex tw-flex-wrap tw-align-start tw-gap-6  ">
          <ThemeProvider theme={theme}>
            <Box width={{ md: "94%", xs: "100%" }}>
              <Box
                sx={{
                  bgcolor: "#fff",
                  minHeight: "auto",
                  paddingBottom: "2rem",
                }}
              >
                <TrackTable
                  loading={loading}
                  columns={columns}
                  data={data}
                  showRow={true}
                  setCount={setCount}
                  page={page}
                  rowHeading="New Order"
                  settype={settype}
                  setDate={setDate}
                  type={type}
                  setStatus={setStatus}
                  status={status}
                />
              </Box>
            </Box>
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default ListTrack;
