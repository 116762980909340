/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { createPrivacy, getPrivacy } from "../../api/privacy_api";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
const theme = createTheme();

const PrivacyPolicy = () => {
  // states------
  const [editorData, setEditorData] = useState("");
  const [privacyData, setPrivacyData] = useState([]);
  const [privacyDatahtml, setPrivacyDatahtml] = useState("");
  const [nowget, setNowget] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(()=>{
    getPrivacy()
      .then((result) => {
        console.log(result.Policy[0].html,"r")
        const htmlContent= result.Policy[0].html
        let trimmedHtmlContent = htmlContent.substring(
          3,
          htmlContent.length - 4
        );
        trimmedHtmlContent = trimmedHtmlContent.replace(/&nbsp;/g, "");
        setPrivacyDatahtml(trimmedHtmlContent);
        // setPrivacyDatahtml(result.Policy[0].html)
        // setPrivacyDatahtml(result?.Policy[0]?.html);
        // if (result.success) {
        //   console.log(result,'res')
        //   setPrivacyData(result.Policy[0]?.html);
        // } else {
        //   setPrivacyData([]);
        // }
      })
      .catch((err) => {});
  },[nowget])
  const sanitizeHtml = (htmlContent) => {
    // Check if the content is a string before attempting to sanitize
    if (typeof htmlContent === "string") {
      // Remove <p> tags and &nbsp; from the HTML string
      return htmlContent.replace(/<p>|<\/p>|&nbsp;/g, "");
    } else {
      return htmlContent; // Return unchanged if not a string
    }
  };

  const createPrivacyPolicy = () => {
    setNowget(false);

    if (editorData === "") {
      return toast.warn("Please Add Privacy Policy Data");
    }
    setIsLoading(true);
    createPrivacy(editorData)
      .then((result) => {
        console.log(result, "r");
        const htmlContent = result.Policy.html;

        // Remove enclosing <p> tags
    

        if (result.success) {
          toast.success(result.message);
          setNowget(true);
          setEditorData("");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  console.log(privacyDatahtml, "dj");
  return (
    <div>
      <div className="bg ">
        <div className="tw-pb-5 container tw-pt-8 tw-flex tw-flex-wrap tw-align-start tw-gap-6  ">
          <ThemeProvider theme={theme}>
            <Box width={{ md: "94%", xs: "100%" }}>
              <Box
                sx={{
                  bgcolor: "#fff",
                  minHeight: "auto",
                  paddingBottom: "2rem",
                }}
              >
                <div className="p-3">
                  <h4 className="mb-4">Add Privacy Policy</h4>

                  <CKEditor
                    editor={ClassicEditor}
                    data={editorData}
                    config={{
                      toolbar: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "link",
                        "bulletedList",
                        "numberedList",
                        "blockQuote",
                        "ckfinder",
                        "|",
                        "imageTextAlternative",
                        "imageUpload",
                        "imageStyle:full",
                        "imageStyle:side",
                        "|",
                        "mediaEmbed",
                        "insertTable",
                        "tableColumn",
                        "tableRow",
                        "mergeTableCells",
                        "|",
                        "undo",
                        "redo",
                      ],
                    }}
                    onInit={(editor) => {
                      // You can access the editor instance here if needed
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      console.log(data, "onchange");
                      const eventWithTarget = { target: { data } };
                      setEditorData(data); // Assuming you want to set the data in the state
                      // Now you can use eventWithTarget wherever you need it
                    }}

                    // onChange={(event, editor) => {
                    //   const data = editor.getData();
                    //   setEditorData(data);
                    // }}
                  />
                  <div className="mt-4">
                    <button
                      type="button"
                      disabled={isLoading}
                      onClick={createPrivacyPolicy}
                      className="btn btn-outline-dark"
                    >
                      {isLoading ? (
                        <>
                          <Spinner size="sm" />
                        </>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </div>
              </Box>
            </Box>
          </ThemeProvider>
          <ThemeProvider theme={theme}>
            <Box width={{ md: "94%", xs: "100%" }}>
              <Box
                sx={{
                  bgcolor: "#fff",
                  minHeight: "auto",
                  paddingBottom: "2rem",
                }}
              >
                <div className="p-3">
                  <h4 className="mb-4">Privacy Policy</h4>
                  {/* <div>{sanitizeHtml(privacyData)}</div> */}
                  <div>{privacyDatahtml}</div>
                </div>
              </Box>
            </Box>
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
