/* eslint-disable no-unused-vars */
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import { Input } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Label, Spinner } from "reactstrap";
import * as yup from "yup";
import { getCategory_ } from "../../api/categories";
import { getServices } from "../../api/services";
import { uploadFile } from "../../api/upload";
import { getVisaById, updateVisa } from "../../api/visa";
import { delIcon, grid, upload } from "../../icons/icon";
import BlueButton from "../../resueComponent/blueButton";
import countries from "./country/countryData";
const theme = createTheme();
const AntTabs = styled((props) => <Tabs {...props} />)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#1890ff",
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);
const TabPanel = ({ value, index, children }) => (
  <div hidden={value !== index}>
    {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
  </div>
);
const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 99, // Adjust the zIndex as needed
  }),
};

const Detail = () => {
  const { id } = useParams();
  // states----------
  const [lastId, setLastId] = useState("");
  const [loadingImage, setloadingImage] = useState(false);

  const [value, setValue] = React.useState(0);
  const [note, setNote] = useState("");
  const [name, setname] = useState("");
  const [service, setService] = useState("");
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [visaCat, setVisaCat] = useState([]);
  const [serviceCat, setServiceCat] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [currentTabValue, setCurrentTabValue] = useState(0);
  const [options, setOptions] = useState([]);
  const [options2, setOptions2] = useState([]);

  const [inputValue2, setInputValue2] = useState("");
  const [chkinputValue, setChkInputValue] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [inputFields, setInputFields] = useState([]);
  const [inputFields2, setInputFields2] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState("");
  const [destination, setDestination] = useState([]);
  const [residence, setResidence] = useState("");
  const [chkinputFields, setChkInputFields] = useState([]);
  const [checkdynamic, setCheckDynamic] = useState([]);
  const [dynamic, setDynamic] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOption2, setSelectedOption2] = useState("");
  const [dest, setdest] = useState("");
  const [s_lastId, sets_lastId] = useState("");
  const [resd, setResd] = useState("");
  const [count, setCount] = useState("");
  const navigate = useNavigate();
  // functions------------
  const handleMenuScrollToBottom = () => {
    getCategory_(lastId)
      .then((res) => {
        setVisaCat(res?.categories);
        setLastId(res?.categories[res?.categories?.length - 1]?._id);
        const data = res?.categories?.map((d) => ({
          value: d._id,
          label: d.name,
        }));
        setOptions((prev) => [...prev, ...data]);
      })
      .catch((err) => {});
  };
  const handleMenuScrollToBottom2 = () => {
    getServices(s_lastId)
      .then((res) => {
        setServiceCat(res?.services);
        sets_lastId(res?.services[res?.services?.length - 1]?._id);
        const sData = res?.services?.map((d) => ({
          value: d._id,
          label: d.name,
        }));
        setOptions2(sData);
      })
      .catch((err) => {});
  };
  const abc = () => {
    const randomKey = Math.random().toString(36).substr(2, 5); // Generate a random key for each input field
    const newInputField = {
      value: "",
    };
    setInputFields([...inputFields, newInputField]);
    setInputValue2("");
  };
  const handleInputChange = (index, value) => {
    const updatedInputFields = [...inputFields];
    updatedInputFields[index].value = value;
    setInputFields(updatedInputFields);
  };
  const handleInputChklist = (index, value) => {
    const updatedInputFields = [...chkinputFields];
    updatedInputFields[index].value = value;
    setChkInputFields(updatedInputFields);
  };
  const checklist = () => {
    const randomKey = Math.random().toString(36).substr(2, 5); // Generate a random key for each input field
    const newInputField = {
      value: "",
    };
    setChkInputFields([...chkinputFields, newInputField]);
    setChkInputValue("");
  };
  useEffect(() => {}, []);
  const handleClearInput = () => {
    setInputValue("");
  };
  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    setloadingImage(true);
    const file = event.target.files[0];
    if (file) {
      try {
        // Upload file and wait for the result
        const result = await uploadFile(file);
        setloadingImage(false);
        // Handle the result as needed
        setSelectedFile(result?.image);
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewImage(reader.result);
        };
        reader.readAsDataURL(file);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleAutocompleteChange = (event, newValue) => {
    // Handle the change and set the selected label
    setSelectedLabel(newValue?.label || "");
  };

  const handleOptionChange = (selectedOption) => {
    // const array = selectedOption?.map((val) => {
    //   return { label: val.label, value: val?.value };
    // });
    setSelectedOption(selectedOption);
    // setname(selectedOption?.label);
  };

  const handleOptionChange2 = (selectedOption) => {
    // const array2 = selectedOption?.map((val) => {
    //   return { label: val.label, value: val?.value };
    // });
    setSelectedOption2(selectedOption);
    // setService(selectedOption?.label);
  };
  // form validation---------
  const validationSchema = yup.object({
    stayDuration: yup.string().required("Stay Duration is required"),
    visaValidity: yup.string().required("Visa Validity is required"),
    visaEntry: yup.string().required("Visa Entry is required"),
    processingTime: yup.string().required("Processing Time is required"),
    standardVisaCost: yup.string().required("Standard Visa Cost is required"),
    counsellorCost: yup.string().required("Counsellor Cost is required"),
    offersAndBenefits: yup
      .string()
      .required("Offers And Benefits are required"),
    entryRestrictions: yup.string().required("Entry Restrictions are required"),
    entryHealthRequirements: yup
      .string()
      .required("Entry & Health Requirements are required"),
    inCountryExperience: yup
      .string()
      .required("In-country experience is required"),
    transitRestrictions: yup
      .string()
      .required("Transit Restrictions are required"),
    reEntryRequirements: yup
      .string()
      .required("Re-Entry Requirements are required"),
  });
  const formik = useFormik({
    initialValues: {
      stayDuration: "",
      visaValidity: "",
      visaEntry: "",
      destination: "",
      processingTime: "",
      standardVisaCost: "",
      counsellorCost: "",
      offersAndBenefits: "",
      entryRestrictions: "",
      entryHealthRequirements: "",
      inCountryExperience: "",
      transitRestrictions: "",
      reEntryRequirements: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const cat_ids = selectedOption?.map((item) => item.value);
      const service_ids = selectedOption2?.map((item) => item.value);

      const data = {
        image: selectedFile,
        values: values,
        country: selectedLabel,
        cat_id: cat_ids,
        service: service_ids,
        steps: dynamic,
        residence: residence,
        destination: destination,
        note: note,
        checklist: checkdynamic,
      };
      const data2 = inputFields?.filter((item) => item?.value?.trim());
      const CheckList = chkinputFields?.filter((item) => item?.value?.trim());

      if (
        residence == "" ||
        destination == "" ||
        data2.length <= 0 ||
        !note ||
        CheckList?.length === 0 ||
        chkinputFields.length === 0
      ) {
        toast.error("Fill form Data first");
      } else {
        setIsLoading(true);

        // alert('ok')
        updateVisa(data, id)
          .then((res) => {
            setIsLoading(false);
            toast.success("Edited successfully..!");
            resetForm();
            setCheckDynamic([]);
            setSelectedFile(null);
            setChkInputFields([]);
            navigate("/explore/listCat");
          })
          .catch((err) => {
            setIsLoading(false);
          });
      }
    },
  });
  const handleRemoveField = (index) => {
    const updatedFields = [...inputFields];
    updatedFields.splice(index, 1);
    setInputFields(updatedFields);
  };
  const handleRemoveChkField = (index) => {
    const updatedFields = [...chkinputFields];
    updatedFields.splice(index, 1);
    setChkInputFields(updatedFields);
  };
  // useEffect-------
  useEffect(() => {
    getVisaById(id).then((res) => {
      const newData = formik.values;
      var resData = res.visaApplication;
      newData.visaValidity = resData?.visa_validity;
      newData.visaEntry = resData?.visa_entry;
      newData.processingTime = resData?.processing_time;
      newData.standardVisaCost = resData?.standard_cost;
      newData.counsellorCost = resData?.counselar_cost;
      newData.offersAndBenefits = resData?.offers;
      newData.entryRestrictions = resData?.entry_restrictions;
      newData.entryHealthRequirements = resData?.entry_health_req;
      newData.inCountryExperience = resData?.in_country_exp;
      newData.transitRestrictions = resData?.transit_restrictions;
      newData.reEntryRequirements = resData?.re_entry_req;
      newData.stayDuration = resData?.stay_duration;
      newData.destination = resData?.destination;
      setSelectedFile(resData?.image);
      setCheckDynamic(JSON.parse(resData?.checklist));
      setdest(resData?.destination);
      setResd(resData?.residence);
      setCount(resData?.country);
      setDestination(resData?.destination);
      setResidence(resData?.residence);
      // setChkInputFields(JSON.parse(resData?.checklist))
      setSelectedLabel(resData?.country);
      setNote(resData?.note);
      // setDynamic(JSON.parse(resData?.steps))
      // setResidence(resData?.)
      //
      let array = [];
      let array2 = [];
      let array3 = [];
      let array4 = [];
      resData?.cat_id.forEach((element) => {
        array.push({ label: element?.name, value: element?._id });
      });
      JSON.parse(resData?.checklist)?.forEach((element) => {
        array2.push({ value: element });
      });
      JSON.parse(resData?.steps)?.forEach((element) => {
        array3.push({ value: element });
      });
      resData?.service.forEach((element) => {
        array4.push({ label: element?.name, value: element?._id });
      });
      setSelectedOption(array);
      setChkInputFields(array2);
      setInputFields(array3);
      setSelectedOption2(array4);
    });
  }, []);
  useEffect(() => {
    getCategory_(lastId)
      .then((res) => {
        setVisaCat(res?.categories);
        // const data = res?.categories
        setLastId(res?.categories[res?.categories?.length - 1]?._id);
        const data = res?.categories?.map((d) => ({
          value: d._id,
          label: d.name,
        }));
        setOptions(data);
        // setCount(res?.count.totalPage)
      })
      .catch((err) => {});
  }, []);
  useEffect(() => {
    getServices(s_lastId)
      .then((res) => {
        setServiceCat(res?.services);
        sets_lastId(res?.services[res?.services?.length - 1]?._id);
        const sData = res?.services?.map((d) => ({
          value: d._id,
          label: d.name,
        }));
        setOptions2(sData);
      })
      .catch((err) => {});
  }, []);
  useEffect(() => {
    const updatedDynamic = [];
    for (let i = 0; i < inputFields.length; i++) {
      const element = inputFields[i].value;
      if (inputFields[i].value !== "") {
        updatedDynamic.push(element);
        setDynamic(updatedDynamic);
      }
    }
    if (inputFields.length == 0) {
      setDynamic([]);
    }
  }, [inputFields]);
  useEffect(() => {
    const updatedDynamic = [];

    for (let i = 0; i < chkinputFields.length; i++) {
      const element = chkinputFields[i].value;
      updatedDynamic.push(element);
      setCheckDynamic(updatedDynamic);
    }
    if (chkinputFields.length == 0) {
      setCheckDynamic([]);
    }
  }, [chkinputFields]);
  return (
    <div className="bg ">
      <div className="tw-pb-5 container tw-pt-16 tw-flex tw-flex-wrap tw-align-start tw-gap-6  ">
        <ToastContainer />

        <ThemeProvider theme={theme}>
          <Box width={{ md: "80%", xs: "100%" }}>
            <Box sx={{ bgcolor: "#fff", minHeight: "100vh" }}>
              <Form onSubmit={formik.handleSubmit}>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div
                    style={{ border: "1px solid #CCCCCC " }}
                    className="upload tw-rounded-xl tw-bg-white tw-p-3 tw-h-50 tw-w-64 tw-cursor-pointer mt-2 "
                    onClick={handleDivClick}
                  >
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                    <div className="upload_border tw-h-44 d-flex flex-column align-items-center justify-content-center">
                      {/* {previewImage || selectedFile ? (
                        <img
                          src={
                            previewImage
                              ? previewImage
                              : global.Upload + selectedFile
                          }
                          alt="upload preview"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <>
                          <img src={upload} alt="upload" />
                          <div className="text-center ">
                            <p
                              className="m-0 tw-text-xs"
                              style={{ color: "#333", fontSize: "9px" }}
                            >
                              Click to select or ‘Drag and drop’ your Image here
                            </p>
                          </div>
                        </>
                      )} */}
                      {previewImage || selectedFile ? (
                        <img
                          src={
                            previewImage
                              ? previewImage
                              : global.Upload + selectedFile
                          }
                          alt="upload preview"
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            objectFit: "cover",
                          }}
                        />
                      ) : loadingImage ? (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ position: "absolute", bottom: "45%" }}
                        >
                          <Spinner size="sm" />
                        </div>
                      ) : (
                        <>
                          <img src={upload} alt="upload" />
                          <div className="text-center ">
                            <p
                              className="m-0 tw-text-xs"
                              style={{ color: "#333", fontSize: "9px" }}
                            >
                              Click to select or ‘Drag and drop’ your Image here
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div class="row mt-4 align-items-center px-4 ">
                    <div className="col-md-6 col-12 pt-3">
                      <Label className=" label_font">Visa Category</Label>
                      <Select
                        options={options}
                        isMulti
                        onMenuScrollToBottom={handleMenuScrollToBottom}
                        value={selectedOption}
                        styles={customStyles}
                        onChange={handleOptionChange}
                      />
                    </div>
                    <div className="col-md-6 col-12 pt-3">
                      <Label className="label_font">Service Category</Label>
                      <Select
                        options={options2}
                        value={selectedOption2}
                        onMenuScrollToBottom={handleMenuScrollToBottom2}
                        isMulti
                        styles={customStyles}
                        onChange={handleOptionChange2}
                      />
                    </div>

                    {/* Visa Validity */}

                    <div className="col-md-6 col-12 pt-4">
                      <TextField
                        id="visaValidity"
                        label={
                          <>
                            Visa Validity{" "}
                            <span className="tw-text-red-600"> *</span>
                          </>
                        }
                        variant="outlined"
                        className="myCustomTextField w-100"
                        value={formik.values.visaValidity}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.visaValidity &&
                          Boolean(formik.errors.visaValidity)
                        }
                        helperText={
                          formik.touched.visaValidity &&
                          formik.errors.visaValidity
                        }
                      />
                    </div>
                    <div className="col-md-6 col-12 pt-4">
                      <TextField
                        id="visaEntry"
                        label={
                          <>
                            Visa Entry{" "}
                            <span className="tw-text-red-600"> *</span>
                          </>
                        }
                        variant="outlined"
                        className="myCustomTextField w-100"
                        value={formik.values.visaEntry}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.visaEntry &&
                          Boolean(formik.errors.visaEntry)
                        }
                        helperText={
                          formik.touched.visaEntry && formik.errors.visaEntry
                        }
                      />
                    </div>
                    {/* Visa Entry */}
                    {/* Processing Time */}
                    <div className="col-md-6 col-12 pt-4">
                      <TextField
                        id="processingTime"
                        label={
                          <>
                            Processing Time{" "}
                            <span className="tw-text-red-600"> *</span>
                          </>
                        }
                        variant="outlined"
                        className="myCustomTextField w-100"
                        value={formik.values.processingTime}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.processingTime &&
                          Boolean(formik.errors.processingTime)
                        }
                        helperText={
                          formik.touched.processingTime &&
                          formik.errors.processingTime
                        }
                      />
                    </div>
                    <div className="col-md-6 col-12 pt-4">
                      <TextField
                        id="standardVisaCost"
                        label={
                          <>
                            Standard Visa Cost{" "}
                            <span className="tw-text-red-600"> *</span>
                          </>
                        }
                        variant="outlined"
                        className="myCustomTextField w-100"
                        value={formik.values.standardVisaCost}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.standardVisaCost &&
                          Boolean(formik.errors.standardVisaCost)
                        }
                        helperText={
                          formik.touched.standardVisaCost &&
                          formik.errors.standardVisaCost
                        }
                      />
                    </div>

                    {/* Standard Visa Cost */}

                    {/* Counsellor Cost */}
                    <div className="col-md-6 col-12 pt-4">
                      <TextField
                        id="counsellorCost"
                        label={
                          <>
                            Counsellor Cost{" "}
                            <span className="tw-text-red-600"> *</span>
                          </>
                        }
                        variant="outlined"
                        className="myCustomTextField w-100"
                        value={formik.values.counsellorCost}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.counsellorCost &&
                          Boolean(formik.errors.counsellorCost)
                        }
                        helperText={
                          formik.touched.counsellorCost &&
                          formik.errors.counsellorCost
                        }
                      />
                    </div>
                    <div className="col-md-6 col-12 pt-4">
                      <TextField
                        id="offersAndBenefits"
                        label={
                          <>
                            Offers And Benefits{" "}
                            <span className="tw-text-red-600"> *</span>
                          </>
                        }
                        variant="outlined"
                        className="myCustomTextField w-100"
                        value={formik.values.offersAndBenefits}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.offersAndBenefits &&
                          Boolean(formik.errors.offersAndBenefits)
                        }
                        helperText={
                          formik.touched.offersAndBenefits &&
                          formik.errors.offersAndBenefits
                        }
                      />
                    </div>
                    {/* Offers And Benefits */}

                    {/* Entry Restrictions */}
                    <div className="col-md-6 col-12 pt-4">
                      <TextField
                        id="entryRestrictions"
                        label={
                          <>
                            Entry Restrictions{" "}
                            <span className="tw-text-red-600"> *</span>
                          </>
                        }
                        variant="outlined"
                        className="myCustomTextField w-100"
                        value={formik.values.entryRestrictions}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.entryRestrictions &&
                          Boolean(formik.errors.entryRestrictions)
                        }
                        helperText={
                          formik.touched.entryRestrictions &&
                          formik.errors.entryRestrictions
                        }
                      />
                    </div>
                    <div className="col-md-6 col-12 pt-4">
                      <TextField
                        id="entryHealthRequirements"
                        label={
                          <>
                            Entry & Health Requirements{" "}
                            <span className="tw-text-red-600"> *</span>
                          </>
                        }
                        variant="outlined"
                        className="myCustomTextField w-100"
                        value={formik.values.entryHealthRequirements}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.entryHealthRequirements &&
                          Boolean(formik.errors.entryHealthRequirements)
                        }
                        helperText={
                          formik.touched.entryHealthRequirements &&
                          formik.errors.entryHealthRequirements
                        }
                      />
                    </div>
                    {/* Entry & Health Requirements */}

                    {/* In-country experience */}
                    <div className="col-md-6 col-12 pt-4">
                      <TextField
                        id="inCountryExperience"
                        label={
                          <>
                            In-country experience{" "}
                            <span className="tw-text-red-600"> *</span>
                          </>
                        }
                        variant="outlined"
                        className="myCustomTextField w-100"
                        value={formik.values.inCountryExperience}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.inCountryExperience &&
                          Boolean(formik.errors.inCountryExperience)
                        }
                        helperText={
                          formik.touched.inCountryExperience &&
                          formik.errors.inCountryExperience
                        }
                      />
                    </div>
                    <div className="col-md-6 col-12 pt-4">
                      <TextField
                        id="transitRestrictions"
                        label={
                          <>
                            Transit Restrictions{" "}
                            <span className="tw-text-red-600"> *</span>
                          </>
                        }
                        variant="outlined"
                        className="myCustomTextField w-100"
                        value={formik.values.transitRestrictions}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.transitRestrictions &&
                          Boolean(formik.errors.transitRestrictions)
                        }
                        helperText={
                          formik.touched.transitRestrictions &&
                          formik.errors.transitRestrictions
                        }
                      />
                    </div>
                    <div className="col-md-6 col-12 pt-4">
                      <TextField
                        style={{ width: "100%" }}
                        id="stayDuration"
                        label={
                          <>
                            Stay Duration{" "}
                            <span className="tw-text-red-600">*</span>
                          </>
                        }
                        variant="outlined"
                        className="myCustomTextField"
                        value={formik.values.stayDuration}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.stayDuration &&
                          Boolean(formik.errors.stayDuration)
                        }
                        helperText={
                          formik.touched.stayDuration &&
                          formik.errors.stayDuration
                        }
                      />
                    </div>
                    <div className="col-md-6 col-12 pt-4">
                      <Autocomplete
                        id="country-select-demo"
                        sx={{ width: "100%" }}
                        options={countries}
                        defaultValue={count}
                        autoHighlight
                        onChange={handleAutocompleteChange}
                        // getOptionLabel={(option) => {
                        //   return option.label;
                        // }}
                        getOptionLabel={(option) =>
                          (option && option.label) || count
                        }
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              alt=""
                            />
                            {option.label} ({option.code}) +{option.phone}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              <>
                                Country{" "}
                                <span className="tw-text-red-600">*</span>
                              </>
                            }
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                            InputProps={{
                              ...params.InputProps,
                              classes: {
                                focused: "custom-focused", //   your custom-focused class here
                              },
                            }}
                            InputLabelProps={{
                              classes: {
                                root: "custom-label-root", // Add your custom label root class here
                              },
                            }}
                          />
                        )}
                      />
                    </div>
                    {/* Transit Restrictions */}

                    {/* Re-Entry Requirements */}
                    <div className="col-12 pt-4">
                      <TextField
                        id="reEntryRequirements"
                        label={
                          <>
                            Re-Entry Requirements{" "}
                            <span className="tw-text-red-600"> *</span>
                          </>
                        }
                        variant="outlined"
                        className="myCustomTextField w-100"
                        value={formik.values.reEntryRequirements}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.reEntryRequirements &&
                          Boolean(formik.errors.reEntryRequirements)
                        }
                        helperText={
                          formik.touched.reEntryRequirements &&
                          formik.errors.reEntryRequirements
                        }
                      />
                    </div>
                    <div className="col-md-6 col-12 pt-4">
                      <Autocomplete
                        id="country-select-demo"
                        sx={{ width: "100%" }}
                        options={countries}
                        autoHighlight
                        defaultValue={dest}
                        onChange={(event, value) =>
                          setDestination(value ? value.label : "")
                        }
                        // getOptionLabel={(option) => option.label}
                        getOptionLabel={(option) =>
                          (option && option.label) || dest
                        }
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              alt=""
                            />
                            {option.label} ({option.code}) +{option.phone}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              <>
                                Destination{" "}
                                <span className="tw-text-red-600">*</span>
                              </>
                            }
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                            InputProps={{
                              ...params.InputProps,
                              classes: {
                                focused: "custom-focused", // your custom-focused class here
                              },
                            }}
                            InputLabelProps={{
                              classes: {
                                root: "custom-label-root", // Add your custom label root class here
                              },
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="col-md-6 col-12 pt-4">
                      <Autocomplete
                        id="country-select-demo"
                        sx={{ width: "100%" }}
                        options={countries}
                        autoHighlight
                        defaultValue={resd}
                        onChange={(event, value) =>
                          setResidence(value ? value.label || "" : "")
                        }
                        // getOptionLabel={(option) => {
                        //   return option.label;
                        // }}
                        getOptionLabel={(option) =>
                          (option && option.label) || resd
                        }
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              alt=""
                            />
                            {option.label} ({option.code}) +{option.phone}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              <>
                                Residence{" "}
                                <span className="tw-text-red-600">*</span>
                              </>
                            }
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                            InputProps={{
                              ...params.InputProps,
                              classes: {
                                focused: "custom-focused", //   your custom-focused class here
                              },
                            }}
                            InputLabelProps={{
                              classes: {
                                root: "custom-label-root", // Add your custom label root class here
                              },
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="px-4 ">
                  <div className="pt-4">
                    <BlueButton
                      bg="#446DC9"
                      color="white"
                      padding=".4rem 1rem"
                      content="Add Step"
                      bR="6px"
                      onClick={abc}
                    />
                  </div>

                  <div className="tw-pt-8  parentSelector ">
                    {inputFields?.map((field, index) => (
                      <div
                        key={index}
                        className="tw-relative tw-flex tw-align-middle tw-gap-1  "
                      >
                        <Input
                          className="tw-py-3 mb-4 customBorder"
                          type="text"
                          placeholder="Type your process..."
                          value={field.value}
                          onChange={(e) =>
                            handleInputChange(index, e.target.value)
                          }
                          prefix={
                            <img
                              src={grid}
                              alt="LeftImage"
                              style={{ marginRight: 8, height: 16, width: 16 }}
                            />
                          } // Left image
                        />
                        <div
                          className="tw-absolute  tw-pl-14  tw-right-1 tw-pb-6  tw-cursor-pointer"
                          onClick={() => handleRemoveField(index)}
                          style={{ top: "50%", transform: "translateY(-50%)" }}
                        >
                          <img
                            src={delIcon}
                            alt="DeleteIcon"
                            style={{ height: 16, width: 16 }}
                          />
                        </div>
                      </div>
                    ))}

                    <div className="">
                      <TextField
                        className="myCustomTextField w-100 w-md-50  w-100"
                        id="note"
                        name="note"
                        onChange={(e) => setNote(e.target.value)}
                        value={note}
                        label={
                          <>
                            Note <span className="tw-text-red-600">*</span>
                          </>
                        }
                        variant="outlined"
                      />
                    </div>
                  </div>

                  <div className="tw-pb-7 pt-4">
                    <BlueButton
                      bg="#446DC9"
                      color="white"
                      padding=".4rem 1rem"
                      content="Add Checklist"
                      bR="6px"
                      onClick={checklist}
                    />
                  </div>
                  <div className="tw-pt-2 parentSelector">
                    {chkinputFields?.map((field, index) => (
                      <div key={index} className="tw-relative tw-flex tw-gap-2">
                        <Input
                          className="tw-py-3 mb-4 customBorder tw-flex-1"
                          type="text"
                          placeholder="Type your process..."
                          value={field.value}
                          onChange={(e) =>
                            handleInputChklist(index, e.target.value)
                          }
                          prefix={
                            <img
                              src={grid}
                              alt="LeftImage"
                              style={{ marginRight: 8, height: 16, width: 16 }}
                            />
                          } // Left image
                        />
                        <div
                          className="tw-relative tw-ml-2 tw-cursor-pointer tw-pt-4"
                          onClick={() => handleRemoveChkField(index)}
                        >
                          <img
                            src={delIcon}
                            alt="DeleteIcon"
                            style={{ height: 16, width: 16 }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="floating-label-container parent_design tw-mt-6 ">
                    <span className="floating-label child_design">
                      {name ? name : ""}
                      <span style={{ color: "red" }}>*</span>
                    </span>
                    <div>
                      <div
                        className="custom-input"
                        style={{ minHeight: "200px" }}
                      >
                        {checkdynamic?.map((val, index) => {
                          return (
                            <ul key={index}>
                              <li className="lato_bold tw-ps-3">
                                <span className="bullet tw-pe-2 ">&#8226;</span>{" "}
                                {val}
                              </li>
                            </ul>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="tw-flex tw-justify-center tw-pt-5 pt-4 pb-5">
                    <button
                      disabled={loading}
                      type="submit"
                      className="bgprimary tw-rounded-md tw-px-10 tw-mt-3 tw-py-2 tw-text-white"
                      color="primary"
                    >
                      {loading ? (
                        <>
                          <Spinner size={"sm"} />
                        </>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </div>
              </Form>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default Detail;
