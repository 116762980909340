/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { chevronlist, close, grid, upload } from "../../icons/icon";
import countries from "../explore/country/countryData";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/system";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { Form, Spinner } from "reactstrap";
import { getTrackbyId, updateTrack } from "../../api/track";
import { useParams } from "react-router-dom";
// Add the imported icon to the library
library.add(faTimes);

const theme = createTheme();
const AntTabs = styled((props) => <Tabs {...props} />)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#1890ff",
  },
});
const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#1890ff",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);
const TabPanel = ({ value, index, children }) => (
  <div hidden={value !== index}>
    {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
  </div>
);
const AddTrack = () => {
  // states---------
  const { id } = useParams();
  const [name, setName] = useState("");
  const [nationality, setnationality] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [gender, setgender] = useState("");
  const [residence, setresidence] = useState("");
  const [travelCountry, settravelCountry] = useState("");
  const [phone, setphone] = useState("");
  const [check, setcheck] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [reviewisChecked, setreviewIsChecked] = useState(false);
  const [completedChecked, setcompletedChecked] = useState(false);
  const [rejectedChecked, setrejectedChecked] = useState(false);
  const [reload, setReload] = useState(false);
  const [hideEmail, setHideEmail] = useState();
  const [purpose, setpurpose] = useState("");
  const [userType, setuserType] = useState("");

  const [user, setuser] = useState();
  const [value, setValue] = React.useState(0);
  const [status, setStatus] = useState("");
  const [applicationId, setapplicationId] = useState("");
  const [hide, setHide] = useState(false);
  const fileInputRef = useRef(null);
  // functions------------
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleUpdateStatus = (status) => {
    updateTrack(status, applicationId).then((res) => {
      setuser(res?.order);
      getTrackbyId(id).then((res) => {
        const completedDate = res?.application?.completed_date;
        setIsLoading(false);
        if (res?.application?.request == false) {
          setHideEmail(false);
        } else if (
          res?.application?.request == true &&
          res?.application?.contacted_date === null
        ) {
          setIsChecked(false);
          setHideEmail(true);
        } else if (
          res?.application?.request == true &&
          res?.application?.contacted_date !== null
        ) {
          setIsChecked(true);
          setHideEmail(true);
        }
        if (res?.application?.status === "completed") {
          setcompletedChecked(true);
          setrejectedChecked(false);
        } else if (res?.application?.status === "rejected") {
          setrejectedChecked(true);
          setcompletedChecked(false);
        }
        setuser(res?.application);
        setStatus(res?.application?.status);
        setapplicationId(res?.application?._id);
        if (res?.success) {
          setName(res.application?.name);
          setphone(res.application?.phone);
          setnationality(res.application?.nationality);
          setresidence(res.application?.residence);
          settravelCountry(res.application?.travelcountry);
          setnationality(res.application?.nationality);
          setgender(res.application?.referral_code);
          setresidence(res.application?.residence);
          setpurpose(res?.application?.trip_purpose?.name);
          setuserType(res?.application?.type);
  
        }
      });
    });
  };
  const handleReject = () => {
    updateTrack("rejected", applicationId).then((res) => {
      setuser(res?.order);
      setHide(true);
      setReload(true);
    });
  };
  useEffect(() => {
    if (user?.completed_date !== null) {
      setcheck(true);
    } else if (
      user?.contacted_date !== null &&
      user?.request === true &&
      user?.completed_date !== null
    ) {
      setcheck(true);
    } else {
      setcheck(false);
    }
  }, [user]);

  // useEffects---------

  const formatDate = (originalDate) => {
    const dateObject = new Date(originalDate);
    const options = { day: "numeric", month: "short", year: "numeric" };
    const formattedDate = dateObject.toLocaleDateString("en-US", options);
    return formattedDate;
  };
  useEffect(() => {
    setreviewIsChecked(true);
    setIsLoading(true);
    getTrackbyId(id).then((res) => {
      const completedDate = res?.application?.completed_date;
      setIsLoading(false);
      if (res?.application?.request == false) {
        setHideEmail(false);
      } else if (
        res?.application?.request == true &&
        res?.application?.contacted_date === null
      ) {
        setIsChecked(false);
        setHideEmail(true);
      } else if (
        res?.application?.request == true &&
        res?.application?.contacted_date !== null
      ) {
        setIsChecked(true);
        setHideEmail(true);
      }
      if (res?.application?.status === "completed") {
        setcompletedChecked(true);
        setrejectedChecked(false);
      } else if (res?.application?.status === "rejected") {
        setrejectedChecked(true);
        setcompletedChecked(false);
      }
      setuser(res?.application);
      setStatus(res?.application?.status);
      setapplicationId(res?.application?._id);
      if (res?.success) {
        setName(res.application?.name);
        setphone(res.application?.phone);
        setnationality(res.application?.nationality);
        setresidence(res.application?.residence);
        settravelCountry(res.application?.travelcountry);
        setnationality(res.application?.nationality);
        setgender(res.application?.referral_code);
        setresidence(res.application?.residence);
        setpurpose(res?.application?.trip_purpose?.name);
        setuserType(res?.application?.type);

      }
    });
  }, [id, reload]);

  return (
    <div className="bg ">
      <div className="tw-pb-5 container tw-pt-16 tw-flex tw-flex-wrap tw-align-start tw-gap-6  ">
        <ThemeProvider theme={theme}>
          <Box width={{ md: "95%", xs: "100%" }}>
            <Box
              sx={{ bgcolor: "#fff", minHeight: "auto", paddingBottom: "2rem" }}
            >
              <AntTabs
                value={value}
                onChange={handleChange}
                aria-label="ant example"
              >
                <AntTab className="lato_regular" label="Application Details" />
                <AntTab label="Track Application" />
              </AntTabs>
              <TabPanel value={value} index={0}>
                <div className="tw-px-10">
                  {loading ? (
                    <div className="d-flex justify-content-center">
                      <Spinner />
                    </div>
                  ) : (
                    <Form className="mt-3">
                      <div className="row tw-mt-4">
                        <div className="col-md-6 mb-3 ">
                          <TextField
                            disabled
                            style={{ width: "100%" }}
                            id="name"
                            label={
                              <>
                                Name <span className="tw-text-red-600">*</span>
                              </>
                            }
                            variant="outlined"
                            className="myCustomTextField"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                          />
                        </div>
                        <div className="col-md-6">
                          <TextField
                            disabled
                            style={{ width: "100%" }}
                            id="name"
                            label={
                              <>
                                Referral code{" "}
                                <span className="tw-text-red-600">*</span>
                              </>
                            }
                            variant="outlined"
                            className="myCustomTextField"
                            onChange={(e) => setgender(e.target.value)}
                            value={gender}
                          />
                        </div>
                      </div>
                      <div className="row tw-mt-4 ">
                        <div className="col-md-6 mb-3">
                          <TextField
                            disabled
                            style={{ width: "100%" }}
                            id="residence"
                            label={
                              <>
                                Residence{" "}
                                <span className="tw-text-red-600">*</span>
                              </>
                            }
                            variant="outlined"
                            className="myCustomTextField"
                            onChange={(e) => setresidence(e.target.value)}
                            value={residence}
                          />
                        </div>
                        <div className="col-md-6">
                          <TextField
                            disabled
                            style={{ width: "100%" }}
                            id="nationality"
                            label={
                              <>
                                Nationality{" "}
                                <span className="tw-text-red-600">*</span>
                              </>
                            }
                            variant="outlined"
                            className="myCustomTextField"
                            onChange={(e) => setnationality(e.target.value)}
                            value={nationality}
                          />
                        </div>
                      </div>
                      <div className="row tw-mt-4">
                        <div className="col-md-6 mb-3">
                          <TextField
                            disabled
                            style={{ width: "100%" }}
                            id="phone"
                            label={
                              <>
                                Phone Number{" "}
                                <span className="tw-text-red-600">*</span>
                              </>
                            }
                            variant="outlined"
                            className="myCustomTextField
                          "
                            onChange={(e) => setphone(e.target.value)}
                            value={phone}
                          />
                        </div>
                        <div className="col-md-6">
                          <TextField
                            disabled
                            style={{ width: "100%" }}
                            id="destination"
                            label={
                              <>
                                Where are you going{" "}
                                <span className="tw-text-red-600">*</span>
                              </>
                            }
                            variant="outlined"
                            className="myCustomTextField
                          "
                            onChange={(e) => settravelCountry(e.target.value)}
                            value={travelCountry}
                          />
                        </div>
                      </div>
                      <div className="row tw-mt-4">
                        <div className="col-md-6">
                          <TextField
                            disabled
                            style={{ width: "100%" }}
                            id="purposeOfTrip"
                            label={
                              <>
                                Account Type{" "}
                                <span className="tw-text-red-600">*</span>
                              </>
                            }
                            variant="outlined"
                            className="myCustomTextField"
                            onChange={(e) => setuserType(e.target.value)}
                            value={userType}
                          />
                        </div>
                        <div className="col-md-6 md:tw-mt-0 sm:tw-mt-4 tw-mt-4">
                          <TextField
                            disabled
                            style={{ width: "100%" }}
                            id="purposeOfTrip"
                            label={
                              <>
                                Purpose of trip{" "}
                                <span className="tw-text-red-600">*</span>
                              </>
                            }
                            variant="outlined"
                            className="myCustomTextField"
                            onChange={(e) => setpurpose(e.target.value)}
                            value={purpose}
                          />
                        </div>
                      </div>
                      <div className="row tw-mt-4">
                        <div className="col-md-6">

                        </div>

                        {user && user?.completed_date == null && (
                          <div className="d-flex gap-3 tw-mt-4 justify-content-end">
                            {user?.contacted_date === null &&
                              user?.request === true ? (
                              <>
                                {user.status === "pending" ?
                                  <button
                                    disabled={loading}
                                    type="button"
                                    onClick={() => handleUpdateStatus("accepted")}
                                    className="btn btn-md bg-warning text-white"
                                  >
                                    {loading ? (
                                      <>
                                        <Spinner size={"sm"} />
                                      </>
                                    ) : (
                                      "Accepted"
                                    )}
                                  </button> :
                                  <button
                                    disabled={loading}
                                    type="button"
                                    onClick={() => handleUpdateStatus("contacted")}
                                    className="btn btn-md bg-warning text-white"
                                  >
                                    {loading ? (
                                      <>
                                        <Spinner size={"sm"} />
                                      </>
                                    ) : (
                                      "Contact"
                                    )}
                                  </button>}
                              </>
                            ) : (
                              <button
                                type="button"
                                onClick={() => handleUpdateStatus("completed")}
                                className="btn btn-md bg-success text-white"
                              >
                                Completed
                              </button>
                            )}
                            {(user?.status === "pending" || user?.status === "rejected") &&
                              <button
                                type="button"
                                onClick={handleReject}
                                className="btn btn-md bg-danger text-white tw-px-6"
                              >
                                Rejected
                              </button>}
                          </div>
                        )}
                      </div>

                    </Form>
                  )}
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                {rejectedChecked === false && (
                  <div className="d-flex flex-column gap-2">
                    <div className="d-flex gap-4 align-items-start ">
                      <input
                        className="tw-mt-2"
                        type="checkbox"
                        checked={reviewisChecked}
                      // onChange={handleCheckboxChange}
                      />
                      <div className="tw-flex tw-flex-col">
                        <p className="m-0 p-0"> {user?.status === "pending" ? "Application is pending approval" : "Application in review"}</p>
                        <p className="m-0 p-0 tw-pb-11">
                          {formatDate(user?.createdAt)}
                        </p>
                      </div>
                    </div>
                    {user?.status !== "pending" &&
                      hideEmail === true && (
                        <div className="d-flex gap-4 align-items-start ">
                          <input
                            className="tw-mt-2"
                            type="checkbox"
                            checked={isChecked}
                          // onChange={handleCheckboxChange}
                          />
                          <div className="tw-flex tw-flex-col">
                            <p className="m-0 p-0">
                              Contacted By Email or Phone Number
                            </p>
                            <p className="m-0 p-0 tw-pb-11">
                              {" "}
                              {formatDate(user?.contacted_date)}
                            </p>
                          </div>
                        </div>
                      )}
                  </div>
                )}
                <div className=" d-flex gap-5 mt-2">
                  {completedChecked && (
                    <div className="tw-flex gap-4">
                      <input
                        type="checkbox"
                        checked={completedChecked}
                      // onChange={handleCheckboxChange}
                      />
                      <div className="tw-flex tw-flex-col">

                        <p className="m-0 p-0">Completed</p>
                        <p className="m-0 p-0 tw-pb-11">
                          {" "}
                          {formatDate(user?.completed_date)}
                        </p>
                      </div>
                    </div>
                  )}
                  {rejectedChecked && (
                    <div className="tw-flex tw-gap-9">
                      <FontAwesomeIcon
                        icon="times"
                        className="rejected-cross-icon"
                      />
                      <p
                        className={`m-0 p-0 ${user?.completed_date !== null ? "rejected-text" : ""
                          }`}
                      >
                        Rejected
                      </p>
                    </div>
                  )}
                </div>
                <div className="row tw-mt-4">
                  <div className="col-md-6">

                  </div>

                  {user && user?.completed_date == null && (
                    <div className="d-flex gap-3 tw-mt-4 justify-content-end">
                      {user?.contacted_date === null &&
                        user?.request === true ? (
                        <>
                          {user.status === "pending" ?
                            <button
                              disabled={loading}
                              type="button"
                              onClick={() => handleUpdateStatus("accepted")}
                              className="btn btn-md bg-warning text-white"
                            >
                              {loading ? (
                                <>
                                  <Spinner size={"sm"} />
                                </>
                              ) : (
                                "Accepted"
                              )}
                            </button> :
                            <button
                              disabled={loading}
                              type="button"
                              onClick={() => handleUpdateStatus("contacted")}
                              className="btn btn-md bg-warning text-white"
                            >
                              {loading ? (
                                <>
                                  <Spinner size={"sm"} />
                                </>
                              ) : (
                                "Contact"
                              )}
                            </button>}
                        </>
                      ) : (
                        <button
                          type="button"
                          onClick={() => handleUpdateStatus("completed")}
                          className="btn btn-md bg-success text-white"
                        >
                          Completed
                        </button>
                      )}
                      {(user?.status === "pending" || user?.status === "rejected") &&
                        <button
                          type="button"
                          onClick={handleReject}
                          className="btn btn-md bg-danger text-white tw-px-6">
                          Rejected
                        </button>}
                    </div>
                  )}
                </div>
              </TabPanel>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default AddTrack;
