import React, { useEffect, useState } from "react";
import { getNotification } from "../api/notification_api";
import { MdNotificationAdd } from "react-icons/md";
import { Spinner } from "react-bootstrap";
const AllNoti = () => {
  // states-----
  const [notiData, setNotiData] = useState([]);
  const [notiCount, setNotiCount] = useState('');
  const [lastId, setLastId] = useState(1);
  const [pageloading, setPageloading] = useState(false);
  // useEffects------
  useEffect(() => {
    getNotification(lastId)
      .then((result) => {
        console.log(result, "noti");
        if (result.success) {
          setNotiData(result?.notifications);
          setNotiCount(result.count.totalPage);

          setLastId(lastId+1)
        } else {
          setNotiData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleBottomScroll = () => {
    setPageloading(true);

    // Fetch more data when user scrolls to the bottom
    getNotification(lastId)
    .then((result) => {
      console.log(result, "noti");
      if (result.success) {
        setPageloading(false);
        setNotiData((prev)=>[...prev,...result.notifications]);
        setNotiCount(result.count.totalPage);
        setLastId(lastId+1)
      } else {
        setNotiData([]);
      }
    })
    .catch((err) => {
      console.log(err);
      setPageloading(false);

    });
  };
  return (
    <div className="container px-5 mt-5">
      <div className="d-flex flex-wrap   flex-row justify-content-lg-start justify-content-center gap-3 align-items-center ">
        {notiData?.map((items, index) => (
          <div key={index} className="card px-4 py-3 key-feature" style={{maxWidth:"22rem",cursor:"pointer", border:"1px solid transparent", boxShadow:"rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;"}}>
            <div className="tw-flex tw-gap-3">
              <div
                className="btn btn-outline-danger rounded-circle p-0 tw-flex tw-justify-center tw-items-center"
                style={{ height: "1.4rem", width: "1.4rem" }}
              >
                <MdNotificationAdd />
              </div>
              <div>
                <div className="tw-text-sm">{items.title}</div>
                <div className="tw-text-sm max-[450px]:tw-whitespace-break-spaces">
                  {items.description}
                </div>
              </div>
            </div>
            {}
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-center my-3">
{
    lastId<=notiCount ? (

      <button onClick={handleBottomScroll} className="see_more">
      {pageloading ? <Spinner size="sm" /> : "See more"}

      </button>
    ): ''
}
      </div>
    </div>
  );
};

export default AllNoti;
