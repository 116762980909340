import axios from "axios";

export const createPrivacy = async (html) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      return;
    }
    const res = await axios.post(
      global.BASEURL+"api/terms/create" ,
      {
        html: html
      },
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      }
    );

    console.log("Response data:", res);

    return res.data;
  } catch (error) {
    console.error("Error fetching category:", error);
     ;
  }
};
export const getPrivacy = async () => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      return;
    }
    const res = await axios.get(
      global.BASEURL+"api/terms" ,
   
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
      }
    );


    return res.data;
  } catch (error) {
    console.error("Error fetching category:", error);
     ;
  }
};


