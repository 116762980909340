/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useEffect } from "react";
import logo from "../assets/png/visaLogo.png";
import auth from "../assets/png/auth.png";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Col,
  FormFeedback,
  Spinner,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ArrowLeft } from "react-feather";
import { forgot } from "../api/forgot";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ForgotPassword = () => {
  // states----
  const [loading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  // form validation---------
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      phone: "",
    },
    validationSchema: Yup.object({
      phone: Yup.string()
        // .phone("Invalid phone number")
        .required("phone is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true)
      forgot(values?.phone).then((res) => {
        if (res.success === true) {
          setIsLoading(false)
          navigate("/code-verification");
          resetForm()
          toast.success('Verification code sent successfully')
          localStorage.setItem("verification_token", res?.token);
        }
      }).catch((err) => {
        toast.error('User is not registered with that Phone number or email')
        setIsLoading(false)
      })
    },
  });
  return (
    <div className="container min-[1440px]:tw-px-10 ">
      <div className="tw-pb-5">
        {/* <div>
          <ArrowLeft/>
        </div> */}
        <div className="tw-pt-10">
          <img src={logo} width="200px" alt="" />
        </div>
        <div className="register_main d-sm-flex flex-lg-row flex-sm-column  justify-content-between align-items-center tw-px-0 px-0">
          <div className="">
            <img src={auth} alt="authPic" className="img-fluid" />
          </div>
          <div>
            <div className="md:tw-w-96 tw-text-start mb-4">
              <div className="lato_regular tw-text-2xl tw-pt-10 ">
                Recover Account
              </div>
              <div className="text-muted">
                Enter your phone number to receive a verification code, which
                you will need to input for confirmation.
              </div>
            </div>
            <Form onSubmit={validation.handleSubmit} className="mt-3">
              <FormGroup row>
                <Col md={10} sm={12}>
                  <PhoneInput
                    country={'ae'}
                    inputClass="form-control"  // Set the class for styling
                    containerClass="phone-input-container"  // Set the class for the container
                    inputProps={{
                      name: "phone",
                      id: "phone",
                      placeholder: "Enter phone",
                    }}
                    value={validation.values.phone || ""}
                    onChange={(phone) => validation.handleChange({ target: { name: "phone", value: phone } })}
                    onBlur={validation.handleBlur}
                    isValid={validation.touched.phone && !validation.errors.phone}
                    inputStyle={{
                      // Add any additional styles you need for the input
                    }}
                  />
                  {validation.touched.phone && validation.errors.phone ? (
                    <FormFeedback type="invalid">
                      {validation.errors.phone}
                    </FormFeedback>
                  ) : null}
                </Col>
                {/* <Col md={10} sm={12}>
                  <Input
                    type="phone"
                    className="form-control"
                    name="phone"
                    id="phone"
                    placeholder="Enter phone"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.phone || ""}
                    invalid={
                      validation.touched.phone && validation.errors.phone
                        ? true
                        : false
                    }
                  />
                  {validation.touched.phone && validation.errors.phone ? (
                    <FormFeedback type="invalid">
                      {validation.errors.phone}
                    </FormFeedback>
                  ) : null}
                </Col> */}
              </FormGroup>
              <div className="tw-flex ">
                <Button
                  disabled={loading}
                  type="submit"
                  className="auth_btn tw-text-white"
                  color="primary"
                >
                  {loading ? (
                    <>
                      <Spinner size={"sm"} />
                    </>
                  ) : (
                    "Send Code"
                  )}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
