import React, { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { mdi_user } from "../../icons/icon";
import TextField from "@mui/material/TextField";
import BlueButton from "../../resueComponent/blueButton";
import { Button, Form, Spinner } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getUser, updateUser } from "../../api/user";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { uploadFile } from "../../api/upload";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { format } from "date-fns";

const theme = createTheme();
const Updateprofile = () => {
  const dynamicLabel = "Dynamic Label"; // or fetch the label from some source
  const formatDate = (date) => {
    return format(date, "dd-MMM-yyyy"); // Format the date as "27-Dec-2017"
  };
  // states ------------
  const [avatarImage, setAvatarImage] = useState(null);
  const [loading, setIsLoading] = useState(false);
  const [loading2, setIsLoading2] = useState(false);
  const [startDate, setStartDate] = useState(null);

  const [active, setActive] = useState(false);
  const [userData, setuserData] = useState("");
  const [user, setuser] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [nationality, setnationality] = useState("");
  const [gender, setgender] = useState("");
  const [address1, setaddress1] = useState("");
  const [address2, setaddress2] = useState("");
  const [residence, setresidence] = useState("");
  const [zipcode, setzipcode] = useState("");
  const [doc_url, setdoc_url] = useState("");
  const [dob, setdob] = useState("");
  const [profilePicture, setprofilePicture] = useState(null);
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  // functions-----------
  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        // Upload file and wait for the result
        const result = await uploadFile(file);
        // Handle the result as needed
        setSelectedFile(result?.image);
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewImage(reader.result);
        };
        reader.readAsDataURL(file);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };
  const handleButtonClick = () => {
    // fileInputRef.current.click();
  };
  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    const data = {
      name: name,
      gender: gender,
      address1: address1,
      address2: address2,
      zipcode: zipcode,
      profilePicture: selectedFile,
      dob: startDate,
      nationality: nationality,
      residence: residence,
      doc_url: doc_url,
    };
    updateUser(data).then((res) => {
      setIsLoading(false);
      toast.success("Edited successfully...!");
    });
  };
  // useEffects----------
  //   useEffect(()=>{
  // getUser().then((res)=>{
  //   console.log(res.user,"get user")
  //   setuser(res?.user)
  // })
  //   },[])
  //   useEffect(() => {
  //     console.log(user, "userdetails...!");
  //     if(user){
  //       setName(user?.name)
  //       setEmail(user?.email)
  //       setdob(user?.dob)
  //       setaddress1(user?.address1)
  //       setaddress2(user?.address2)
  //       setnationality(user?.nationality)
  //       setgender(user?.gender)
  //       setresidence(user?.residence)
  //       setzipcode(user?.zipcode)
  //       setdoc_url(user?.doc_url)
  //       setprofilePicture(user?.previewImage)
  //     }
  //     setuserData(user);
  //   }, [user]);
  // useEffect(() => {
  
  //   setIsLoading2(true);
  //   getUser().then((res) => {
  //     const FDate = new Date(res?.user?.dob);
  //     const day = FDate.getDate();
  //     const month = FDate.getMonth() + 1;
  //     const year = FDate.getFullYear();

  //     const dateToShow = day + "/" + month + "/" + year;
  //     console.log(dateToShow,'new dayeee');
  //     // const datePart = res.user?.dob.substring(0, 10);
  //     const date = res?.user?.dob;
  //     console.log(res);
  //     setIsLoading2(false);
  //     setuser(res?.user);
  //     if (res?.user) {
  //       setName(res.user?.name);
  //       setEmail(res.user?.email);
  //       // setStartDate(dateToShow);
  //       setaddress1(res.user?.address1);
  //       setaddress2(res.user?.address2);
  //       setnationality(res.user?.nationality);
  //       setgender(res.user?.gender);
  //       setresidence(res.user?.residence);
  //       setzipcode(res.user?.zipcode);
  //       setdoc_url(res.user?.doc_url);
  //       setprofilePicture(res.user?.profilePicture); // Set profile picture here
  //       setSelectedFile(res.user?.profilePicture); // Set preview image here
  //     }
  //   });
  // }, []);
  useEffect(() => {
    setIsLoading2(true);
    getUser().then((res) => {
      // Parse the date string using Moment.js
      // const parsedDate = moment(res?.user?.dob);
      // Format the parsed date according to your desired format
      // const formattedDate = parsedDate.format('YYYY\/YYYY');
      const formattedDate = moment(res?.user?.dob).format('YYYY-MM-DD'); // Change the format as needed
      console.log(formattedDate, 'new dayeee');
      setIsLoading2(false);
      setuser(res?.user);
      if (res?.user) {
        setName(res.user?.name);
        setEmail(res.user?.email);
        setStartDate(formattedDate);
  console.log(startDate,"ss")
         // Assuming you want to set startDate with formattedDate
        setaddress1(res.user?.address1);
        setaddress2(res.user?.address2);
        setnationality(res.user?.nationality);
        setgender(res.user?.gender);
        setresidence(res.user?.residence);
        setzipcode(res.user?.zipcode);
        setdoc_url(res.user?.doc_url);
        setprofilePicture(res.user?.profilePicture); // Set profile picture here
        setSelectedFile(res.user?.profilePicture); // Set preview image here
      }
    });
  }, []);
  console.log(startDate,"ss")

  return (
    <div className="bg ">
      <ToastContainer />
      <div className="tw-pb-5 container tw-pt-8 tw-flex tw-flex-wrap tw-align-start tw-gap-6  ">
        <ThemeProvider theme={theme}>
          <Box width={{ md: "94%", xs: "100%" }}>
            <Box sx={{ bgcolor: "#fff", minHeight: "100vh" }}>
              {loading2 ? (
                <div
                  className="tw-pt-16"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "100vh",
                  }}
                >
                  <Spinner size={"md"} />
                </div>
              ) : (
                <div className="md:tw-px-16 sm:tw-px-8 tw-px-4 tw-pt-5">
                  <div className="row profile ">
                    <div className=" col-md-4 col-12 profile_child_1 ">
                      <div className="d-flex flex-column gap-5   ">
                        <div>
                          <img
                            className="avatar"
                            alt=""
                            src={
                              previewImage
                                ? `${previewImage}`
                                : `${global.Upload + selectedFile}`
                            }
                          />
                        </div>
                      </div>
                      <button
                        className="bgprimary tw-rounded-md position-relative tw-px-3 tw-mt-8 tw-py-2 tw-text-white"
                        onClick={handleButtonClick}
                      >
                        Upload Image
                        <input
                          type="file"
                          ref={fileInputRef}
                          className="innerFile tw-cursor-pointer"
                          accept="image/*"
                          onChange={handleImageChange}
                          id="avatarInput"
                        />
                      </button>
                    </div>
                    <div className=" col-md-8 col-12 md:tw-text-start tw-text-center profile_child_2 tw-py-8 sm:tw-py-8">
                      <p className="lato_bold">Personal Details</p>
                      <Form onSubmit={handleSubmit} className="mt-3">
                        <div className="d-flex flex-md-row flex-column gap-4 mt-3">
                          <TextField
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            className=" myCustom  TextField w-md-50 w-100"
                            id="outlined-basic"
                            label={
                              <>
                                Name <span className="tw-text-red-600">*</span>
                              </>
                            }
                            variant="outlined"
                          />
                        </div>

                        <div className="d-flex flex-md-row flex-column gap-4 mt-3">
                          <TextField
                            className=" myCustomTextField w-md-50 w-100"
                            id="outlined-basic"
                            onChange={(e) => setgender(e.target.value)}
                            value={gender}
                            label={
                              <>
                                Gender{" "}
                                <span className="tw-text-red-600">*</span>
                              </>
                            }
                            variant="outlined"
                          />
                          <input type="date" onChange={(e)=>setStartDate(e.target.value)} value={startDate}/>
                          {/* <DatePicker
                            label={dynamicLabel}
                            style={{ width: "100%" }}
                            value={startDate}
                            placeholderText="Pick date"
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            className="w-100"
                            // Render custom input value
                            renderCustomInput={(
                              props,
                              openCalendar,
                              closeCalendar
                            ) => (
                              <input
                                {...props}
                                value={startDate ? formatDate(startDate) : ""}
                                onFocus={openCalendar}
                                onBlur={closeCalendar}
                                style={{ width: "100%" }} // Add your custom styles here
                              />
                            )}
                            portalId="datepicker-portal" // Assign a unique ID for the portal
                            appendToBody={true} // Append the datepicker to the body
                            popperModifiers={{
                              preventOverflow: {
                                enabled: true,
                              },
                              flip: {
                                enabled: true,
                              },
                              offset: {
                                enabled: true,
                                offset: "5px, 10px", // Adjust the offset as needed
                              },
                              zIndex: {
                                enabled: true,
                                zIndex: 99, // Set the zIndex as needed
                              },
                            }}
                          /> */}
                          {/* <TextField
                            className="myCustomTextField w-md-50 w-100"
                            id="outlined-basic"
                            onChange={(e) => setdob(e.target.value)}
                            value={dob}
                            label={
                              <>
                                Date Of Birth{" "}
                                <span className="tw-text-red-600">*</span>
                              </>
                            }
                            variant="outlined"
                          /> */}
                        </div>
                        <div className="d-flex flex-md-row flex-column gap-4 mt-3">
                          <TextField
                            className=" myCustomTextField w-md-50 w-100"
                            id="outlined-basic"
                            onChange={(e) => setresidence(e.target.value)}
                            value={residence}
                            label={
                              <>
                                Residence{" "}
                                <span className="tw-text-red-600">*</span>
                              </>
                            }
                            variant="outlined"
                          />
                          <TextField
                            className=" myCustomTextField w-md-50 w-100"
                            id="outlined-basic"
                            onChange={(e) => setnationality(e.target.value)}
                            value={nationality}
                            label={
                              <>
                                Nationality{" "}
                                <span className="tw-text-red-600">*</span>
                              </>
                            }
                            variant="outlined"
                          />
                        </div>

                        <div className="d-flex flex-md-row flex-column gap-4 mt-3">
                          <TextField
                            className=" myCustomTextField w-md-50 w-100"
                            id="outlined-basic"
                            onChange={(e) => setaddress1(e.target.value)}
                            value={address1}
                            label={
                              <>
                                Address{" "}
                                <span className="tw-text-red-600">*</span>
                              </>
                            }
                            variant="outlined"
                          />
                          <TextField
                            className=" myCustomTextField w-md-50 w-100"
                            id="outlined-basic"
                            onChange={(e) => setaddress2(e.target.value)}
                            value={address2}
                            label={
                              <>
                                Address2{" "}
                                <span className="tw-text-red-600">*</span>
                              </>
                            }
                            variant="outlined"
                          />
                        </div>

                        <div className="d-flex flex-md-row flex-column gap-4 mt-3">
                          <TextField
                            className="myCustomTextField w-md-50 w-100 "
                            onChange={(e) => setdoc_url(e.target.value)}
                            value={doc_url}
                            id="doc"
                            label={
                              <>
                                Doc Url{" "}
                                <span className="tw-text-red-600">*</span>
                              </>
                            }
                            variant="outlined"
                            type="doc"
                          />
                          <TextField
                            onChange={(e) => setzipcode(e.target.value)}
                            value={zipcode}
                            className=" myCustomTextField w-md-50 w-100"
                            id="outlined-basic"
                            label={
                              <>
                                Zip Code{" "}
                                <span className="tw-text-red-600">*</span>
                              </>
                            }
                            variant="outlined"
                          />
                        </div>

                        <div className="tw-mt-5">
                          <button
                            disabled={loading}
                            type="submit"
                            className="bgprimary tw-rounded-md tw-px-3  tw-py-2 tw-text-white "
                            color="primary"
                          >
                            {loading ? (
                              <>
                                <Spinner size={"sm"} />
                              </>
                            ) : (
                              "Update Profile"
                            )}
                          </button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              )}
            </Box>
          </Box>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default Updateprofile;
